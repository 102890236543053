import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LanguageService } from '@realstate/services/lang.service';
import { SettingService } from '@realstate/services/setting.service';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  setting = {}
  textDir: string;

  constructor(private service:SettingService,public translate: TranslateService , private lang : LanguageService,
    private titleService: Title) {
    this.currentLang();
    this.titleService.setTitle('Terms-condition')
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      } else {
        this.textDir = 'ltr';
      }
    });
   }

  ngOnInit() {
      this.service.getSetting().subscribe(res => {
        this.setting = res.settings
        console.log(this.setting)
      })
  }
  currentLang() {
      this.translate.currentLang = localStorage.getItem('lang')
    if (this.translate.currentLang == 'ar')
      this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }


}
