<ul class="contact-info-list ">
    <li (click)="clickOnProperty()"> <a href="tel:+2({{property?.user?.phonenumber}})">
        <i class="main-color fa fa-phone"></i></a></li>
    <li class="mx-2"></li>
    <li (click)="clickOnProperty()"> <a href="mailto:{{property?.user?.email}}">
        <i class="main-color fa fa-envelope"></i></a></li>
    <li class="mx-2"></li>
    <li (click)="clickOnProperty()"> <a href="https://api.whatsapp.com/send?phone={{property?.user?.whatsapp_no}}">
        <i class="fa fa-whatsapp main-color"></i></a></li>
</ul>
