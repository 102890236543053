<app-navbar-style-one></app-navbar-style-one>
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>OTP verification</h3>
        </div>
    </div>
</div>
<div class="user-area" >
    <div class="container-fluid m-0">
        <div class="row">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/ed-pylypenko-7zcbtbI4E2o-unsplash-min.jpg" alt="Images">
                </div>
            </div>
            <div class="col-lg-5 col-xl-6 p-0">
                <div class="login-container">
                    <div class="form">
                        <div class="header">
                            <div class="otp-image-container">
                                <img src="assets\img\verified.svg" alt="otp">
                            </div>
                            <h4>AUTHENTICATE YOUR ACCOUNT</h4>
                            <p >Please confirm your account by entering the authorization code</p>
                        </div>
                        <!-- <button type="button" style="display: flex; margin: 20px auto;" class="btn btn-primary" >Send verification code</button> -->
                        <div class="otp">
                        <ng-otp-input style="text-align:center" name="otp" ngDefaultControl (onInputChange)="onOtpChange($event)"
                    [config]="{length:6}"></ng-otp-input>
                </div>
                <div class="submit">
                    <button *ngIf="!onsubmit" type="button" [disabled]="otpCode?.length < 6" (click)="onSubmit()" style="display: flex; margin: 20px auto;" class="btn" >Submit</button>
                    <p *ngIf="onsubmit" class="text-center">loading...</p>

                </div>
                
      <p class="resend">Did not Receive Code press here,
        <span (click)="resend()">resend</span>
      </p>
                </div>
                </div>
                </div>
            </div>
        </div>
</div>
