import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LanguageService } from '@realstate/services/lang.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit {

  textDir: string = 'ltr';

  constructor(
    public translate: TranslateService , private lang : LanguageService,
    private titleService: Title
  ) {
    this.titleService.setTitle("About");

    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
   }

  ngOnInit(): void {
    window.scrollTo(0,0)
  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')

    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }
}
