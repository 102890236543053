<div class="navbar-area" dir="{{textDir}}">
    <div class="mobile-nav">
        <!-- <div class="container"> -->
            <div class="row">
                <div class="col-6" [ngClass]="textDir=='ltr' ? 'ArText' : 'EngText'">
                    <a routerLink="/" class="logo" *ngIf="textDir=='ltr'"><img
                            src="assets/img/logo/beitlist_Logo.png"  alt="Logo"></a>
                    <a routerLink="/" class="logo" *ngIf="textDir=='rtl'"><img
                            src="assets/img/logo/beitlist-ar-o.png" alt="Logo"></a>
                </div>
                <div class="col-4">
                    <button class="btn text-white bg-transparent m-btn " type="button" routerLink="/add-listing">
                        <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="col-2" [ngClass]="textDir=='rtl' ? 'Mob-view' : 'Web-view'" >
                    <button class="btn text-white bg-transparent m-btn " type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <!-- <span class="navbar-toggler-icon"></span> -->
                        <!-- <i class="fa-solid fa-bars"></i> -->
                        <!-- <span class="material-symbols-outlined"></span> -->
                        <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

        <!-- </div> -->

        <!-- offcanvas -->
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel">

            <div class="offcanvas-header">
                <div class="row align-items-center">
                    <div class="col-8">
                        <a routerLink="/" style="width: 55%;" *ngIf="textDir=='ltr'"><img
                                src="assets/img/logo/beitlist_Logo.png" alt="Logo"></a>
                        <a routerLink="/" style="width: 55%;" *ngIf="textDir=='rtl'"><img
                                src="assets/img/logo/beitlist-ar-o.png" alt="Logo"></a>
                    </div>
                    <div class="col-2">
                        <a *ngIf="textDir == 'ltr'" style="cursor: pointer;" (click)="Translate('ar')">
                            <img src="assets/img/flag-round-250.png" style="width: 60%;" alt="arabic-flag">
                        </a>
                        <a *ngIf="textDir == 'rtl'" style="cursor: pointer;" (click)="Translate('en')">
                            <img src="assets/img/great-britain-icon-256.png" alt="English-flag" style="width: 60%;">
                        </a>
                    </div>
                    <div class="col-2">
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>

            <div class="offcanvas-body" style="background-color: #f7c37f;">

                <div class="dropdown d-user" style="text-align: center;">
                    <img *ngIf="currentUsr?.image != null" class="profile-pic" src="{{userImage}}" alt="userImage">
                    <br>
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                        *ngIf="currentUsr" style="text-decoration:none" aria-expanded="false" dir={{textDir}}>
                        {{currentUsr?.fullname}}
                    </a>
                    <a class="" href="/login-register" *ngIf="!currentUsr" style="text-decoration:none"
                        aria-expanded="false" dir={{textDir}}>
                        {{'login' | translate}}/{{'register' | translate}}
                    </a>

                    <ul  class="dropdown-menu" aria-labelledby="dropdownMenuLink"  dir={{textDir}}>
                        <li class="nav-item">
                            <a routerLink="/profile" class="nav-link" routerLinkActive="active" dir={{textDir}}
                                [routerLinkActiveOptions]="{exact: true}">
                                <div class="row">
                                    <div class="col-md-4">
                                        <i *ngIf="currentUsr?.image == null" class="fa fa-image mt-3"></i>
                                        <img *ngIf="currentUsr?.image != null" class="profile-pic" src="{{userImage}}" alt="userImage">
                                    </div>
                                    <div class="col-md-8">
                                        <span class="profile-name">{{currentUsr?.fullname}}</span>
                                        <p>{{'view profile' | translate}}</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/my-listings" class="nav-link" routerLinkActive="active" dir={{textDir}}
                                [routerLinkActiveOptions]="{exact: true}">
                                <i class="profile-icons fa fa-list"></i><span class="black-li">{{'my listings' |
                                    translate}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/my-favorites" class="nav-link" routerLinkActive="active" dir={{textDir}}
                                [routerLinkActiveOptions]="{exact: true}">
                                <i class="profile-icons fa fa-list"></i><span class="black-li">{{'my favorites listings'
                                    | translate}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/my-reviews" class="nav-link" routerLinkActive="active" dir={{textDir}}
                                [routerLinkActiveOptions]="{exact: true}">
                                <i class="profile-icons fa fa-star"></i><span class="black-li">{{'reviews'
                                    |translate}}</span>
                            </a>
                        </li>
                        <li class="nav-item" dir={{textDir}}>
                            <a routerLink="/edit-profile" class="nav-link" routerLinkActive="active" dir={{textDir}}
                                [routerLinkActiveOptions]="{exact: true}">
                                <i class="profile-icons fa fa-id-card"></i><span class="black-li">{{'edit profile' |
                                    translate}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/change-profile-password" class="nav-link" routerLinkActive="active"
                                dir={{textDir}} [routerLinkActiveOptions]="{exact: true}">
                                <i class="profile-icons fa fa-key"></i><span class="black-li">{{'change password'
                                    |translate}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a style="cursor: pointer" (click)="logout()" class="nav-link" dir={{textDir}}
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                <i class="profile-icons fa fa-sign-out"></i><span class="black-li">{{'logout'
                                    |translate}}</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <ul class="navbar-nav m-auto ">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"><i
                                class="profile-icons fa fa-home mx-2"></i>{{'home'
                            | translate}}</a>
                    </li>
                    <hr>
                    <!-- <li class="nav-item">
                        <a routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"><i
                                class="profile-icons fa fa-info mx-2"></i>{{'about'
                            | translate}}</a>
                    </li> -->
                    <hr>
                    <li class="nav-item">
                        <a routerLink="/listing" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"><i
                                class="profile-icons fa fa-building mx-2"></i>{{'listing' | translate}}</a>
                    </li>
                    <hr>
                    <li class="nav-item">
                        <a routerLink="/my-favorites" class="nav-link" routerLinkActive="active" dir={{textDir}}
                            [routerLinkActiveOptions]="{exact: true}">
                            <i class="profile-icons fa fa-heart mx-2"></i>{{'my favorites listings' | translate}}</a>
                    </li>
                    <hr>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}"><i
                                class="profile-icons fa fa-phone mx-2"></i>{{'contact' | translate}}</a></li>
                    <hr>
                </ul>

                <div class="side-item" >
                    <div class="nav-add-btn" >
                        <a routerLink="/add-listing" class="default-btn border-radius" style="cursor: pointer;">{{'add listing' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">

                <a class="navbar-brand orangeLogo" routerLink="/" *ngIf="textDir=='ltr'">
                    <img src="assets/img/logo/beitlist_Logo.png" alt="Logo"></a>
                <a class="navbar-brand whiteLogo" routerLink="/" *ngIf="textDir=='ltr'">
                    <img src="assets/img/logo/beitlist_Logo_w.png" alt="Logo"></a>

                <a class="navbar-brand orangeLogo" routerLink="/" *ngIf="textDir=='rtl'">
                    <img src="assets/img/logo/beitlist-ar-o.png" alt="Logo"></a>
                <a class="navbar-brand whiteLogo" routerLink="/" *ngIf="textDir=='rtl'">
                    <img src="assets/img/logo/beitlist-ar-w.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">

                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'home' | translate}}</a>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'about' | translate}}</a>
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="/listing" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'listing' | translate}}</a>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{'contact' | translate}}</a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">

                        <div class="side-item" >
                            <div class="nav-add-btn">
                                <a (click)="RouterCondition()" class="default-btn border-radius"
                                    style="cursor: pointer;">{{'add listing' | translate}}</a>
                            </div>
                        </div>

                        <!-- <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div> -->
                        <div class="side-item">
                            <div class="user-btn" *ngIf="currentUsr">
                                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">

                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <a href="javascript:void(0)" class="nav-link">{{currentUsr?.fullname}} <i
                                                    class='bx bx-user'></i></a>
                                            <ul class="dropdown-menu" dir={{textDir}} [ngClass]="textDir=='rtl' ? 'dropdown-ar' : ''">
                                                <li class="nav-item">
                                                    <a routerLink="/profile" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <div class="row" dir={{textDir}}>
                                                            <div class="col-md-4">
                                                                <i *ngIf="currentUsr?.image == null"
                                                                    class="fa fa-image mt-3"></i>
                                                                <img *ngIf="currentUsr?.image != null"
                                                                    class="profile-pic" src="{{userImage}}" alt="userImage">
                                                            </div>
                                                            <div class="col-md-8">
                                                                <span
                                                                    class="profile-name">{{currentUsr?.fullname}}</span>

                                                                <p *ngIf="currentUsr?.subscribtion_id==1">{{'package1' |
                                                                    translate}}</p>
                                                                <p *ngIf="currentUsr?.subscribtion_id==2">{{'package2' |
                                                                    translate}}</p>
                                                                <p *ngIf="currentUsr?.subscribtion_id==3">{{'package3' |
                                                                    translate}}</p>
                                                                <p *ngIf="currentUsr?.subscribtion_id==4">{{'package4' |
                                                                    translate}}</p>

                                                                <p>{{'view profile' | translate}}</p>

                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item">
                                            <a routerLink="/subscribtions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                                <span class="black-li">{{'manage' | translate}}</span>
                                            </a>
                                        </li> -->
                                                <li class="nav-item">
                                                    <a routerLink="/my-listings" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <i class="profile-icons fa fa-list"></i><span
                                                            class="black-li">{{'mylistings' | translate}}</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a routerLink="/my-favorites" class="nav-link"
                                                        routerLinkActive="active" dir={{textDir}}
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <i class="profile-icons fa fa-heart"></i><span
                                                            class="black-li">{{'my favorites listings' |
                                                            translate}}</span>
                                                    </a>
                                                </li>

                                                <li class="nav-item">
                                                    <a routerLink="/my-reviews" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <i class="profile-icons fa fa-star"></i><span
                                                            class="black-li">{{'reviews' | translate}}</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a routerLink="/edit-profile" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <i class="profile-icons fa fa-id-card"></i><span
                                                            class="black-li">{{'edit profile' | translate}}</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a routerLink="/change-profile-password" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <i class="profile-icons fa fa-key"></i><span
                                                            class="black-li">{{'change password' | translate}}</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item"><a style="cursor: pointer"
                                                        (click)="logout()" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">
                                                        <i class="profile-icons fa fa-sign-out"></i><span
                                                            class="black-li">{{'logout' | translate}}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="!currentUsr" class="user-btn">
                                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul class="navbar-nav m-auto">
                                        <li class="nav-item">
                                            <a routerLink="/login-register" class="nav-link">
                                                <i class='bx bx-user'></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="side-item">
                            <div class="arabic-box">
                                <a *ngIf="textDir == 'ltr'" style="cursor: pointer;" (click)="Translate('ar') ">
                                    <img src="assets/img/flag-round-250.png" alt="ar-flag">
                                </a>
                                <a *ngIf="textDir == 'rtl'" style="cursor: pointer;" (click)="Translate('en')">
                                    <img src="assets/img/great-britain-icon-256.png" alt="en-flag">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <input type="text" class="input-search" formControlName="description" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>



</div>
