<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg2"
    [ngStyle]="{'background-image': user?.cover != null ? 'url({{userCover}})' : 'url(assets/img/Group9.png)'}"
    dir="{{textDir}}">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 align-items-center" style="z-index: 1;">
                    <div class="listing-side-host">
                        <i *ngIf="user?.image == null" class="fa fa-image"></i>
                        <img *ngIf="user?.image != null" src="{{userImage}}" alt="Images" class="user-pic">
                        <h2 class="profile-name">{{user?.fullname}}</h2>
                        <span [ngSwitch]="user?.usertype">
                            <span class="job" *ngSwitchCase="6">{{'Owner' | translate}}</span>
                            <span class="job" *ngSwitchCase="4">{{'Freelance Broker' | translate}}</span>
                            <span class="job" *ngSwitchCase="5">{{'Brokerage Company' | translate}}</span>
                            <span class="job" *ngSwitchCase="4">{{'broker' | translate}}</span>
                            <span class="job" *ngSwitchCase="1">{{'customer' | translate}}</span>
                        </span>
                    </div>
                </div>
                <!-- <div class="col-lg-4"></div> -->

                <!-- <div class="col-lg-4">
                    <div class="banner-rating" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <ul>
                            <li class="rating-star">
                               <i *ngFor="let i of [].constructor(averageFloor)" class='bx bxs-star'></i>
                                <i *ngIf="averageFraction != 0" class='bx bxs-star-half'></i>
                                <span>{{reviews?.length}} {{'reviews' | translate}} </span>
                            </li>
                            <li><a class="a-white">{{user?.review?.toFixed(1)}}</a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
        <ng-template #content let-modal>
            <div class="container" dir="{{textDir}}">
                <form [formGroup]="reportForm" (ngSubmit)="onSubmit()">
                    <div class="listing-category">
                        <p class="mt-2" style="margin-left: 2%">{{'select problem' | translate}}</p>
                        <hr>
                        <div class="row">
                            <i style="color: #f19101 !important; margin-top: 1%;"
                                class="fa fa-exclamation-triangle col-md-1"></i>
                            <span class="col-md-10">{{'report profile' | translate}}</span>
                        </div>
                        <ul class="mt-3">
                            <ng-container *ngIf="translate.currentLang == 'en'">
                                <li *ngFor="let type of reporttypes; let i = index">
                                    <a class="{{selectedReport == i + 1 ? 'anchor2-selected' : 'anchor2'}}"
                                        (click)="selected(i + 1)"><i class="flaticon-touch"></i>{{type}}</a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="translate.currentLang == 'ar'">
                                <li *ngFor="let type of reporttypes_ar; let i = index">
                                    <a class="{{selectedReport == i + 1 ? 'anchor2-selected' : 'anchor2'}}"
                                        (click)="selected(i + 1)"><i class="flaticon-touch"></i>{{type}}</a>
                                </li>
                            </ng-container>
                        </ul>
                        <textarea class="form-control mt-3" rows="8" formControlName="reporttext"></textarea>
                        <p class="help-block" *ngIf="formSubmitted &&selectedReport == null">
                            <small style="color: red;" class="help-block">{{"give type" | translate}}</small>
                        </p>
                        <hr>
                        <div style="text-align: right;">
                            <button type="submit" class="default-btn"
                                style="padding: 4px 20px !important">{{'sendmessage' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>
        <div class="banner-bottom ">
            <div class="row align-items-center">
                <div class="col-7" style="z-index: 1;"
                [ngStyle]="{'visibility':auth.isAuthenticated() && id ? 'visible' : 'hidden' }">
                    <div class="banner-tag">
                        <ul>
                            <li><a class="a-white" (click)="open(content)"><i
                                        class="fa fa-ban"></i>&nbsp;{{'report'|translate}}</a></li>
                            <li class="reviewButton"><a (click)="submitReviews()" style="color: white; cursor: pointer;"><i
                                        class="flaticon-writing"></i>&nbsp;{{'submit review' |translate}}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-5" style="z-index: 1;">
                    <div class="banner-rating" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <ul>
                            <li class="rating-star" [ngStyle]="{'right':textDir=='rtl' ? '20px' : '8px' }">
                                <i *ngFor="let i of [].constructor(averageFloor)" class='bx bxs-star'></i>
                                <i *ngIf="averageFraction != 0" class='bx bxs-star-half'></i>
                                <span>{{reviews?.length}} {{'reviews' | translate}} </span>
                            </li>
                            <li><a (click)="toReviews()" class="a-white">{{user?.review?.toFixed(1)}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>


        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a>{{'home' | translate}}</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{'profile' | translate}}</li>
                    </ul>

                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <!-- <li *ngIf="user?.facebook"><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li *ngIf="user?.twitter"><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li *ngIf="user?.instagram"><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                        </li>
                        <li *ngIf="user?.pinterest"><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a>
                        </li>
                        <li *ngIf="user?.youtube"><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                        <li>
                            {{'share user profile' | translate}}
                        </li>
                        <li style="background-color:#f19101; border-radius: 10px; ">
                            <share-buttons [theme]="'default'" [include]="['facebook','whatsapp','linkedin']" [show]="4">
                            </share-buttons>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="listing-details-wrap">
                    <div class="listing-category">
                        <ul [ngStyle]="{'text-align': translate.currentLang == 'ar' ? 'right' : ''}">
                            <li><a (click)="toAbout()" class="anchor2"><i
                                        class="flaticon-touch"></i>{{'description'|translate}}</a></li>
                            <li><a (click)="toListings()" class="anchor2"><i class="flaticon-gallery"></i>
                                    {{'listing'|translate}}</a></li>
                            <li><a (click)="toReviews()" class="anchor2"><i class="flaticon-writing"></i>
                                    {{'reviews'|translate}}</a></li>
                        </ul>
                    </div>
                    <div class="listing-widget listing-widget-desc" id="about" dir="{{textDir}}">
                        <h3 class="title">{{'description' | translate}}</h3>
                        <p *ngIf="user?.description?.length == null" class="text-center">No description Found</p>
                        <p *ngIf="user?.description?.length != null"> {{user?.description}}</p>
                    </div>

                    <!-- <div class="listing-widget listing-widget-desc" id="about" *ngIf="currentUser && !id">
                        <h3 class="title">Package</h3>
                         <p>name : {{currentUser?.subscribtion?.name}}</p>
                         <p>price : {{currentUser?.subscribtion?.price}} EGP per month</p>
                        <p>remaining listings : {{currentUser?.packages_left}}</p>
                    </div> -->

                    <div *ngIf="properties" class="listing-widget gallery-slider" id="listings">
                        <h3 class="title" dir="{{textDir}}">{{'listings by user' | translate}}</h3>
                        <div class="row p-3 " style="text-align: center;" dir="{{textDir}}">
                            <div *ngFor="let property of properties | paginate: { itemsPerPage:6 , currentPage: p }"
                                class="col-lg-4 col-md-6 col-sm-12">
                                <app-carousel [property]="property" [isAuthUser]="isAuthUser"></app-carousel>
                            </div>
                            <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous' | translate}}"
                                nextLabel="{{'next'|translate}}"></pagination-controls>
                        </div>

                        <!-- <owl-carousel-o [options]="customOptions"> -->
                        <!-- <div class="row"> -->
                        <!-- <ng-container *ngFor="let property of properties"> -->
                        <!-- <ng-template carouselSlide [id]="property.id" class="col-4"> -->
                        <!-- <app-carousel [property]="property" class="col-4"></app-carousel> -->
                        <!-- </ng-template> -->
                        <!-- </ng-container>     -->
                        <!-- </div> -->
                        <!-- </owl-carousel-o> -->

                    </div>

                    <div *ngIf="currentUser && currentUser.id != id && id" class="listing-widget" id="add-review"
                        dir="{{textDir}}">
                        <div class="listing-contact-title">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="listing-widget-mail">
                                        <i class="flaticon-email-2"></i>
                                        <div class="content">
                                            <h3>{{ 'submit review' | translate}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-form">
                            <form id="contactForm" [formGroup]="reviewForm" (ngSubmit)="onSubmitReview()">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea formControlName="comment" class="form-control" id="message"
                                                cols="30" rows="8" required
                                                placeholder="{{'your message'| translate}}"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 mb-3">
                                        <ngb-rating [max]="5" [(rate)]="currentRate" (rateChange)="change($event)" [readonly]="false"></ngb-rating>
                                        <p class="help-block" *ngIf="formSubmitted && !reviewForm.controls['rating'].value">
                                            <small style="color: red;" class="help-block">{{'give rating'
                                                |translate}}</small>
                                        </p>
                                    </div>
                                    <div class="col-lg-12 col-md-12 ">
                                        <button type="submit" class="default-btn border-radius">{{'submit the review'
                                            |translate}} <i class='bx bx-plus'></i></button>
                                        <p *ngIf="formSubmitted && this.review" class="alert alert-success mt-3">
                                            {{"successfull review" | translate }}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="listing-widget listing-comment" id="reviews" dir="{{textDir}}">
                        <h3 class="title">{{'reviews' | translate}}</h3>
                        <p *ngIf="reviews?.length == 0" class="text-center">No Reviews Found</p>
                        <ul *ngIf="reviews?.length > 0">
                            <li *ngFor="let review of reviews">
                                <div class="content">
                                    <h3>{{review?.from_user?.fullname}}</h3>
                                    <span>{{review.created_at | date}}</span>
                                    <p>{{review.comment}}</p>
                                    <div class="comment-rating">
                                        <i *ngFor="let i of [].constructor(review.rating)" class='bx bxs-star'></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
