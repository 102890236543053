import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService  {


  constructor(private http: HttpClient) { }

  propertiesImages(form): FormArray {
    return form.get("propertyimages") as FormArray
  }

  changeObjectToForm(form) {
    const formData = new FormData();
    var features = form.controls['features'].value;
    if (features && features.length != 0) {
      features.forEach((element, index) => {
        formData.append('features[' + index + ']', element);
      });
    }
    var videos = form.controls['videos'].value;
    if (videos && videos.length != 0) {
      videos.forEach((element, index) => {
        if(element.url)
        formData.append('videos[' + index + ']', element.url);
      });
    }

    formData.append('id', form.get('id').value);
    formData.append('name', form.get('name').value);
    formData.append('name_ar', form.get('name_ar').value);
    formData.append('description', form.get('description').value);
    formData.append('description_ar', form.get('description_ar').value);
    formData.append('price_level', form.get('price_level').value);
    formData.append('status', form.get('status').value);
    formData.append('deliverydate', form.get('deliverydate').value);
    formData.append('finish_type', form.get('finish_type').value);
    formData.append('payment_method', form.get('payment_method').value);
    formData.append('thumbnail', form.get('thumbnail').value);
    formData.append('cover', form.get('cover').value);
    formData.append('bedrooms', form.get('bedrooms').value);
    formData.append('bathrooms', form.get('bathrooms').value);
    formData.append('size', form.get('size').value);
    formData.append('units', form.get('units').value);
    formData.append('roof', form.get('roof').value);
    formData.append('garden', form.get('garden').value);

    // formData.append('yearbuild', form.get('yearbuild').value);
    // formData.append('yeardeliver', form.get('yeardeliver').value);
    formData.append('view', form.get('view').value);
    formData.append('price', form.get('price').value);
    // formData.append('maxPrice', form.get('maxPrice').value);
    // formData.append('minPrice', form.get('minPrice').value);
    // formData.append('outdoors', form.get('outdoors').value);
    // formData.append('price_per_meter', form.get('price_per_meter').value);
    formData.append('map', form.get('map').value);
    formData.append('user_id', form.get('user_id').value);
    formData.append('compound_id', form.get('compound_id').value);
    formData.append('location_id', form.get('location_id').value);
    formData.append('propertytype_id', form.get('propertytype_id').value);
    // formData.append('government_id', form.get('government_id').value);
    formData.append('company_id', form.get('company_id').value);
    formData.append('developer_id', form.get('developer_id').value);
    return formData;
  }

  changeImageObjectToForm(form, index) {
    const formData = new FormData();
    formData.append('id', this.propertiesImages(form).at(index).get('propertyimage_id').value);
    formData.append('image', this.propertiesImages(form).at(index).get('propertyimage').value);
    return formData;
  }


  addProperty(newProperty) {
    return this.http.post(environment.localUrl + 'properties/create', newProperty);
  }

  addPropertyImages(propertyImage) {
    return this.http.post(environment.localUrl + 'properties-images/create', propertyImage);
  }

  addPropertyVideos(videos) {
    return this.http.post(environment.localUrl + 'properties-videos/create', videos );
  }


  getUserProperties(id) {
    return this.http.get<any>(environment.localUrl + 'properties/user/' + id);
  }

  deletePropertyImage(id) {
    return this.http.post(environment.localUrl + 'properties-images/delete/' + id, { responseType: 'json' });
  }
  updateProperty(processProperty) {
    return this.http.post(environment.localUrl + 'properties/update', processProperty, { responseType: 'json' })
  }

  getProperty(id) {
    return this.http.get<any>(environment.localUrl + 'properties/show-listing?id=' + id, { responseType: "json" });
  }
  showProperty(){
    return this.http.get<any>(environment.localUrl + 'users/check-publishing')
  }

  getProperties(page) {
    return this.http.get<any>(environment.localUrl + 'properties/list?page=' + page);
  }


  getNewest(number) {
    return this.http.get<any>(environment.localUrl + 'properties/newest/' + number);
  }
  getHomepage() {
    return this.http.get<any>(environment.localUrl + 'homepage');
  }

  delete(formData) {
    return this.http.post(environment.localUrl + 'properties/delete', formData);
  }

  getReviews(id) {
    return this.http.get<any>(environment.localUrl + 'properties/' + id + '/reviews');
  }

  addReview(formData) {
    return this.http.post(environment.localUrl + 'reviews/create', formData);
  }

  attachFeature(formData) {
    return this.http.post(environment.localUrl + 'properties/attach-feature', formData);
  }


  updatePrice(form) {
    return this.http.get<any>(environment.localUrl + 'properties/update-price?government=' + form.government_id +
      '&area=' + form.area + '&propertytype=' + form.propertytype_id);
  }

  max() {
    return this.http.get<any>(environment.localUrl + 'properties/max');
  }

  search(page, formData) {
    return this.http.get<any>(environment.localUrl + 'properties/search?page=' + page +
      '&government=' + formData['government_id'] + '&locations=' +formData['locations'] +'&area=' + formData['area'] + '&units=' + formData['units'] +
      formData['area'] + '&propertytype=' + formData['propertytype_id'] + '&bedrooms=' + formData['bedrooms'] + '&compounds='+formData['compounds']+
      '&bathrooms=' + formData['bathrooms'] + '&description=' + formData['description'] +
      '&minPrice=' + formData['minPrice'] + '&maxPrice=' + formData['maxPrice'] +
      '&features=' + formData['features'] +'&outdoors=' +formData['outdoors']  ); //
  }

  clickOnProperty(id){
    return this.http.get<any>(environment.localUrl + 'properties/click?id=' + id);
  }

  addToFavorite(id){
    return this.http.get<any>(environment.localUrl + 'properties/add-to-favorite?id=' + id);
  }

  sort(sortBy){
    return this.http.get<any>(environment.localUrl + 'properties/sort?sortBy=' + sortBy);
  }

}
