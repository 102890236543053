<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'about' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'about' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70" dir="{{textDir}}">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <span>{{'get know' | translate}} </span>
                        <h2>{{'A Awesome History Behind Our Success' | translate}}</h2>
                        <p> {{'why description' | translate}}</p>
                    </div>

                    <!-- <div class="about-list">
                        <ul>
                            <li><i class="flaticon-curve-arrow"></i> {{'why history 1'|translate}}</li>
                            <li><i class="flaticon-curve-arrow"></i> {{'why history 2'|translate}} </li>
                            <li><i class="flaticon-curve-arrow"></i> {{'why history 3'|translate}}</li>
                        </ul>
                    </div> -->
                    <!-- <a routerLink="/team" class="default-btn border-radius">{{'Our Team' | translate}} <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/joel-filipe-RFDP7_80v5A-unsplash-min.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="choose-area" dir="{{textDir}}">
    <div class="container">
        <div class="section-title text-center">
            <span>{{'Choose Reasons'|translate}}</span>
            <h2>{{'Why Should Choose Us'|translate}}</h2>
        </div>

        <div class="choose-width pt-100 pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-phone-call"></i>
                        <h3>{{'why reason title 1' | translate}}</h3>
                        <p>{{'why reason description 1'|translate}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="choose-card">
                        <i class="flaticon-web-page"></i>
                        <h3>{{'why reason title 2' |translate}}</h3>
                        <p>{{'why reason description 2' | translate}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="choose-card">
                        <i class="flaticon-support"></i>
                        <h3>{{'why reason title 3'|translate}}</h3>
                        <p>{{'why reason description 3'|translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="video-area video-area-bg" dir="{{textDir}}">
    <div class="container">
        <div class="video-content">
            <h2>{{'Are-You-Ready To Start Your Journey?'|translate}}</h2>
        </div>
    </div>
</div>
<section class="process-area" dir="{{textDir}}">
    <div class="process-into process-into-2  pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>{{'Process'|translate}}</span>
                <h2><b>{{'How It Works'|translate}}</b></h2>
                <p>{{'process description'|translate}} </p>
            </div>

            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1" >1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>{{'Find Interesting Place'|translate}}</h3>
                            <p>{{'process step 1'|translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 ">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>{{'Choose What To Do'|translate}}</h3>
                            <p>{{'process step 2'|translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>{{'Find What You Want'|translate}}</h3>
                            <p>{{'process step 3'|translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div> -->
    </div>
</section>

<!-- <div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Team</span>
            <h2>Our Skilled Team</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/roland-samuel-MZ5A24H1JqU-unsplash-min.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Austin Evon</a></h3>
                        <span>Co-manager associated</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/linkedin-sales-solutions-pAtA8xe_iVM-unsplash-min.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Taylor Anderson</a></h3>
                        <span>Business consultant</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="team-card">
                    <a routerLink="/team"><img src="assets/img/team/christina-wocintechchat-com-_5_CBVCLBsY-unsplash-min.jpg" alt="Team Images"></a>
                    <div class="content">
                        <h3><a href="#">Jorgia Klev</a></h3>
                        <span>Business Manger</span>
                        <p>Lorem ipsum dolor sit ame consectur adipisicingsedo eiusmod tempor</p>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<app-footer-style-one></app-footer-style-one>
