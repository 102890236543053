import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from '@realstate/auth/auth-guard.service';
import { LookupResolver } from "@realstate/services/lookup.resolver";

/*Components*/
import { MaincomponentComponent } from "./maincomponent/maincomponent.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { PricingComponent } from "./components/pages/pricing/pricing.component";
import { CategoryComponent } from "./components/pages/category/category.component";
import { ShopComponent } from "./components/pages/shop/shop.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { ProductsDetailsComponent } from "./components/pages/products-details/products-details.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TestimonialsComponent } from "./components/pages/testimonials/testimonials.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { LoginRegisterComponent } from "./components/pages/login-register/login-register.component";
import { TermsConditionComponent } from "./components/pages/terms-condition/terms-condition.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ListingComponent } from "./components/pages/listing/listing.component";
import { ListingDetailsComponent } from "./components/pages/listing-details/listing-details.component";
import { MyProfileComponent } from "./components/pages/profile/my-profile/my-profile.component";
import { UserProfileComponent } from "./components/pages/profile/user-profile/user-profile.component";
import { EditProfileComponent } from "./components/pages/profile/edit-profile/edit-profile.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ChangePasswordRequestComponent } from "./components/pages/change-password-request/change-password-request.component";
import { ChangePasswordComponent } from "./components/pages/change-password/change-password.component";
import { AddListingComponent } from "./components/pages/add-listing/add-listing.component";
import { SubscribtionsComponent } from "./components/pages/subscribtions/subscribtions.component";
import { MyListingsComponent } from "./components/pages/profile/my-listings/my-listings.component";
import { MyReviewsComponent } from "./components/pages/profile/my-reviews/my-reviews.component";
import { ChangeProfilePasswordComponent } from "./components/pages/profile/change-profile-password/change-profile-password.component";
import { FavoritesComponent } from "./components/pages/favorites/favorites.component";
import { CheckOtpComponent } from "./components/pages/check-otp/check-otp.component";
import { GetGuard } from "./auth/get-guard.service";
const routes: Routes = [
  {
    path: "",
    component: MaincomponentComponent,
    resolve: { items: LookupResolver },
    children: [
      { path: "", redirectTo: "homepage", pathMatch: "full" },
      {
        path: "homepage",
        component: HomeOneComponent
      },
      {
        path: "check-otp",
        component: CheckOtpComponent,
        canActivate: [AuthGuard]
      },
      { path: "home-three", component: HomeThreeComponent },
      { path: "about", component: AboutComponent },
      { path: "team", component: TeamComponent },
      { path: "pricing", component: PricingComponent },
      { path: "category", component: CategoryComponent },
      { path: "shop", component: ShopComponent },
      { path: "cart", component: CartComponent },
      { path: "checkout", component: CheckoutComponent },
      { path: "products-details", component: ProductsDetailsComponent },
      { path: "faq", component: FaqComponent },
      { path: "testimonials", component: TestimonialsComponent },
      { path: "error", component: ErrorComponent },
      { path: "login-register", component: LoginRegisterComponent , canActivate:[GetGuard] },
      { path: "terms-condition", component: TermsConditionComponent },
      { path: "privacy-policy", component: PrivacyPolicyComponent },
      { path: "coming-soon", component: ComingSoonComponent },
      //{path: 'listing?page=:page', component: ListingComponent},
      { path: "listing", component: ListingComponent, pathMatch: "full" },
      { path: "listing-details/:id/:title", component: ListingDetailsComponent },
      {
        path: "profile",
        component: MyProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "edit-profile",
        component: EditProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "my-listings",
        component: MyListingsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "my-reviews",
        component: MyReviewsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "change-profile-password",
        component: ChangeProfilePasswordComponent,
        canActivate: [AuthGuard]
      },
      { path: "profile/:id", component: UserProfileComponent },
      { path: "blog", component: BlogComponent },
      { path: "blog-details", component: BlogDetailsComponent },
      { path: "contact", component: ContactComponent },
      { path: "reset-password", component: ChangePasswordRequestComponent },
      { path: "change-password", component: ChangePasswordComponent },
      {
        path: "add-listing",
        component: AddListingComponent,
        canActivate: [AuthGuard]
      },
      { path: "my-favorites", component: FavoritesComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: "legacy",
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "top"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
