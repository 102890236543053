import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getLocation(id){
    return this.http.get<any>(environment.localUrl + 'locations/show/' + id, { responseType: "json" });
    // return this.http.get<any>(environment.localUrl + 'locations/districts/' + id, { responseType: "json" });

  }

  getLocations(){
    return this.http.get<any>(environment.localUrl +'locations/list?offset=0&limit=10');

  }
  
  getSearchLocations(typing){
    return this.http.get<any>(environment.localUrl +'locations/search?typing='+ typing);
  }
  getCompounds(locationId){
    return this.http.get<any>(environment.localUrl +`locations/compounds/${locationId}`);
  }
  
}
