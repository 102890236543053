<app-navbar-style-one></app-navbar-style-one>
<carousel interval="5000" style="height: 300px;">
  <slide *ngFor="let slide of slides" style="position: relative;">
    <img
      [src]="[environment + slide.image]"
      class="img-responsive"
      alt="image slide"
      style="display: block; width: 100%; height: 700px;"
    />
    <div class="overlay">
      <div class="desc">
        <div class="text">
          <h1>
            {{textDir =='ltr' ? slide.name : slide.name_ar }} <b>{{textDir =='ltr' ? slide.shortdesc :  slide.shortdesc_ar}}</b>
          </h1>
        </div>
      </div>
    </div>
  </slide>
</carousel>

<div class="banner-form-area banner-form-mt" dir="{{textDir}}">
  <div class="container">
    <div class="banner-form border-radius">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row ">
          <div class="form-group">
            <div
              class="form-control px-2"
              style="border-right: none !important;"
            >
              <ng-select #select 
                notFoundText="{{textDir == 'rtl' ? 'لم يتم العثور على هذا الموقع' : 'this location is not found'}}"
                [multiple]="true"
                [clearable]="false"
                [maxSelectedItems]="5"
                [closeOnSelect]="true"
                id="locationMegaSearch"
                bindLabel="{{textDir == 'rtl' ? 'name_ar' : 'name'}}"
                [items]="locations"
                [(ngModel)]="allLocations"
                placeholder="{{textDir == 'rtl' ? 'اكتب اي موقع' : 'type any location'}}"
                formControlName="locations"
                [loading]="locationsLoading"
                (add)="addTagFn($event)"
                [typeahead]="locationsInput$"
                [hideSelected]="true"
                [clearSearchOnAdd]="true"
                class="customBar"
                [searchFn]="customSearchFn"
                [isOpen]="searchMenuFlag"
                (focus)="onOpenSearchMenuFlag()"
                (blur)="onCloseSearchMenuFlag()"                
              >
                <!--
                  <ng-option [value]="item" *ngFor="let item of locations">
                      <div class="">
                          <h5 class="card-title">
                             {{textDir == 'rtl' ? item.name_ar : item.name}}</h5>
                          <h6 class="card-subtitle mb-2 text-muted">
                              <span>{{textDir == 'rtl' ? item.path_ar : item.path}}</span>
                   </h6>
                      </div>
                  </ng-option>
                -->
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div style="margin: 10px 0;" class="">
                    <div class="">
                      <h5 class="card-title">
                        {{ textDir == "rtl" ? item.name_ar : item.name }}
                      </h5>
                      <h6 class="card-subtitle mb-2 text-muted">
                        <span>{{
                          textDir == "rtl" ? item.path_ar : item.path
                        }}</span>
                      </h6>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row  px-2 d-flex align-items-center searchItems">
          <!--
            <div class="col-lg-3 col-md-3">
                <div class="form-group">
                    <i class='flaticon-place'></i>
                    <select class="form-control" formControlName="location_id">
                        <option [value]="null">{{'location' | translate}}</option>
                        <option *ngFor="let location of locations" [value]="location.id">
                            {{translate.currentLang == 'ar' ? location.name_ar : location.name}}</option>
                    </select>
                </div>
            </div>
          -->

          <!--
            <div class="col-lg-3 col-md-3">
                <div class="form-group">
                    <i class='flaticon-vision'></i>
                    <input class="form-control" type="text"  placeholder="{{'what are you looking for' | translate}}" formControlName="description">
                </div>
            </div>
          -->

          <div class="col-lg-3 col-md-3">
            <div class="searchBarItem">
              <!-- <i class='flaticon-category'></i> -->
              <select class="form-control" formControlName="propertyType">
                <option [value]="null">{{
                  "all property types" | translate
                }}</option>
                <option *ngFor="let type of property_types" [value]="type.id">{{
                  translate.currentLang == "ar"
                    ? type.name_ar
                    : (type.name | titlecase)
                }}</option>
              </select>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 hideMobile">
            <div class="searchBarItem">
              <!-- <label style="top: 0 ">{{'bedrooms' | translate}}</label> -->
              <select class="form-control" formControlName="bedrooms">
                <option [value]="null">{{ "bedrooms" | translate }}</option>
                <option [value]="null">{{ "any" | translate }}</option>
                <option
                  *ngFor="let e of [].constructor(maxBedrooms); let i = index"
                  [value]="i + 1"
                  >{{ i + 1 }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-3 mx-auto d-flex justify-content-between hideMobile"
          >
            <div class="searchCheckBox">
              <input
                type="checkbox"
                id="ForBuy"
                name="units"
                class="mx-1"
                (change)="attachUnit(1, $event.target.checked)"
              />
              <label for="For Buy">{{ "buy" | translate }}</label>
            </div>
            <div class="searchCheckBox">
              <input
                type="checkbox"
                id="ForRent"
                name="units"
                class="mx-1"
                (change)="attachUnit(0, $event.target.checked)"
              />
              <label for="For Rent">{{ "rent" | translate }}</label>
            </div>
          </div>

          <!--
            <div class="form-group col-md-6">
                <label>{{'bedrooms' | translate}}*</label>
                <input type="number" min="1" class="form-control" value="1" required
                    formControlName="bedrooms" [ngClass]="{
                    'is-invalid': formSubmitted && f.bedrooms.invalid,
                    'is-valid': formSubmitted && !f.bedrooms.invalid
                    }">
                <span class="help-block" *ngIf="
                    formSubmitted &&
                    (f.bedrooms.invalid|| f.bedrooms.errors?.required)
                    ">
                    <small style="color: red;" class="help-block">{{"required" | translate}}</small>
                </span>
            </div>
          -->

          <!--
            <div class="col-lg-3 col-md-3">
                <div class="form-group">
                    <i class='flaticon-category'></i>
                    <select class="form-control" formControlName="bedrooms">
                        <option [value]="null">{{'Bedrooms' | translate}}</option>
                        <option *ngFor="let type of " [value]="type.id">{{type.name}}</option>
                    </select>
                </div>
            </div>
          -->
          <div
            class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0 text-ceter d-flex justify-content-end "
          >
            <button type="submit" class="default-btn border-radius searchBtn">
              {{ "search" | translate }} <i class="flaticon-loupe"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!--
  <div class="mt-5  d-center" style="text-align: center;">
      <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" />

      <p>Address: {{ userAddress }}</p>
      <p>Latitude: {{ userLatitude }}</p>
      <p>Longitude: {{ userLongitude }}</p>

  </div>
-->
<!-- <h1>locations</h1> -->
<!--
  <div *ngFor="let item of locations$|async " class="mt-5  d-center" style="text-align: center;">

      <span *ngIf="item.government?.name">{{item.government?.name}}</span>
      <span *ngIf="item.district?.name">{{'/'+item.district?.name}}</span>
      <span *ngIf="item.compounds?.name">{{'/'+item.compounds?.name}}</span>
  </div>
-->

<section class="place-list-area pb-70 pt-100" *ngIf="properties">
  <div class="container">
    <div class="section-title text-center mb-3">
      <h2>{{ "latest listings" | translate }}</h2>
    </div>
    <owl-carousel-o >
      <div class="row">
        <ng-container *ngFor="let property of properties">
          <div carouselSlide [id]="property.id" class="col-12 col-md-4">
            <app-carousel
              [property]="property"
              class="col-12 col-md-4"
            ></app-carousel>
          </div>
        </ng-container>
      </div>
    </owl-carousel-o>
  </div>
</section>

<div class="choose-area mb-5" dir="{{textDir}}">
  <div class="container">
    <div class="section-title text-center">
      <!-- <span>{{'Choose Reasons'|translate}}</span> -->
      <h2>{{ "Why Should Choose Us" | translate }}</h2>
    </div>

    <div class="choose-width pt-100 pb-70">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="choose-card">
            <i class="flaticon-phone-call"></i>
            <h3>{{ "why reason title 1" | translate }}</h3>
            <p>{{ "why reason description 1" | translate }}</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="choose-card">
            <i class="flaticon-web-page"></i>
            <h3>{{ "why reason title 2" | translate }}</h3>
            <p>{{ "why reason description 2" | translate }}</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
          <div class="choose-card">
            <i class="flaticon-support"></i>
            <h3>{{ "why reason title 3" | translate }}</h3>
            <p>{{ "why reason description 3" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="application-area pt-100 pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="application-img">
          <img
            src="assets/img/beitlist_-min.png"
            class="rounded-3"
            alt="Images"
          />
        </div>
      </div>
      <div class="col-lg-7">
        <div class="application-content">
          <div class="section-title">
            <span>{{ "beitlist" | translate }}</span>
            <h2>{{ "next home" | translate }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  <section class="testimonial-area pb-70 pt-100">
      <div class="container-fluid">
          <div class="section-title text-center">
              <span>{{'testimonials' | translate}}</span>
              <h2>{{'What Our Clients Say' | translate}}</h2>
          </div>

          <div class="testimonial-slider owl-carousel owl-theme">
              <div class="testimonial-item testimonial-item-bg">
                  <h3>Sanaik Tubi</h3>
                  <span>Arbon Restaurant</span>
                  <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                  <ul class="rating">
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                  </ul>
                  <div class="testimonial-top">
                      <i class='bx bxs-quote-left'></i>
                  </div>
              </div>

              <div class="testimonial-item testimonial-item-bg">
                  <h3>Oli Rubion</h3>
                  <span>Rubion Inc</span>
                  <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                  <ul class="rating">
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                  </ul>
                  <div class="testimonial-top">
                      <i class='bx bxs-quote-left'></i>
                  </div>
              </div>

              <div class="testimonial-item testimonial-item-bg">
                  <h3>Mashrof Ruin</h3>
                  <span>Pice Cafe</span>
                  <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                  <ul class="rating">
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                  </ul>
                  <div class="testimonial-top">
                      <i class='bx bxs-quote-left'></i>
                  </div>
              </div>

              <div class="testimonial-item testimonial-item-bg">
                  <h3>Sanaik Tubi</h3>
                  <span>Arbon Restaurant</span>
                  <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                  <ul class="rating">
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                  </ul>
                  <div class="testimonial-top">
                      <i class='bx bxs-quote-left'></i>
                  </div>
              </div>

              <div class="testimonial-item testimonial-item-bg">
                  <h3>Sanaik Tubi</h3>
                  <span>Arbon Restaurant</span>
                  <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                  <ul class="rating">
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                      <li><i class='bx bxs-star'></i></li>
                  </ul>
                  <div class="testimonial-top">
                      <i class='bx bxs-quote-left'></i>
                  </div>
              </div>
          </div>
      </div>
  </section>
-->

<app-footer-style-one></app-footer-style-one>
