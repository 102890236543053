<app-navbar-style-one (currentUserEmitter)="fetchData($event)"></app-navbar-style-one>

<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'reviews' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'reviews' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="container">
<div class="listing-widget listing-comment mt-3" id="reviews" dir="{{textDir}}"> 
    <h3 class="title">{{'reviews' | translate}}</h3>
    <p *ngIf="reviews?.length == 0" class="text-center">{{'no reviews' | translate}}</p>
    <ul *ngIf="reviews?.length > 0">
        <li  *ngFor="let review of reviews">
            <div class="content">
                <h3>{{review?.from_user?.fullname}}</h3>
                <span>{{review.created_at | date}}</span>
                <p>{{review.comment}}</p>
                <div class="comment-rating"> 
                    <i *ngFor="let i of [].constructor(review.rating)" class='bx bxs-star'></i>
                </div>
            </div>
        </li>
    </ul>
</div>
</div>
<app-footer-style-one></app-footer-style-one>
