import { Component, HostListener, OnInit } from '@angular/core';
import { PropertyTypesService } from '@realstate/services/property_types.service';
import { FeaturesService } from '@realstate/services/features.service';
import { GovernmentsService } from '@realstate/services/governments.service';
import { PropertiesService } from '@realstate/services/properties.service';
import { FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { UserService } from '@realstate/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocationsService } from '@realstate/services/locations.service';
import { LanguageService } from '@realstate/services/lang.service';
import { AuthService } from '@realstate/auth/auth.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.component.html',
  styleUrls: ['./add-listing.component.scss']
})
export class AddListingComponent implements OnInit {

  types: any = null;
  features: any = null;
  governments: any = null;
  locations: any = null;
  childLocations = [];
  compounds: any = [];
  currentUser: any = null;
  dropdownSettings = {};
  scrollValue: number = null;
  form: FormGroup;
  formSubmitted = false;
  propertyImagesForms = [];
  property_id: number;
  textDir: string = 'ltr';
  Publishing : boolean
  isLoading: boolean = false;
  loadingIndictor: boolean = false;
  disabled : boolean = false
  locationIndictor: boolean = false;
  isCompoundDdlShown : boolean =false;
  ThumbnailChanged : boolean = false
  coverPhoto : boolean = false
  selectedfiles : boolean = false
  errorMode : boolean = false
  errormessage : boolean = false
  compoundsList  = [];
  errors
  videoMode : boolean;
  property_name
  images = [];
  files = [];
  reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


  constructor(
    private propertyTypesService: PropertyTypesService,
    private featuresService: FeaturesService,
    private governmentsService: GovernmentsService,
    private locationsService: LocationsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private propertiesService: PropertiesService,
    private userService: UserService,
    private router: Router,
    public translate: TranslateService,
    private fb:FormBuilder,
    public authService: AuthService,
    private lang : LanguageService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Add-listing");

    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.getTypes();
    this.getFeatures();
    this.getGovernments();
    this.getUser();
    this.createForm();
    this.checkPublishing();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      limitSelection: 2
    };

  }

  checkPublishing(){
    this.propertiesService.showProperty().subscribe(res => {
      this.Publishing = res?.isAvailable
      console.log(this.Publishing);
    })
  }

  currentLang() {
      this.translate.currentLang = localStorage.getItem('lang')
    if (this.translate.currentLang == 'ar')
      this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  canAdd(currentUser) {
    // if(currentUser && (currentUser.packages_left == null || currentUser.packages_left <= 0)){
    //   this.toastr.error('You have no listings left to list');
    //   this.router.navigate(['/']);
    // }else
    if (!currentUser) {
      this.toastr.error('Log in to add listing');
      this.router.navigate(['/login-register']);
    }
    //  else {
    //   this.router.navigate(['/add-listing']);
    // }
  }

  getTypes() {
    this.propertyTypesService.getPropertyTypes().subscribe(
      (res) => {
        this.types = res.list;
      }
    );
  }

  getFeatures() {
    this.featuresService.getFeatures().subscribe(
      (res) => {
        this.features = res.list;
      }
    )
  }

  getGovernments() {
    this.governmentsService.getGovernments().subscribe(
      (res) => {
        this.governments = res.list;
      }
    )
  }


  getUser() {
    this.userService.getCurrent().subscribe(
      (res) => {
        this.currentUser = res.user;
        this.canAdd(this.currentUser);
      }
    )
  }

  createForm() {
    this.form = this.formBuilder.group({
      id: "",
      name: ["", Validators.compose([Validators.required])],
      name_ar: ["", Validators.compose([Validators.required, Validators.maxLength(40)])],
      description: ["", Validators.compose([Validators.required])],
      description_ar: ["", Validators.compose([Validators.required])],
      price_level: [1, Validators.compose([Validators.required])],
      status: [1, Validators.compose([Validators.required])],
      deliverydate: ["", Validators.compose([])],
      finish_type: [1, Validators.compose([Validators.required])],
      payment_method: [1, Validators.compose([Validators.required])],
      thumbnail: ['', Validators.compose([Validators.required])],
      cover: ['', Validators.compose([Validators.required])],
      bedrooms: [1, Validators.compose([Validators.required])],
      bathrooms: [1, Validators.compose([Validators.required])],
      size: ["", Validators.compose([Validators.required])],
      units: ["1" , Validators.compose([Validators.required])],
      //   yearbuild: [null, Validators.compose([])],
      //   yeardeliver: [null, Validators.compose([])],
      view: [1, Validators.compose([Validators.required])],
      price: ["", Validators.compose([Validators.required])],
      //   price_per_meter: ["", Validators.compose([])],
      map: ["", Validators.compose([Validators.required ])],
      user_id: ["", Validators.compose([Validators.required])],
      compound_id: [""],
      location_id: ["", Validators.compose([Validators.required])],
      propertytype_id: [1, Validators.compose([Validators.required])],
      features: [''],
      propertyimages: this.formBuilder.array([]),
      company_id: [0, Validators.compose([])],
      developer_id: [0, Validators.compose([])],
      videos: this.fb.array([this.createVideo()]),
      property_id : null ,
      roof : null ,
      garden : null
    })
  }
  getLocations(id , index = null){
    this.disabled = true
    this.loadingIndictor = true
    this.isCompoundDdlShown = false;
    this.compoundsList = [];
    if(index == null) this.childLocations = [] ;

    if(index < this.childLocations.length-1){
      this.childLocations = this.childLocations.splice(0 , index +1 )
      this.disabled = false
      }
    this.governmentsService.getGovernment(id).subscribe(
      (res) => {
      this.locations = res;
      this.loadingIndictor = false
      this.disabled = false

      if(res.length > 0){
      this.childLocations.push(res) // 0 > [cairo childs]
    }else{
      this.locationsService.getCompounds(id).subscribe(res => {
        if(res.length > 0){
          this.isCompoundDdlShown = true;
          this.disabled = false
          this.compoundsList = res
        }
      })
     }
    }
   )
 }
 scrollTo(id){
  console.log(id);
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "nearest"
  });
 }

  get videos():FormArray{
    return <FormArray> this.form.get('videos');
  }

  createVideo():FormGroup{
    return this.fb.group({
      url: null,
    })
  }

  addVideo() {
    this.videos.push(this.createVideo());
    console.log(this.videos.controls[0].value.url)
  }

  get f() {
    return this.form.controls;
  }

  propertyImages(form): FormArray {
    return form.get("propertyimages") as FormArray
  }

  newPropertyImage(): FormGroup {
    return this.formBuilder.group({
      propertyimage_id: "",
      propertyimage: ["", Validators.compose([])],
      property_id: ["", Validators.compose([])],
    })
  }

  addPropertyImage() {
    this.propertyImages(this.form).push(this.newPropertyImage());
  }

  onThumbnailChanged(event) {
    console.log(event.target.files[0].size);

    if (event.target.files.length > 0) {
      if(event.target.files[0].size < 1000000){
        const file = event.target.files[0];
        // const mimeType = file.type;
        // // if (mimeType.match(/image\/*/) == null) {
        // //     this.message = true;
        // //     return;
        // // }
        this.form.get('thumbnail').setValue(file);
        this.ThumbnailChanged = false
      }else{
        this.ThumbnailChanged = true
        this.form.get('thumbnail').setValue('');
      }
    }
  }

  onCoverChanged(event) {
    if (event.target.files.length > 0) {
      if(event.target.files[0].size < 1000000){

      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      this.coverPhoto = false
      this.form.get('cover').setValue(file);
    }else{
      this.coverPhoto = true
      this.form.get('cover').setValue('');

    }
  }
  }

  onSelectFile(event) {
    if(event.target.files[0].size < 1000000){
      if (event.target.files && event.target.files[0] ) {
        this.selectedfiles = false
      var filesAmount = event.target.files.length;
      var files = event.target.files;
      this.files = files;
      for (let i = 0; i < filesAmount; i++) {
        this.addPropertyImage();
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);

        this.propertyImages(this.form).controls.forEach((element, index) => {
          this.propertyImages(this.form).at(index).get('propertyimage').setValue(files[index]);
        });
      }
    }
  }else{
    this.selectedfiles = true
  }
}

  onSubmit() {
    const entries = Object.entries(this.f);
    if (!this.currentUser) {
      this.router.navigate(['/login-register']);
      return;
    } else {
      this.f.user_id.setValue(this.currentUser.id);
    }
    this.formSubmitted = true;
    console.log(this.form.value);

    if (!this.form.valid) {
      this.toastr.error('Please fill in the mandatory fields', 'Error');
      entries.forEach(ele => {
        if(ele[1].status == 'INVALID'){
          this.scrollTo(ele[0])
          // console.log(ele[0]);
        }

    })
      return;
    }
    entries.forEach(ele => {
    if(ele[0] == 'videos'){
      (ele[1].value[0].url == null) ?  this.videoMode = false :  this.videoMode = true
      console.log(this.videoMode);
    }
  })

    var formData = this.propertiesService.changeObjectToForm(this.form);
    this.propertyImages(this.form).controls.forEach((element, index) => {
      this.propertyImagesForms.push(this.propertiesService.changeImageObjectToForm(this.form, index));
    });

    this.saveData(formData, this.propertyImagesForms);
  }

  saveData(formData, propertyImagesForms) {
    console.log( propertyImagesForms);

    this.isLoading = true;

    this.propertiesService.addProperty(formData).subscribe(
      (response: any) => {
        this.property_id = response.record.id;
        this.property_name = response.record.slug
        if(this.property_id){
          formData.append('id', this.property_id);
          this.propertiesService.attachFeature(formData).subscribe(
            response => {
            },
            error => {
              this.toastr.error('Error adding property', 'error');
            }
          );

          this.propertyImagesForms.forEach((element, index) => {
            element.append('property_id', this.property_id);
          });

          propertyImagesForms.forEach((element, index) => {
            this.propertiesService.addPropertyImages(element).subscribe(res => {
              if(this.videoMode == false){
              this.isLoading = false;
              this.router.navigate(['/listing-details/' + this.property_id + this.property_name])
              }
            }, err => {
              this.isLoading = false;
              this.toastr.error('Property Created failed');
            })
          });

          if(this.videoMode == true){
            this.propertiesService.addPropertyVideos(formData).subscribe(
              response => {
                this.isLoading = false;
                this.router.navigate(['/listing-details/' + this.property_id +  this.property_name]);
                this.toastr.success('Property Created successfully', 'Success');
              },
              error => {
                this.toastr.error('Error adding property', 'error');
              }
            );
          }
          this.toastr.success('Property Created successfully', 'Success');


          // this.propertiesService.addPropertyVideos(this.form.get('videos').value , this.property_id).subscribe((response: any) => {
            // console.log(response);
          // })
          //Navigate to the new added property
        }else{
          this.errors =  response.record
          console.log(this.errors);
          // this.scrollTo(this.errors[0])
        }
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.toastr.error('Property Creation failed', 'Error');
      });
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    this.scrollValue = window.pageYOffset;
  }
}
