import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  constructor(private http: HttpClient) { }

  getFeature(id){
    return this.http.get<any>(environment.localUrl + 'features/show/' + id, { responseType: "json" });
  }

  getFeatures(){
    return this.http.get<any>(environment.localUrl +'features/list?offset=0&limit=10');
  }
  
}
