<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'subscribtions' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'subscribtions' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="plans-section mt-5" dir="{{textDir}}">
    <div class="container">
        <div class="shadow-sm p-3 mb-5 bg-white rounded">
            <h3 class="black-text">{{'choose your plan' | translate}}</h3>
            <div class="row mt-5">
                <div *ngFor="let subscribtion of subscribtions" class="col-md-3">
                    <div class="shadow-sm p-3 mb-5 bg-white rounded">
                        <div class="box">
                            <div class="row text-right">
                                <span class="sub-name">{{translate.currentLang == 'ar' ? subscribtion.name_ar : subscribtion.name | uppercase}}</span>
                            </div>
                            <div class="text-center white-text">
                                <h1 class="large-text">{{subscribtion.listsno != null ? "+" + subscribtion.listsno: "∞" }}</h1>
                                <h3>{{'listings' | translate}}</h3>
                                <p>/{{'month' | translate}}</p>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <h3 class="black-text text-center">{{ subscribtion.price != 0 ? subscribtion.price + ("egp" | translate) : "Free"}}</h3>
                        </div>
                        <div class="row mt-3">
                            <button *ngIf="user?.subscribtion_id == subscribtion.id" type="button" class="btn-cust btn btn-light btn-md" disabled>{{'subscribed' | translate}}</button>
                            <button *ngIf="user?.subscribtion_id != subscribtion.id" type="button" class="btn-cust default-btn" (click)="onSelect(subscribtion.id)">{{'subscribe' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>