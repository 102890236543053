import { BrowserModule } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TokenInterceptorService } from '@realstate/services/token-interceptor.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from "@realstate/auth/auth.service";
import { AuthGuard } from "@realstate/auth/auth-guard.service";
import { AngularFireModule } from "@angular/fire";
// import { AngularFireAuthModule } from "@angular/fire/auth";
import { NgxPaginationModule } from 'ngx-pagination';
// import { NgxGalleryModule } from '@kolkov/ngx-gallery';
// import { Ng5SliderModule } from 'ng5-slider';
import {CarouselModule} from 'ngx-bootstrap/carousel';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ToastrModule } from 'ngx-toastr';
// import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@realstate/i18n/i18n.module';
// import { NgxSpinnerModule } from "ngx-spinner";
// import { ShareModule } from 'ngx-sharebuttons';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { SafePipe } from './safe.pipe';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
// import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
// import { AgmCoreModule } from '@agm/core';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// import {NgOtpInputModule} from "ng-otp-input";
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { NgImageSliderModule } from 'ng-image-slider';
// import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
// import { FacebookModule } from 'ngx-facebook';
import { LookupResolver } from "./services/lookup.resolver";


/*Components*/
import { MaincomponentComponent } from './maincomponent/maincomponent.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { SidebarComponent } from '@realstate/components/common/sidebar/sidebar.component';
import { CarouselComponent } from '@realstate/components/common/carousel/carousel.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { MyProfileComponent } from './components/pages/profile/my-profile/my-profile.component';
import { UserProfileComponent } from './components/pages/profile/user-profile/user-profile.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ChangePasswordRequestComponent } from './components/pages/change-password-request/change-password-request.component';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { AddListingComponent } from './components/pages/add-listing/add-listing.component';
import { SubscribtionsComponent } from './components/pages/subscribtions/subscribtions.component';
import { EditProfileComponent } from './components/pages/profile/edit-profile/edit-profile.component';
import { MyListingsComponent } from './components/pages/profile/my-listings/my-listings.component';
import { MyReviewsComponent } from './components/pages/profile/my-reviews/my-reviews.component';
import { ChangeProfilePasswordComponent } from './components/pages/profile/change-profile-password/change-profile-password.component';
import { ContactInfoComponent } from './components/common/contact-info/contact-info.component';
import { FavoritesComponent } from './components/pages/favorites/favorites.component';
import { MapComponent } from './components/map/map.component';
import { CheckOtpComponent } from './components/pages/check-otp/check-otp.component';
import { GetGuard } from './auth/get-guard.service';




var firebaseConfig = {
  apiKey: "YOUR_API_KEY", //YOUR_API_KEY
  authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
  databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
  projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
  storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
  appId: "YOUR_APP_ID", //YOUR_APP_ID
  measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    MaincomponentComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    FooterStyleOneComponent,
    FooterStyleTwoComponent,
    SidebarComponent,
    AboutComponent,
    TeamComponent,
    PricingComponent,
    CategoryComponent,
    FaqComponent,
    TestimonialsComponent,
    ErrorComponent,
    LoginRegisterComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ShopComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    ListingComponent,
    ListingDetailsComponent,
    MyProfileComponent,
    UserProfileComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactComponent,
    ChangePasswordRequestComponent,
    ChangePasswordComponent,
    SidebarComponent,
    CarouselComponent,
    AddListingComponent,
    SubscribtionsComponent,
    EditProfileComponent,
    MyListingsComponent,
    MyReviewsComponent,
    ChangeProfilePasswordComponent,
    ContactInfoComponent,
    SafePipe,
    FavoritesComponent,
    // MapComponent,
    CheckOtpComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    // NgbModule,
    // FacebookModule.forRoot(),
    CarouselModule,
    // NgImageSliderModule,
    // NgxGalleryModule,
    NgxPaginationModule,
    // NgxIntlTelInputModule,
    // NgxUsefulSwiperModule,
    BrowserModule,
    ReactiveFormsModule,
    // NgOtpInputModule,
    AngularFireModule.initializeApp(firebaseConfig),
    // AngularFireAuthModule,
    // Ng5SliderModule,
    // BrowserAnimationsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    ToastrModule.forRoot(),
    // NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    I18nModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    // NgxSpinnerModule,
    // FontAwesomeModule,
    // ShareIconsModule,
    // ShareButtonsModule,
    // RecaptchaV3Module,
    // GooglePlaceModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBbMpO2BX-DJH2vM-SsylcMI5iivUmeFyk',
    //   libraries: ['places']
    // }),
    // AutocompleteLibModule

  ],
  providers: [
    AuthService,
    CookieService,
    LookupResolver,
    AuthGuard,
    GetGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '482838588946-kvhfvq9rr96saklg7hjfgq3o67ga47kt.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '373827414866362'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LfiY4wgAAAAABUKOIMjyHShj6XSOLCWX42tU-Iy" }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
