import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

    langSubject =new BehaviorSubject<any>('');
    constructor(private http: HttpClient) { }

    getlang(): Observable <any> {

      return this.langSubject.asObservable();
    }

    selectlang(product:any){
      this.langSubject.next(localStorage.getItem('lang'));
    }
}
