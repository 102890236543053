import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '@realstate/services/user.service';
import { map, take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { SocialAuthService, GoogleLoginProvider, SocialUser, FacebookLoginProvider } from 'angularx-social-login';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AuthService } from '@realstate/auth/auth.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NotificationService } from '@realstate/services/Notification.service';
import { LanguageService } from '@realstate/services/lang.service';
import { Width } from 'ngx-owl-carousel-o/lib/services/carousel.service';
import { SocialLoginservice } from '@realstate/services/socialLogin.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.css']
})

export class LoginRegisterComponent implements OnInit {

  loginFormSubmitted = false;
  isLoginFailed = false;
  registerForm: FormGroup;
  loginForm: FormGroup;
  registerFormSubmitted = false;
  record: any = null;
  socialUser: SocialUser;
  isLoggedin: boolean;
  captchaResponse: string;
  error: string;
  errormessage
  textDir: string;
  isSocial: number;
  PhoneNumberFormat = PhoneNumberFormat;
  socialType: any = 0;
  typeData: any = {};
  userdata : any = {};
  user_id;
  user;
  formsboolean : boolean = false
  socialbool : boolean = false
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  @ViewChild('typeBtn') typeBtn: ElementRef<HTMLElement>;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private cookieService: CookieService,
    private socialAuthService: SocialAuthService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    private notification : NotificationService,
    private lang : LanguageService,
    private socialLogin : SocialLoginservice,
    private titleService: Title

  ) {
    this.titleService.setTitle('Login-register');
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      } else {
        this.textDir = 'ltr';
      }

    });

    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });

    this.registerForm = this.formBuilder.group({
      id: ["", Validators.compose([])],
      fullname: ["", Validators.compose([Validators.required])],
      email: ["", Validators.compose([Validators.required, Validators.email,])],
      phonenumber: ["01", Validators.compose([Validators.minLength(11), Validators.maxLength(11), Validators.required])],
      password: ["", Validators.compose([Validators.required])],
      confirmPassword: ["", Validators.compose([Validators.required])],
      jobRole: ["", Validators.compose([Validators.required])],
      terms: ["", Validators.compose([Validators.required])],
      social: ""
    }, { validator: (!this.socialbool) ? this.checkPasswords : null });  }

  closeAlert() {
    this.isLoginFailed = false;
    this.error = null;
  }


  ngOnInit() {
    // this.spinner.hide();
    window.scrollTo(0,0)
    this.registerForm.controls['social'].setValue(0);

  }



  checkVerified(res){
    if(res.user.isVerified){
      this.router.navigate(['/']).then(res => {
        this.toastr.success('Welcome back')
      })
    }else{
      this.router.navigate(['/check-otp'])
    }
  }
  

  get lf() {
    return this.loginForm.controls;
  }

  currentLang() {
      this.translate.currentLang = localStorage.getItem('lang')
    if (this.translate.currentLang == 'ar')
      this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  login() {
    this.loginFormSubmitted = true;
    if (!this.loginForm.valid && this.isSocial != 1)
      return;
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });

    var formData = this.userService.changeLoginObjectToForm(this.loginForm.value);
    this.userService.login(formData).pipe(
      map((response: any) => {
        if (response) {
          this.cookieService.set('access_token', response.access_token);
          localStorage.setItem('is_verified',response.user.isVerified);
          console.log(response);

        }
      }, error => {
        console.log(error);
      })
    ).subscribe(res => {
      console.log(this.isSocial);
      
      if (!this.isSocial) {
        if(!this.auth.isVerified()){
          this.router.navigate(['/check-otp'])
        }else{
          this.router.navigate(['/']).then(res => {
            this.toastr.success('Welcome back')
          }),
          this.spinner.hide()
        }
      }else{
        window.location.reload()
      }
    }, err => {
      this.isLoginFailed = true,
        this.spinner.hide(),
        this.errormessage = err?.error?.error
        this.loginFormSubmitted = true;
    });

  }

  checkPasswords(group: FormGroup) {
    const pass = group?.controls?.password?.value;
    const confirmPass = group?.controls?.confirmPassword?.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  get f() {
    return this.registerForm.controls;
  }
  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  newhandleSocial(){
    this.socialAuthService.authState.pipe(
      take(1)).subscribe(res => {
      console.log(res);
      this.userdata= {
        email : res.email,
        provider_id : res.response.id,
        provider : res.provider, 
        name : res.name
      }
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        }); 
      this.socialLogin.checkLogin(this.userdata).subscribe(res => {
        console.log(res);
        this.cookieService.set('access_token', res.access_token);
        localStorage.setItem('is_verified',res.user.isVerified);
        this.spinner.hide()
        this.checkVerified(res)
      }, err => {
        this.spinner.hide()
        this.socialbool = true
        this.formsboolean = true
        this.registerForm.patchValue({
          email: this.userdata['email'],
          fullname : this.userdata['name'],
        });
      })
    })
  }

  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.handleSocial();
    this.newhandleSocial();
  }


  register() {
    if(this.socialbool == false){
      this.registerFormSubmitted = true;
      if (!this.registerForm.valid) {
        return;
      }
      // if (this.validateEmail) {
      //   return;
      // }
      this.isSocial = 0;
      var formData = this.userService.changeObjectToForm(this.registerForm.value, this.isSocial);
      this.saveData(formData);
    
    }else{
      this.registerForm.removeControl('password');
      this.registerForm.removeControl('confirmPassword');
      // this.registerForm.get('phonenumber').setValue(this.registerForm.get('phonenumber').value.number)
      
      this.registerFormSubmitted = true;
      if (!this.registerForm.valid) {
        console.log(this.registerForm.value);
        return;
      }
      this.userdata.phonenumber = this.registerForm.get('phonenumber').value
      this.userdata.usertype = this.registerForm.get('jobRole').value
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        }); 
      this.socialLogin.socialRegister(this.userdata).subscribe(res => {
        console.log(res);
        this.spinner.hide()
        this.cookieService.set('access_token', res.access_token);
        localStorage.setItem('is_verified',res.user.isVerified)
        this.router.navigate(['/check-otp']);
      })
    }
    }

  saveData(formData = null) {
    // console.log(this.registerForm.controls.jobRole.value);
    if (this.isSocial)
      formData.delete('usertype');
      formData.append('usertype', this.socialType);

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.userService.addUser(formData).subscribe(
      (response: any) => {
        this.spinner.hide();
        console.log(response);
        this.record = response.record;

        if (this.record.original.error == true) {
          if (this.socialUser) {
            this.login();
            return;
          }
          this.error = this.record.original.message[0];
          return;
        } else if (this.record.original[0].usertype == 0) {
          // this.login();
          this.typeData.user_id = this.record.original[0].id;
          this.attachUserType();
          return
        }
        if (!this.socialUser) {
          this.loginForm.controls['email'].setValue(this.registerForm.controls['email'].value);
          this.loginForm.controls['password'].setValue(this.registerForm.controls['password'].value);
        }
        this.login()
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error('Error Registering', 'Error');
      });
  }


  attachUserType() {
    this.spinner.hide()
    let el = this.typeBtn.nativeElement;
    el.click();
  }
  loginbtn(){
    this.formsboolean = false
  }

  registerbtn(){
    this.formsboolean = true

  }


  submitType() {
    // this.registerForm.controls.jobRole.setValue(this.socialType);
    this.typeData.usertype = this.socialType;
    this.saveType();

    // let user = this.userService.getCurrentAuth().subscribe((res) => {
    //   console.log(res);
    //   // this.typeData.user_id = res.user.id;
    // });
  }

  saveType() {
    this.userService.updateUserType(this.typeData).subscribe((res) => {
      console.log(res);
      this.checkVerified(this.user)
    })
  }

  public resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  domains = ['gmail', 'hotmail', 'yahoo', 'outlook', 'example']

  validateEmail() {
    if (this.registerForm.controls.jobRole.value == 3) {
      let email = this.registerForm.controls.email.value;
      let domain = email.split('@')[1].split('.')[0];
      if (this.domains.includes(domain)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }
  //^[\w-\.]+@([gmail]+\.)+[\w-]{2,4}$
}

