
<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'edit profile' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'edit profile' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="plans-section mt-5"  dir="{{textDir}}">
    <div class="container">
        <div class="shadow-sm p-3 mb-5 bg-white rounded">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row mt-5">
                <div class="col-md-6">
                    <h3 class="black-text">{{'profile setup' | translate}}</h3>
                    <div class="row image-section mt-5" [ngStyle]="{ 'background-image': !currentUser?.cover && !coverUrl ?  'url(../../../../../assets/img/Group9.png)' : 
                    currentUser?.cover && !coverUrl ? 'url(' + userCover + ')' : 'url(' + coverUrl + ')'}">
                        <div class="col-md-4 text-center">
                            <i *ngIf="!currentUser?.image && !imageUrl" class="user-icon fa fa-user"></i>
                            <img *ngIf="currentUser?.image && !imageUrl" src="{{userImage}}" style="border-radius: 50%; width: 120px; height: 120px;">
                            <img *ngIf="imageUrl" src="{{imageUrl}}" alt="imageUrl" style="border-radius: 50%; width: 120px; height: 120px;">
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="text-white" style="font-weight: bold; font-size: 30px;">{{currentUser?.fullname}}</p>
                                </div>
                                <div class="col-md-6">
                                    <label for="upload-button-1" class="default-btn">
                                        <span class="ml-3">{{'add profile picture' | translate}}</span>
                                    </label>
                                    <input type="file" id="upload-button-1" class="upload-button" 
                                    (change)="onThumbnailChanged($event)" name="thumbnail" 
                                    accept="image/x-png,image/gif,image/jpeg">
                                </div>
                                <div class="col-md-6">
                                    <label for="upload-button-2" class="default-btn">
                                        <span class="ml-3">{{'add cover picture' | translate}}</span>
                                    </label>
                                    <input type="file" id="upload-button-2" class="upload-button" (change)="onCoverChanged($event)" 
                                    name="cover" accept="image/x-png,image/gif,image/jpeg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="mb-1">{{'fullname' | translate}}</label>
                        <input type="text" formControlName="fullname" id="name" class="form-control" required 
                        data-error="Please enter your Fullname" [ngClass]="{
                            'is-invalid': formSubmitted && f.fullname.invalid,
                            'is-valid': formSubmitted && !f.fullname.invalid
                            }">
                            <span class="help-block" *ngIf="
                            formSubmitted &&
                            (f.fullname.invalid|| f.fullname.errors?.required)
                            ">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                    </div>
                    <div class="form-group">
                        <label class="mb-1 mt-2">{{'email' | translate}}</label>
                        <input type="email" formControlName="email" id="email" class="form-control" required 
                        data-error="Please enter email" [ngClass]="{
                            'is-invalid': formSubmitted && f.email.invalid,
                            'is-valid': formSubmitted && !f.email.invalid
                            }">
                            <span class="help-block" *ngIf="
                            formSubmitted && f.email.errors?.required
                            ">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                            <span class="help-block" *ngIf=" 
                            formSubmitted && f.email.errors?.email
                            ">
                                <small style="color: red;" class="help-block">{{'mail format' | translate}}</small>
                            </span>
                    </div>
                    <div class="form-group">
                        <label class="mb-1 mt-2">{{'phone' | translate}}</label>
                        <input type="text" formControlName="phonenumber" class="form-control" required 
                        data-error="Please enter your Phone Number" [ngClass]="{
                            'is-invalid': formSubmitted && f.phonenumber.invalid,
                            'is-valid': formSubmitted && !f.phonenumber.invalid
                            }">
                            <span class="help-block" *ngIf="
                            formSubmitted &&
                            (f.phonenumber.errors?.required || f.phonenumber.errors?.minlength || f.phonenumber.errors?.maxlength)
                            ">
                                <small style="color: red;" class="help-block">{{'phone format' | translate}}.</small>
                            </span>
                    </div>
                    <div class="form-group" *ngIf="jobRoleUser=== 3">
                        <label>{{'address' | translate}}</label>
                        <input type="text" formControlName="address" class="form-control" required 
                        data-error="Please enter your Address" [ngClass]="{
                            'is-invalid': formSubmitted && f.address.invalid,
                            'is-valid': formSubmitted && !f.address.invalid
                            }">
                            <span class="help-block" *ngIf="
                            formSubmitted &&
                            (f.address.invalid|| f.address.errors?.required)
                            ">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                    </div>
                    <div class="form-group">
                        <label class="mb-1 mt-2">{{'whatsapp number' | translate}}</label>
                            <div class="input-group">
                                <div class="radio d-inline-block mr-2 mb-1 col-md-6">
                                <input class="form-check-input" type="radio" name="whatsapp" (change)="isDifferent(0)" id="broker1" [value]=1 checked>
                                <label for="broker1" class="ms-1">{{'same as phone' | translate}}</label>
                                </div>
                                <div class="radio d-inline-block col-md-6">
                                    <input class="form-check-input" type="radio" name="whatsapp" (change)="isDifferent(1)" id="broker2" [value]=0>
                                <label for="broker2" class="ms-1">{{'different from phone' | translate}}</label>
                                </div>
                            </div>
                    </div>
                    <div *ngIf="displayWhatsapp" class="col-12">
                        <div class="form-group">
                        <label class="mb-1 mt-2">{{'whatsapp number' | translate}}</label>
                        <input type="text" class="form-control" minLength="11" formControlName="whatsapp_no" required [ngClass]="{
                            'is-invalid': formSubmitted && f.whatsapp_no.invalid,
                            'is-valid': formSubmitted && !f.whatsapp_no.invalid
                            }">
                        <span class="help-block" *ngIf="
                        formSubmitted &&
                        (f.whatsapp_no.errors?.minlength || f.whatsapp_no.errors?.maxlength)
                        ">
                            <small style="color: red;" class="help-block">{{'phone format' | translate}}</small>
                        </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="mb-1 mt-2">{{'description'| translate}}</label>
                        <textarea class="form-control" rows="5" placeholder="Description" formControlName="description" [ngClass]="{
                        'is-invalid': formSubmitted && f.description.invalid,
                        'is-valid': formSubmitted && !f.description.invalid
                        }"></textarea>
                        <span class="help-block" *ngIf="
                        formSubmitted &&
                        (f.description.invalid|| f.description.errors?.required)
                        ">
                            <small style="color: red;" class="help-block">{{"required" | translate}}</small>
                        </span>
                    </div>
                    <div class="row mb-1 mt-2">
                        <div class="form-group col-md-6">
                            <label>{{"facebook" | translate}}</label>
                            <input type="url" formControlName="facebook" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'twitter' | translate}}</label>
                            <input type="url" formControlName="twitter" class="form-control">
                        </div>
                    </div>
                    <div class="row mb-1 mt-2">
                        <div class="form-group col-md-6">
                            <label>{{'instagram' | translate}}</label>
                            <input type="url" formControlName="instagram" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'pinterest' | translate}}</label>
                            <input type="url" formControlName="pinterest" class="form-control">
                        </div>
                    </div>
                    <div class="row mb-1 mt-2">
                        <div class="form-group col-md-6">
                            <label>{{'youtube' | translate}}</label>
                            <input type="url" formControlName="youtube" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'website' | translate}}</label>
                            <input type="url" formControlName="website" class="form-control">
                        </div>
                    </div>
                    <div class="row mb-1 mt-2" *ngIf="jobRoleUser != 4">
                        <div class="form-group col-md-6">
                            <label>{{'Job Role' | translate}}</label>
                            <input type="text" formControlName="jobRole" class="form-control">
                            </div>
                            </div>

                    <div class="form-group">
                        <label class="mb-1 mt-2">Contact by</label>
                            <div class="input-group">
                                <div class="radio d-inline-block mr-2 mb-1 col-md-4">
                                    <input class="form-check-input" type="checkbox" formControlName="email_contact" id="custom-check-1">
                                    <label id="custom-check-1" class="ms-1">{{'email'| translate}}</label>
                                </div>
                                <div class="radio d-inline-block col-md-4">
                                    <input class="form-check-input" type="checkbox" formControlName="phone_contact" id="custom-check-2">
                                    <label for="custom-check-2" class="ms-1">{{"phone" | translate}}</label>
                                </div>
                                <div class="radio d-inline-block col-md-4">
                                    <input class="form-check-input" type="checkbox" formControlName="whatsapp_contact" id="custom-check-3">
                                    <label for="custom-check-3" class="ms-1">{{"whatsapp" | translate}}</label>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 text-center mt-5">
                <button type="submit" class="default-btn" style="padding: 12px 27px; border-radius: 0px; font-size: 15px;">{{'done' | translate}} </button>
            </div>
            </form>
        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
