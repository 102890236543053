import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { AuthService } from '@realstate/auth/auth.service';
import { LanguageService } from '@realstate/services/lang.service';
import { UserService } from '@realstate/services/user.service';
@Component({
  selector: 'app-my-listings',
  templateUrl: './my-listings.component.html',
  styleUrls: ['./my-listings.component.scss']
})
export class MyListingsComponent implements OnInit {

  current: any = null;
  textDir: string;
  properties: any = null;
  page: number = 1;
  noProperties: boolean;
  isAuthUser: boolean;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private auth:AuthService,
    private lang : LanguageService,
    private titleService: Title
  ) {
    this.titleService.setTitle('My listing')
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0,0)
  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }

  fetchData(event){
    this.current = event.currentUser;
    if(this.current)
      this.userProperties(this.current.id)
    else
      this.userProperties(0);
  }

  userProperties(id){
    this.userService.getUserPropertiesAuth(id).subscribe(
      (response) =>{
        this.properties = response.record;
      }
    )
  }

  isAuth(){
    if(this.auth.isAuthenticated()){
      this.isAuthUser = true ;
    }else{
      this.isAuthUser = false
    }
  }


}
