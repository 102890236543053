import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    NotificationSubject =new BehaviorSubject<any>('');
    ClientnameSubject = new BehaviorSubject<any>('');
    userlogin = new BehaviorSubject<any>('');
    constructor(private http: HttpClient ) { }
  
    getNotification() : Observable <any> {
      return this.NotificationSubject.asObservable();     
    }
  
    selectNotification(notification:any){   
      this.NotificationSubject.next(notification);
    }
    getClientname() : Observable <any> {
      return this.ClientnameSubject.asObservable();     
    }
    selectClient(clientname:any){   
      this.ClientnameSubject.next(clientname);
    }
    getuserlogin() : Observable <any> {
      return this.userlogin.asObservable();     
    }
    selectuser(username:any){   
      this.userlogin.next(username);
    }
}
