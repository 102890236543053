import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Subscribtionservice {

  constructor(private http:HttpClient) { }

  getSubscribtions(){
      return this.http.get<any>(environment.localUrl + 'subscribtions/list?offset=0&limit=10');
  }

  getSubscribtion(id){
    return this.http.get<any>(environment.localUrl + 'subscribtions/show/' + id + '/customer');
  }

}
