<div class="user-area">
  <div class="container-fluid mt-5">
      <div class="row align-items-center">
          <div class="col-lg-12 col-xl-12">
              <div class="user-section text-center">
                  <div class="user-content">
                      <h2>Beitlist</h2>
                  </div>
                  <div class="user-all-form">
                      <div class="contact-form">
                        <div *ngIf="errors != null" class="alert alert-danger mt-3">
                          {{ errors }}
                        </div>
                        <div *ngIf="successMsg != null" class="alert alert-primary mt-3">
                          {{ successMsg?.message }}
                        </div>
                          <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                              <div class="row">
                                  <div class="col-lg-12 ">
                                      <div class="form-group">
                                          <i class='bx bx-user'></i>
                                          <input type="text" formControlName="email" id="name" class="form-control" 
                                          required data-error="Please enter your Username or Email" 
                                          placeholder="Email">
                                      </div>
                                  </div>
                                  <hr>
                                  <ngx-spinner></ngx-spinner>
                              </div>
                              <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="default-btn  user-all-btn">Reset Password</button>
                            </div>
                          </form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>