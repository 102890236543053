<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Terms & Conditions</h3>
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Terms & Conditions</li>
            </ul> -->
        </div>
    </div>
</div>

<div class="terms-conditions-area ptb-100" dir="{{textDir}}">
    <div class="container">
        <div class="single-content">
            <!-- <h3>Welcome to Pilar Terms & Conditions</h3> -->
            <!-- <p >{{setting['terms_and_conditions']}}</p> -->
            <p [innerHtml]="setting['terms_and_conditions']" [innerHtml]="setting['terms_and_conditions_ar']"
            >{{textDir == 'ltr' ? setting['terms_and_conditions'] : setting['terms_and_conditions_ar']}}</p>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>