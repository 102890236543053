import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { Router, ActivatedRoute } from "@angular/router";
import { PropertiesService } from '@realstate/services/properties.service';
import { PropertyTypesService } from '@realstate/services/property_types.service';
import { FeaturesService } from '@realstate/services/features.service';
import { GovernmentsService } from '@realstate/services/governments.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from '@realstate/auth/auth.service';
import { LanguageService } from '@realstate/services/lang.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {

  environment = environment.imagesUrl + '/properties-thumbnails';
  term: string;
  search_result: any = null;
  descriptionParam = null;
  page = null;
  government = null;
  properties: any = null;
  total = null;
  noProperties: boolean;
  formSubmitted = false;
  form: FormGroup = null;
  side: number = null;
  params: any = null;
  submitType: boolean = false;
  formArray: any = null;
  searchValue: string;
  governmentValue: string;
  locations:any ;
  compounds : any ;
  propertyTypeValue: string;
  textDir: string;
  homeForm: number = null;
  searchForm: number = null;
  unitsArray: any;
  isAuthUser: boolean;
  loadingIndictor : boolean

  constructor(
    private activatedRoute: ActivatedRoute,
    public propertiesService: PropertiesService,
    public router: Router,
    public formBuilder: FormBuilder,
    public propertyTypesService: PropertyTypesService,
    public featuresService: FeaturesService,
    public governmentsService: GovernmentsService,
    public translate: TranslateService,
    public auth: AuthService,
    private lang : LanguageService , 
    private titleService: Title

  ) {
    this.titleService.setTitle("Listing");
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.page = this.activatedRoute.snapshot.queryParams['page'];
    if(typeof this.page === "undefined"){
      this.page=1;
    }
    this.government = this.activatedRoute.root.snapshot.queryParams['government'];
    this.navSearch();
  }

  currentLang() {
      this.translate.currentLang = localStorage.getItem('lang')
    if (this.translate.currentLang == 'ar')
      this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  navSearch() {
    if (this.activatedRoute.snapshot.queryParams['search']) {
      this.searchValue = this.activatedRoute.snapshot.queryParams['search'];
      this.searchForm = 1;
      this.homeForm = null;
      this.side = null;
      this.formArray = [];
      this.formArray.push({
        government_id: "null", minPrice: "", maxPrice: "",
        description: this.searchValue, propertytype_id: "null", bedrooms: "null", bathrooms: "null", area: "", features: "", searchForm: this.searchForm
      });
      this.search(1, this.formArray[0]);
    } else if (this.activatedRoute.snapshot.queryParams['form']) {
      this.searchValue = this.activatedRoute.snapshot.queryParams['description'];
      // this.governmentValue = this.activatedRoute.snapshot.queryParams['government'];
      this.locations = this.activatedRoute.snapshot.queryParams['locations'];
      this.compounds = this.activatedRoute.snapshot.queryParams['compounds'];

      console.log(this.locations , this.compounds);

      this.propertyTypeValue = this.activatedRoute.snapshot.queryParams['propertytype'];
      this.homeForm = 1;
      this.searchForm = null;
      this.side = null;
      this.formArray = [];
      this.formArray.push({
        government_id: this.governmentValue ? this.governmentValue : "null",locations:this.locations?this.locations:'null', minPrice: "", maxPrice: "",
        description: this.searchValue, propertytype_id: this.propertyTypeValue ? this.propertyTypeValue : "null", outdoors: [null] ,
        bedrooms: "null", bathrooms: "null", area: "", features: "", homeForm: this.homeForm, units: this.activatedRoute.snapshot.queryParams['units'] ,compounds:this.compounds
      });
      this.search(1, this.formArray[0]);
    } else {
      this.selectPropertiesType();
    }
  }

  fetchData(event) {
    console.log(event);
    this.formArray = event.form;
    this.homeForm = null;
    this.searchForm = null;
    if (event.num == 1) {
      this.search(1, this.formArray);
      this.page = 1;
      this.side = event.side;
    } else {
      this.search(this.page, this.formArray);
    }
  }

  selectPropertiesType() {
    if (this.formArray != null)
      this.search(this.page, this.formArray);
    else
      this.getProperties(this.page);
  }

  checkDescriptionParam(descriptionParam) {
    if (descriptionParam != null)
      return true;
    else
      return false;
  }

  changePage(id) {
    this.page = id;
    console.log(id);
    if (window.innerWidth >= 768) {
      window.scroll(0,500);
    }else{
      window.scroll(0,1500);
    }
    if (this.searchForm != null || this.homeForm != null)
      this.search(this.page, this.formArray[0]);
    else if (this.side != null)
      this.search(this.page, this.formArray);
    else
      this.getProperties(this.page);
  }

  getProperties(page) {
    this.propertiesService.getProperties(page).subscribe(
      response => {
        this.properties = response.list.data;
        console.log(this.properties);

        this.total = response.list.total;
        // this.router.navigate([],
        //   {  //    relativeTo: this.activatedRoute,
        //     queryParams: { page: page }
        //   });
      });

  }

  search(page, form) {
    console.log(form);
    this.loadingIndictor = true
    this.propertiesService.search(page, form).subscribe(
      response => {
        console.log(response);
        this.properties = response.list.data;
        this.total = response.list.total;
        this.loadingIndictor = false

        if (this.total == 0) {
          this.noProperties = true;
        }
        else {
          this.noProperties = false;
        }
        // this.router.navigate([],
        //   {
        //     queryParams: {
        //       page: page,
        //       formType: form['homeForm'],
        //       search: form['searchForm'],
        //       side: this.side,
        //       government: form['government_id'],
        //       minPrice: form['minPrice'],
        //       maxPrice: form['maxPrice'],
        //       area: form['area'],
        //       propertytype: form['propertytype_id'],
        //       bedrooms: form['bedrooms'],
        //       bathrooms: form['bathrooms'],
        //       description: form['description'],
        //       features: form['features'],
        //       units: form['units']
        //     }
        //   });
      });
  }
  clear(x){
    this.sort(x)
    this.noProperties = false
  }

  sort(sortBy) {
    if(sortBy == 'null'){
      sortBy = 1;
    }
    this.loadingIndictor = true
    this.propertiesService.sort(sortBy).subscribe((res) => {
      this.properties = res.list.data;
      this.loadingIndictor = false
      console.log(this.properties);
      this.total = res.list.total;
  })
  }

  isAuth(){
    if(this.auth.isAuthenticated()){
      this.isAuthUser = true ;
    }else{
      this.isAuthUser = false
    }
  }
}
