import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {

  constructor(private http: HttpClient) { }

  getSlides(){
    return this.http.get<any>(environment.localUrl +'slides/list');
  }

}
