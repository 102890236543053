<app-navbar-style-one></app-navbar-style-one>
<div class="inner-banner inner-bg1" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'add listing' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'add listing' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="wrapper" dir="{{textDir}}" >
    <div class="side">
        <h3>{{'add new property' | translate}}</h3>
        <div class="row {{scrollValue < 750 ? 'main-color' : ''}}">
            <div class="col-md-1">
                <i class="fa fa-th-large"></i>
            </div>
            <div class="col-md-11">
                <span class="">{{'step 1' | translate}}: {{'details information' | translate}}</span>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-9">
                <p class="{{scrollValue < 750 ? 'main-color' : ''}}"> ({{"bedrooms" | translate}},
                    {{'bathrooms' | translate}}, {{'area' | translate}}, ...) </p>
            </div>
        </div>
        <div class="row mt-4 {{scrollValue > 750 && scrollValue < 1200 ? 'main-color' : ''}}">
            <div class="col-md-1">
                <i class="fa fa-th-large"></i>
            </div>
            <div class="col-md-11">
                <span>{{'step 2' | translate}}: {{'scene' | translate}}</span>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-9">
                <p class="{{scrollValue > 750 && scrollValue < 1200 ? 'main-color' : ''}}">
                    ({{'description' | translate}}, {{'view' | translate}}, {{'location' | translate}},..)</p>
            </div>
        </div>
        <div class="row mt-4 {{scrollValue > 1200 ? 'main-color' : ''}}">
            <div class="col-md-1">
                <i class="fa fa-th-large"></i>
            </div>
            <div class="col-md-11">
                <span>{{'step 3' | translate}}: {{'media' | translate}}</span>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-9">
                <p class="{{scrollValue > 1200 ? 'main-color' : ''}}">({{'photos' | translate}},
                    {{'property thumbnail' | translate}},...)</p>
            </div>
        </div>
    </div>
    <div class="main">
        <div class="shadow p-5 mb-5 bg-white rounded">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" id="formId">
                <div class="step1">
                    <p>{{'step 1' | translate}}: {{'details information' | translate}}</p>
                    <div class="row mt-5">
                        <div class="form-group col-md-6">
                            <label>{{'property name' | translate}}*</label>
                            <input type="text" class="form-control" id="name"
                                placeholder="{{'property name' | translate}}" formControlName="name" [ngClass]="{
                                'is-invalid': formSubmitted && f.name.invalid,
                                'is-valid': formSubmitted && !f.name.invalid
                                }">
                            <span class="help-block"
                                *ngIf="(formSubmitted || f.name.touched)&&( f.name.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                            <span class="help-block"
                                *ngIf="(formSubmitted || f.name.touched) && ( f.name.errors?.maxlength)">
                                <small style="color: red;" class="help-block">{{'max error' | translate}}</small>
                            </span>
                            <span class="help-block"
                                *ngIf="(formSubmitted || f.name.touched) && ( f.name.errors?.maxlength)">
                                <small style="color: red;" class="help-block">{{'max error' | translate}}</small>
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'property arabic name' | translate}}*</label>
                            <input type="text" class="form-control" id="name_ar"
                                placeholder="{{'property arabic name' | translate}}" required formControlName="name_ar"
                                [ngClass]="{
                                'is-invalid': formSubmitted && f.name_ar.invalid,
                                'is-valid': formSubmitted && !f.name_ar.invalid
                                }">
                            <span class="help-block"
                                *ngIf="(formSubmitted || f.name_ar.touched) &&(f.name_ar.invalid|| f.name_ar.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                            <span class="help-block"
                                *ngIf="(formSubmitted || f.name_ar.touched) && ( f.name_ar.errors?.maxlength)">
                                <small style="color: red;" class="help-block">{{'max error' | translate}}</small>
                            </span>

                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>{{'bedrooms' | translate}}*</label>
                            <input type="number" min="1" class="form-control" value="1" required
                                formControlName="bedrooms" id="bedrooms"
                                [ngClass]="{'is-invalid': formSubmitted && f.bedrooms.invalid,'is-valid': formSubmitted && !f.bedrooms.invalid}">
                            <span class="help-block"
                                *ngIf="formSubmitted &&(f.bedrooms.invalid|| f.bedrooms.errors?.required)">
                                <small style="color: red;" class="help-block">{{"required" | translate}}</small>
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{"bathrooms" | translate}}*</label>
                            <input type="number" min="1" class="form-control" value="1" required
                                formControlName="bathrooms" id="bathrooms"
                                [ngClass]="{'is-invalid': formSubmitted && f.bathrooms.invalid,'is-valid': formSubmitted && !f.bathrooms.invalid}">
                            <span class="help-block"
                                *ngIf="formSubmitted && (f.bathrooms.invalid|| f.bathrooms.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>{{'property type' | translate}}</label>
                            <select class="form-select" formControlName="propertytype_id" id="propertytype_id">
                                <option *ngFor="let type of types" value="{{type.id}}">
                                    {{ translate.currentLang == 'ar' ? type.name_ar : type.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'status' | translate}}</label>
                            <select class="form-select" formControlName="status" id="status">
                                <option value="1">{{'delivered' | translate}}</option>
                                <option value="2">{{'off plan' | translate}}</option>

                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-3">
                            <label>{{'finish type' | translate}}</label>
                            <select class="form-select" formControlName="finish_type" id="finish_type">
                                <option value="1">{{'core and shell' | translate}}</option>
                                <option value="2">{{'semi finished' | translate}}</option>
                                <option value="3">{{'fully finished' | translate}}</option>
                                <option value="4">{{'fully finshed and furnished' | translate}}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-3" *ngIf="form.value.status == '2'">
                            <label>{{'delivery date' | translate}}</label>
                            <input type="date" class="form-control" placeholder="delivery date"
                                formControlName="deliverydate" id="deliverydate">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>{{'unit size' | translate}}*</label>
                            <input type="number" min="50" class="form-control" placeholder="Ex: 123"
                                formControlName="size" id="size"
                                [ngClass]="{'is-invalid': formSubmitted && f.size.invalid,'is-valid': formSubmitted && !f.size.invalid}">
                            <span class="help-block" *ngIf="formSubmitted &&(f.size.invalid|| f.size.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                        </div>
                        <!-- buy or rent -->
                        <div class="form-group col-md-6">
                            <label>{{'buyorrent' | translate}}</label>
                            <select class="form-select" formControlName="units" id="units">
                                <option value="1">{{'buy' | translate}}</option>
                                <option value="2">{{'rent' | translate}}</option>
                                <!-- <option value="3">{{'buy' | translate}} {{'or' | translate}} {{'rent' | translate}}</option> -->
                            </select>
                            <span class="help-block"
                                *ngIf="formSubmitted && (f.units.invalid|| f.units.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                        </div>
                        <!-- <div class="form-group col-md-6">
                            <label>{{'price level' | translate}}</label>
                            <select class="form-select" formControlName="price_level" id="price_level">
                                <option value="1">{{'economic' | translate}}</option>
                                <option value="2">{{'midrange' | translate}}</option>
                                <option value="3">{{'luxury' | translate}}</option>
                            </select>

                        </div> -->
                    </div>

                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>{{'price' | translate}}({{"egp" | translate}})</label>
                            <input type="number" min="1" class="form-control" placeholder="Ex: 100000" id="price"
                                formControlName="price" id="price" [ngClass]="{
                                    'is-invalid': formSubmitted && f.price.invalid,
                                    'is-valid': formSubmitted && !f.price.invalid
                                    }">
                            <span class="help-block" *ngIf="
                                    formSubmitted &&
                                    (f.price.invalid|| f.price.errors?.required)
                                    ">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'payment method' | translate}}</label>
                            <select class="form-select" formControlName="payment_method" id="payment_method">
                                <option value="1">{{'cash' | translate}}</option>
                                <option value="2">{{'installments' | translate}}</option>
                                <option value="3">{{'cash' | translate}} {{'or' | translate}} {{'installments' |
                                    translate}}</option>
                            </select>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="step2">
                    <p>{{'step 2' | translate}}: {{'scene' | translate}}</p>
                    <div class="row mt-5">
                        <div class="form-group col-md-6">
                            <label>{{'features' | translate}}*</label>
                            <ng-select [items]="features" bindLabel="name" bindValue="id" [multiple]="true"
                                [closeOnSelect]="false" placeholder="{{'select-feature' | translate}}"
                                formControlName="features" id="features">
                            </ng-select>
                        </div>


                        <div class="form-group col-md-6">
                            <label>{{'view' | translate}}</label>
                            <select class="form-select" formControlName="view" id="view">
                                <option value="1">{{'sea' | translate}}</option>
                                <option value="2">{{'garden' | translate}}</option>
                                <option value="3">{{'park' | translate}}</option>
                                <option value="4">{{'street' | translate}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>{{'government' | translate}}</label>
                            <img *ngIf="loadingIndictor" src="assets\img\loading.gif" alt="loadingIndictor"
                                style="width: auto; height: auto; margin-left: 20px;">
                            <select [disabled]="disabled" class="form-select"
                                (change)="getLocations($event.target.value)" formControlName="location_id"
                                id="location_id">
                                <option *ngFor="let government of governments" value="{{government.id}}">
                                    {{ translate.currentLang == 'ar' ? government.name_ar : government.name}}</option>
                            </select>
                        </div>
                        <ng-container *ngFor="let childLocation of childLocations , let i= index ">
                            <div class="form-group col-md-6">
                                <label>{{'location' | translate}}</label>
                                <select [disabled]="disabled" class="form-select" formControlName="location_id"
                                    (change)="getLocations($event.target.value , i)">
                                    <option *ngFor="let location of childLocation" value="{{location.id}}">
                                        {{translate.currentLang == 'ar' ? location.name_ar : location.name}}</option>
                                </select>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isCompoundDdlShown">
                            <div class="form-group col-md-6">
                                <label>{{'compound' | translate}}</label>
                                <select class="form-select" formControlName="compound_id">
                                    <option *ngFor="let compound of compoundsList" value="{{compound.id}}">
                                        {{translate.currentLang == 'ar' ? compound.name_ar : compound.name}}</option>
                                </select>
                            </div>
                        </ng-container>
                    </div>
                    <hr>
                    <!-- <label>{{'location' | translate}}</label> -->
                    <!-- <app-map></app-map> -->
                    <hr>
                    <label>{{'Outdoor Area' | translate}}</label>
                    <div class="row mt-3">
                        <div class="form-group col-md-6">
                            <label>{{'Garden' | translate}}</label>
                            <input type="number" min="1" class="form-control" id="garden"
                                placeholder="{{'enter garden area' | translate}}( &#13217; )" formControlName="garden"
                                id="garden">
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{'Roof' | translate}}</label>
                            <input type="number" min="1" class="form-control" id="roof"
                                placeholder="{{'enter roof area' | translate}}( &#13217; )" formControlName="roof"
                                id="roof">
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="form-group col-md-6">
                        <label>{{'google map' | translate}}*</label>
                        <input type="url" class="form-control"
                            placeholder="Ex: https://www.google.com.eg/maps/place/Cairo+International+Stadium/@30.069896,31.3147681...."
                            formControlName="map" id="map"
                            [ngClass]="{'is-invalid': formSubmitted && f.map.invalid,'is-valid': formSubmitted && !f.map.invalid}">
                        <span class="help-block" *ngIf="formSubmitted &&(f.map.invalid|| f.map.errors?.required)">
                            <small style="color: red;" class="help-block">{{"required" | translate}}</small>
                        </span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group col-md-12">
                        <label>{{'description' | translate}}*</label>
                        <textarea class="form-control" rows="5" formControlName="description" id="description"
                            [ngClass]="{'is-invalid': formSubmitted && f.description.invalid,'is-valid': formSubmitted && !f.description.invalid}"></textarea>
                        <span class="help-block"
                            *ngIf="formSubmitted &&(f.description.invalid|| f.description.errors?.required)">
                            <small style="color: red;" class="help-block">{{"required" | translate}}</small>
                        </span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="form-group col-md-12">
                        <label>{{"description arabic" | translate}}*</label>
                        <textarea class="form-control" rows="5" formControlName="description_ar" id="description_ar"
                            [ngClass]="{
                                'is-invalid': formSubmitted && f.description_ar.invalid,
                                'is-valid': formSubmitted && !f.description_ar.invalid}"></textarea>
                        <span class="help-block"
                            *ngIf="formSubmitted &&(f.description_ar.invalid|| f.description_ar.errors?.required)">
                            <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                        </span>
                    </div>
                </div>

                <hr>
                <div class="step3">
                    <p>{{'step 3' | translate}}: {{'media' | translate}}</p>
                    <div class="row mt-5 ">
                        <div class="form-group col-md-6">
                            <span>{{"property thumbnail" | translate}}</span><br>
                            <label for="upload-button-1" class="upload-label">
                                <i class="fa fa-camera" style="margin-right: 3%;"></i>
                            </label><span>{{f.thumbnail.value.name}}</span>
                            <input type="file" id="upload-button-1" class="upload-button"
                                (change)="onThumbnailChanged($event)" name="thumbnail"
                                accept="image/x-png,image/gif,image/jpeg"
                                [ngClass]="{'is-invalid': formSubmitted && f.thumbnail.invalid,'is-valid': formSubmitted && !f.thumbnail.invalid}">
                            <span class="help-block"
                                *ngIf="formSubmitted &&(f.thumbnail.invalid|| f.thumbnail.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                            <br>
                            <span class="help-block" *ngIf="ThumbnailChanged ">
                                <small style="color: red;" class="help-block"> This photo must be less than 1
                                    mega</small>
                            </span>
                        </div>
                        <div class="form-group col-md-6">
                            <span>{{'property cover' | translate}}</span><br>
                            <label for="upload-button-2" class="upload-label">
                                <i class="fa fa-camera" style="margin-right: 3%;"></i>
                            </label><span>{{f.cover.value.name}}</span>
                            <input type="file" id="upload-button-2" class="upload-button"
                                (change)="onCoverChanged($event)" name="cover"
                                [ngClass]="{'is-invalid': formSubmitted && f.cover.invalid,'is-valid': formSubmitted && !f.cover.invalid}"
                                accept="image/x-png,image/gif,image/jpeg">
                            <span class="help-block"
                                *ngIf="formSubmitted &&(f.cover.invalid|| f.cover.errors?.required)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                            <br>
                            <span class="help-block" *ngIf="coverPhoto">
                                <small style="color: red;" class="help-block"> This photo must be less than 1
                                    mega</small>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="form-group col-md-6 ">
                            <span>{{'property images' | translate}}</span><br>
                            <label for="upload-button-3" class="upload-label">
                                <i class="fa fa-camera" style="margin-right: 3%;"></i>
                            </label><span *ngFor="let file of files">{{file.name}}</span>
                            <input type="file" id="upload-button-3" class="upload-button"
                                (change)="onSelectFile($event)" name="propertyimage" multiple
                                accept="image/x-png,image/gif,image/jpeg">
                            <span class="help-block" *ngIf="formSubmitted && (f.cover.invalid)">
                                <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                            </span>
                            <br>
                            <span class="help-block" *ngIf="selectedfiles ">
                                <small style="color: red;" class="help-block">Photos must be less than 1 mega</small>
                            </span>
                        </div>
                        <div>
                            <ul>Notes :
                                <li><small>the maximum size of the photos must not be more than 1m</small></li>
                                <li><small>the extension of the photos must be jpg , png </small></li>
                            </ul>
                        </div>
                    </div>
                    <hr>
                    <span>{{'property videos' | translate}}</span><br>

                    <div formArrayName="videos" *ngFor="let t of videos.controls; let i = index" class="mt-2">
                        <div class="row" [formGroupName]="i">
                            <div class="col-md-2">
                                <p>{{'video no' | translate}} {{i+1}}</p>
                            </div>
                            <div class="col-md-6">
                                <input formControlName='url' id="{{'url'+i}}" type="text" class="form-control mb-4"
                                    placeholder="{{'url example' | translate}}" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-5">

                                <!-- <div class="alert  alert-danger"
                              *ngIf="tickets.controls[i].get('name').hasError('required') && tickets.controls[i].get('url').touched">
                              Name is required
                            </div> -->
                            </div>
                            <div class="col-md-5">
                                <!-- <div class="alert  alert-danger"
                              *ngIf="tickets.controls[i].get('age').hasError('required') && tickets.controls[i].get('age').touched">
                              Age is required
                            </div> -->
                            </div>
                        </div>
                    </div>

                    <button class="default-btn" (click)='addVideo()' type="button">{{'add video' | translate}}</button>
                    <hr>
                    <div *ngIf="errors">
                        <ng-container *ngFor="let error of errors">
                            <ul>
                                <li style="color: red;">{{error}}</li>
                            </ul>
                        </ng-container>
                    </div>
                    <div class="row text-right">
                        <button type="submit" [disabled]="isLoading" class="default-btn">{{'submit' |
                            translate}}</button>
                    </div>

                    <p class="text-center" *ngIf="isLoading">Loading, Please wait...</p>
                </div>
            </form>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>