import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  changeObjectToForm(form){
    const formData = new FormData();
    formData.append('id', form['id']);
    formData.append('reporttype', form['reporttype']);
    formData.append('reporttext', form['reporttext']);
    formData.append('from', form['from']);
    formData.append('to', form['to']);
    return formData;
  }

  addReport(formData){
    return this.http.post(environment.localUrl + 'reports/create', formData);
  }

  addListingReport(report){
    return this.http.post(environment.localUrl + 'proerty/reports/create', report);
  }
 

}
