import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { AuthService } from '@realstate/auth/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector:Injector, private router:Router) { }

  intercept(req, next){
    let authService = this.injector.get(AuthService);
      let tokenizedReq = req.clone({
        setHeaders: {
          Authorization:`Bearer ${authService.getToken()}` 
        }
      }) 
      return next.handle(tokenizedReq).pipe(
        tap( 
          (event: HttpEvent<any>) =>{
            if(event instanceof HttpResponse){
            }
        },

        (error: any) => {
          if(error instanceof HttpErrorResponse){
            if(error.status == 401){
              localStorage.removeItem('access_token');
              return this.router.navigate(['/login-register']);
            }
          }
        }
      ));
    
    
  }
}
