import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
  merge,
  filter
} from "rxjs/operators";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class LookupService {

  lookups: any;
  vars: any;
  lookupLoaded: boolean;
  private subject = new Subject<string>();
  constructor(
    private http: HttpClient
  ) {
    this.lookupLoaded = false;
  }

  getLookups() {
    var mainObservable = Observable.create(observer => {
      this.http
        .get(environment.localUrl +'lookups')
        .subscribe(response => {
          this.lookups= response;
          this.lookupLoaded = true;
          this.subject.next("lookup-loaded");
          observer.next(response);
          observer.complete();
        });
    });
    return mainObservable;
  }

  listen() {
    return this.subject.asObservable();
  }

  lookupUpdated(alias, data, action) {
    this.getLookups().subscribe();
  }


}
