import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { SocialLoginservice } from '@realstate/services/socialLogin.service';
import { UserService } from '@realstate/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-check-otp',
  templateUrl: './check-otp.component.html',
  styleUrls: ['./check-otp.component.scss']
})
export class CheckOtpComponent implements OnInit {
  otpCode
  userdata = JSON.parse(localStorage.getItem('user-data'))
  numberExist : boolean = false
  onsubmit : boolean = false

  constructor(private service : UserService, private spinner: NgxSpinnerService,
    private toastr: ToastrService ,private router: Router , private socialLogin : SocialLoginservice,
    private titleService: Title
    ) {
      this.titleService.setTitle("Check-OTP");

     }

  ngOnInit(): void {
    this.toastr.error('you have to verify your account to can create property')
  }
  onOtpChange(e) {
    console.log(e)
    this.otpCode = e
  }
  onSubmit(){
    this.onsubmit = true
    const OtpCode = {
      code : this.otpCode
    }
    
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
      console.log(this.spinner);
      
    this.service.checkOTP(OtpCode).subscribe(res => {
      console.log(res)
      this.spinner.hide()
      this.onsubmit = false
      this.toastr.success('your account is verified successfully')
      localStorage.setItem('is_verified', '1')
      this.router.navigate(['/'])

    },err => {
      this.spinner.hide()
      this.onsubmit = false
      this.toastr.error('Wrong OTP code')
    })
  }
  resend(){
    this.service.verify().subscribe(res => {
      console.log(res)
    })
  }
}
