import { Component, OnInit, Pipe } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { ActivatedRoute, Router } from "@angular/router";
import { PropertiesService } from '@realstate/services/properties.service';
import { environment } from 'environments/environment';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeResourceUrl, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { faRestroom, faVectorSquare, faBed, faShower } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '@realstate/services/reports.service.';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@realstate/services/user.service';
import { LanguageService } from '@realstate/services/lang.service';
import { AuthService } from '@realstate/auth/auth.service';
// import { FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
declare const google: any;


@Component({
  selector: 'app-listing-details',
  templateUrl: './listing-details.component.html',
  styleUrls: ['./listing-details.component.scss']
})

export class ListingDetailsComponent implements OnInit {

  environment = environment.imagesUrl;
  userImage: string = null;
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  thumbnail: string;
  property: any = null;
  current: any = null;
  form: FormGroup;
  formSubmitted = false;
  averageFloor: number = null;
  averageFraction: number = null;
  url: SafeResourceUrl = null;
  cover: string = null;
  textDir: string;
  faRestroom = faRestroom;
  faVectorSquare = faVectorSquare;
  faBed = faBed;
  faShower = faShower;
  icons = environment.imagesUrl + '/features-icons/';
  reportForm: FormGroup;
  currentUser: any;
  videos: any;
  map: any;
  isFav: boolean = false;
  id
  Url
  latitude: number = 30.0725208;
  longitude: number = 31.3449143;
  embedUrl: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertiesService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public translate: TranslateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private reportService: ReportsService,
    private toastr: ToastrService,
    public userService: UserService,
    private meta: Meta,
    private lang: LanguageService,
    private titleService: Title
    // private fb: FacebookService
  ) {
    
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    });
    this.createForm();
    // const fbParams: InitParams = {
    //   appId: '373827414866362',
    //   xfbml: true,
    //   version: 'v3.3'
    // };
    // this.fb.init(fbParams);

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params?.id
      this.getProperty(params['id']);
    });
    this.getCurrentUser();
    // const testurl = 'https://maps.google.com/maps?q=30.0725208,31.3449143&t=&z=15&ie=UTF8&iwloc=&output=embed';
    // this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(testurl);
    // this.mapFunction()
    // this.meta.addTags([
    //   { property: 'og:title', content: "beitlist description"},
    //   { property: 'og:image', content: this.thumbnail },
    //   { property: 'og:type', content: "article" },
    //   { property: 'og:description', content: "beitlist description" }
    // ]);
  }

  // shareFb() {
  //   const options: UIParams = {
  //     method: "share",
  //     href: 'https://stag.beitlist.com/listing-details/' + this.id,
  //     picture: this.thumbnail,
  //     phase: 'check my avatar',
  //     quote: 'check out my new 3D avatar on GBarena! create your own avatar now and join lots of tournaments',
  //      name: 'check my avatar',
  //     description: 'check my avatar',
  //     message: 'check out my new 3D avatar on GBarena! create your own avatar now and join lots of tournaments',
  //     caption: 'check out my new 3D avatar on GBarena! create your own avatar now and join lots of tournaments',
  //     hashtag: '#Beitlist'

  //   }
  //   this.fb.ui(options).then((res: UIResponse) => {
  //     console.log(res);
  //   })
  //     .catch(error => {
  //       console.log(error);
  //    });
  //   }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  currentLang() {
    this.translate.currentLang = localStorage.getItem('lang')
    if (this.translate.currentLang == 'ar')
      this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  setGalleryOptions() {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    this.galleryImages = this.setGallery();
  }


  setGallery() {
    const images = [];
    this.property?.property?.images.forEach(element => {
      images.push({
        small: this.environment + '/' + element.image,  // +'/properties-Images' +
        medium: this.environment + '/' + element.image,
        big: this.environment + '/' + element.image
      })
    });
    console.log(images);
    return images;

  }

  imageObject: Array<object> = [{
    video: 'https://youtu.be/6pxRHBw-k8M' // Youtube url
  },
  {
    video: 'https://youtu.be/6pxRHBw-k8M', // MP4 Video url
  },
  ];

  propertyVideos: any = [];
  getProperty(id) {
    this.propertyService.getProperty(id).subscribe(
      response => {
        this.property = response;
        this.titleService.setTitle(this.property?.property?.name + '| Beitlist');

        console.log(this.property);
        console.log(this.property?.property?.name);
        
        console.log(this.property?.property?.state);
        this.propertyVideos = this.property.property.videos;
        this.isFav = this.property.isFav;
        this.averageFloor = this.property.user.review - this.property.user.review % 1;
        this.Url = this.property.property.map + "";
        console.log(this.Url);
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.Url);
        console.log(this.url);

        this.thumbnail = environment.imagesUrl + '/' + this.property?.property?.thumbnail;
        this.averageFraction = this.property.user.average % 1;
        this.userImage = environment.imagesUrl + '/users-images/' + this.property.user.image;
        this.cover = environment.imagesUrl + '/' + this.property.property.cover;
        this.setGalleryOptions();
        console.log(this.thumbnail);
        this.mapFunction();
      }, error => {
        this.router.navigate(['error']);
      }
    )
  }

  createForm() {
    this.reportForm = this.formBuilder.group({
      property_id: ["", Validators.required],
      body: ["", Validators.required],
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.reportForm.controls;
  }
  mapFunction() {
    const mapLink = this.property.property.map;
    const coordinatesRegex = /(\d*\.*\d*),(\d*\.*\d*).*/;
    const coordinates = mapLink.match(coordinatesRegex);
    if (coordinates !== null) {
      this.latitude = parseFloat(coordinates[1]);
      this.longitude = parseFloat(coordinates[2]);
      console.log(this.latitude, this.longitude);
      const testurl = 'https://maps.google.com/maps?q=' + this.latitude + ',' + this.longitude + '&t=&z=15&ie=UTF8&iwloc=&output=embed';
      this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(testurl);
    } else {
      console.log('No coordinates found in the link');
    }

    // const mapUrl = 'https://goo.gl/maps/P6v5eF2Pk6wD8G988';
    // const mapId = mapUrl.substring(mapUrl.indexOf('@') + 1, mapUrl.indexOf('/data='));
    // const apiKey = 'AIzaSyBbMpO2BX-DJH2vM-SsylcMI5iivUmeFyk';

    // const script = document.createElement('script');
    // script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    // script.defer = true;
    // script.async = true;

    // script.onload = () => {
    //   this.map = new google.maps.Map(document.getElementById('map'), {
    //     center: { lat: 0, lng: 0 },
    //     zoom: 1
    //   });

    //   const mapUrlParams = mapUrl.substring(mapUrl.indexOf('?') + 1).split('&');
    //   const centerParam = mapUrlParams.find(param => param.startsWith('ll=')).substring(3).split(',');
    //   const lat = parseFloat(centerParam[0]);
    //   const lng = parseFloat(centerParam[1]);

    //   const marker = new google.maps.Marker({
    //     position: { lat, lng },
    //     map: this.map
    //   });

    //   this.map.setCenter({ lat, lng });
    // };

    // document.head.appendChild(script);
  }



  onSubmit() {
    this.formSubmitted = true;

    // this.getCurrentUser() ;

    this.reportForm.patchValue({
      property_id: this.property?.property.id,
    })
    this.submitReport();
  }

  submitReport() {
    console.log(this.reportForm.value);

    if (this.reportForm.invalid) {
      return;
    }
    this.reportService.addListingReport(this.reportForm.value).subscribe((res: any) => {
      console.log(res);
      if (res.body) {
        this.modalService.dismissAll();
        this.toastr.success('Report sent successfully', 'Success');
      }
    }, (error) => {
      this.toastr.error('Error occurred while Submitting the report', 'error');
    })

  }
  getCurrentUser() {
    if (!this.authService.isAuthenticated) {
      this.userService.getCurrent().subscribe(
        response => {
          this.currentUser = response.user;
          console.log(this.currentUser);

        }, error => {
          console.log(error);
        }
      )
    } else {
      return;
    }

  }

  toReviews() {
    document.getElementById("reviews").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"

    });
  }
  toGallery() {
    document.getElementById("gallery").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"

    });
  }
  toDetails() {
    document.getElementById("details").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"

    });
  }
  toMap() {
    document.getElementById("map").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }
  toAddReview() {
    document.getElementById("add-review").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }

  addToFav() {
    this.propertyService.addToFavorite(this.property.property.id).subscribe(
      response => {
        this.isFav = response.isFav;
        if (response.isFav) {
          this.toastr.success('Added to favorites', 'Success');
        } else {
          this.toastr.success('Removed from favorites', 'Success');
        }
      }, error => {
        console.log(error);
        this.toastr.error('Error occurred', 'Error');
      }
    )
  }

  isOwner() {
    if (this.currentUser?.id == this.property?.user?.id) {
      return true;
    }
    return false;
  }

  // getVideoUrl(videos) {
  //   videos.forEach(element => {
  //     var id = element.video.split('/')[element.video.split('/').length - 1];
  //     this.videos.push()
  //   }
  //   // var video = url.snapshot.params.v ;
  //   // return 'https://www.youtube.com/embed/' + video;
  // }
}
