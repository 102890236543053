<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg3" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'listing' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'listing' | translate}}</li>
            </ul> -->
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">{{'home' | translate}}</a></li>
                        <li>
                            <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                            <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                        </li>
                        <li class="active">{{'listing' | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-widget-section pt-100 pb-70" dir="{{textDir}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <app-sidebar [searchValue]="searchValue" (formEmitter)="fetchData($event)" (clearEmitter)="clear($event)"></app-sidebar>
            </div>
            <div class="col-lg-9">
                <div class="row w-100 ms-1 pb-3 mb-4" style="border-bottom: 1px solid #f19101;" >
                    <div class="col-11">
                        <label for="sort">{{'Sort By :' | translate}}</label>
                        <select class="form-select w-25 " id="sort" name="sort" (change)="sort($event.target.value)">
                            <option value="null" selected >{{'Newly Listed' | translate}}</option>
                            <option value="2">{{'Lowest Price' | translate}}</option>
                            <option value="3">{{'Highest Price' | translate}}</option>
                        </select>
                    </div>
                    <div class="col-1">
                        <img *ngIf="loadingIndictor" src="assets\img\loading.gif" alt="loadingIndictor" style="width: auto; height: auto;">
                    </div>
                </div>
                <div class="listing-widget-into">
                    <div class="row">
                        <p *ngIf="noProperties" class="text-center">{{'no properties' | translate}}</p>
                        <div *ngFor="let property of properties| paginate: { itemsPerPage: 6  , currentPage: page, totalItems: total }"
                            class="col-lg-4 col-md-4">
                            <app-carousel [property]="property"  [isAuthUser]="isAuthUser"></app-carousel>
                        </div>
                        <pagination-controls class="text-center" (pageChange)="changePage(page = $event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>