<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul> -->
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100" >
    <div class="container">
        <div class="single-content text-right">
            <h3>Welcome to Pilar Privacy & Policy</h3>
            <p [innerHtml]="setting['privacy_policy']"
             [innerHtml]="setting['privacy_policy_ar']">
             {{ setting['privacy_policy']</p>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>