import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { faRestroom, faVectorSquare, faBed  , faToilet , faSink , faShower} from '@fortawesome/free-solid-svg-icons';
import { PropertiesService } from '@realstate/services/properties.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@realstate/auth/auth.service';
import { photoService } from '@realstate/services/photos.service';
import { LanguageService } from '@realstate/services/lang.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit{
  @Input() property: any;
  @Input() isAuthUser: any;

  textDir: string;
  environment = environment.imagesUrl;
  icons = environment.imagesUrl + '/features-icons/';
  faRestroom = faRestroom;
  faVectorSquare = faVectorSquare;
  faBed = faBed;
  faToilet = faToilet ;
  faSink = faSink ;
  faShower = faShower ;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private propertyService: PropertiesService,
    private toastr: ToastrService,
    private auth:AuthService,
    private service : photoService,
    private lang : LanguageService
    ) {
      this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
  }
  ngOnInit() {
    // this.isAuth();
  }
  sendPhoto(x){
    this.service.selectPhoto(x);
  }

  currentLang(){
    this.lang.getlang().subscribe(res => {
      this.translate.currentLang = localStorage.getItem('lang')
  })
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }


  ngAfterViewInit(): void {
  }

  addToFav(id , property) {
    if(!this.isAuthUser){return}
    this.propertyService.addToFavorite(id).subscribe(
      response => {
        if (response.isFav) {
          property.isFav = true;
          this.toastr.success('Added to favorites', 'Success');
        } else {
          property.isFav = false;
          this.toastr.success('Removed from favorites', 'Success');
        }
        this.ngOnInit();
      }, error => {
      }
    )
  }

  isAuth(){
    if(this.auth.isAuthenticated()){
      this.isAuthUser = true ;
    }else{
      this.isAuthUser = false
    }
  }
}
