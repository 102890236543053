import { Location } from '@angular/common';
import { LocationsService } from './../../../services/locations.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PropertiesService } from '@realstate/services/properties.service';
import { PropertyTypesService } from '@realstate/services/property_types.service';
import { GovernmentsService } from '@realstate/services/governments.service';
import { LookupService } from '@realstate/services/lookup.service';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from '@realstate/auth/auth.service';
import { concat, fromEvent,empty, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter, retry } from 'rxjs/operators';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { SlidesService } from '@realstate/services/slides.service';
import { UserService } from '@realstate/services/user.service';
import { SwiperOptions } from 'swiper';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '@realstate/services/Notification.service';
import { LanguageService } from '@realstate/services/lang.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  properties: any = null;
  property_types: any = null;
  governments: any = [];
  locations: any = [];
  form: FormGroup;
  formSubmitted = false;
  searchMenuFlag = false;
  textDir: string = 'ltr';
  maxBedrooms: number;
  unitsArray = [];
  districts = [];
  slides = []
  environment = 'https://backend.beitlist.com/storage/';
  opened : boolean
  location:string
  keyword = 'name';

  // locations$: Observable<any>;
  locationsLoading = false;
  locationsInput$ = new Subject<string>();
  minLengthTerm = 2;
  test = ['اندرو', 'اسلام', 'عبده']
  selectedLocations = [];
  selectedCompounds = [];
  allLocations = [];

  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''
  isAuthUser: any;

  constructor(
    private propertiesService: PropertiesService,
    private propertyTypesService: PropertyTypesService,
    private governmentsService: GovernmentsService,
    private LocationsService: LocationsService,
    private formBuilder: FormBuilder,
    private router: Router,
    public translate: TranslateService,
    private auth: AuthService,
    private compoundService: LocationsService,
    private lookupService: LookupService,
    private Service :  SlidesService,
    public authService : AuthService,
    private userService : UserService,
    private toastr: ToastrService,
    private cDR: ChangeDetectorRef,
    private notification : NotificationService,
    private lang : LanguageService , 
    private titleService: Title


  ) {
    this.titleService.setTitle("Home");

    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    });

  }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.createForm();
    this.isAuth();
    this.getHomepage();
    this.getPropertyTypes();
    this.max();
    this.Notification();
    this.injectSearchSubject();

  }

  onOpenSearchMenuFlag(){
    this.searchMenuFlag = true;
  }
  onCloseSearchMenuFlag(){
    this.searchMenuFlag = false;
  }

  injectSearchSubject(){
    this.locationsInput$ = new Subject<string>();

    this.locationsInput$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(res => {
        if(res== null || res.length <=3){
          return false;
        }
        return true;
      }),
      tap(() => {this.locationsLoading=true}),
       switchMap( (res) =>{
         return this.LocationsService.getSearchLocations(res)
       }
       )
    ).subscribe(res => {
      this.locations = res?.results;
      if(res?.results){
         this.locationsLoading = false;
         this.opened = true
       }
    });
  }
  dropdownMode(){
     if(this.allLocations.length > 0){
      return true
     }
  }
  closeSelect(select: NgSelectComponent){
    debugger;
    select.close();
  }

  Notification(){
    this.notification.getNotification().subscribe(res => {
      if(res == 'login'){
        this.notification.selectNotification('')
        console.log(res);
      }
    })
  }

  currentLang() {
      this.translate.currentLang = localStorage.getItem('lang')
    if (this.translate.currentLang == 'ar')
      this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
  }

  createForm() {
    this.form = this.formBuilder.group({
      // government: [null, Validators.compose([])],
      description: ['', Validators.compose([])],
      propertyType: [null, Validators.compose([])],
      locations: [null, Validators.compose([])],
      compounds: [null, Validators.compose([])],
      bedrooms: [null, Validators.compose([])],
      units: [[], Validators.compose([])],
    });
  }

  getHomepage(){
    this.propertiesService.getHomepage().subscribe(
      response => {
        this.properties = response?.newestProperties;
        this.slides = response?.slideShows;
      });
  }

  getProperties() {
    this.propertiesService.getNewest(5).subscribe(
      response => {
        console.log(response);
        this.properties = response.record.slice(0,3);
        console.log(this.properties);

      });
  }

  customSearchFn(term: string, item) {
       return true
  }

  getPropertyTypes() {
    this.property_types = this.lookupService.lookups.propertyTypes;
  }

  max() {
    this.maxBedrooms = this.lookupService.lookups.maxBedrooms;
  }



  addTagFn = (item) => {
    // console.log(item);
  }

  onSubmit() {
    this.allLocations.forEach(el => {
      (el.type == 'comp') ? this.selectedCompounds.push(el.id) : this.selectedLocations.push(el.id)
    })

    if(this.selectedLocations.length>0)
    this.form.controls.locations.setValue(this.selectedLocations)
    if(this.selectedCompounds.length>0)
    this.form.controls.compounds.setValue(this.selectedCompounds)


    this.formSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    this.saveData();
  }

  attachUnit(id, event) {
    if (event == true)
      this.unitsArray.push(id);
    else {
      this.unitsArray.forEach((element, index) => {
        if (element == id) this.unitsArray.splice(index, 1);
      });
    }
    this.form.controls['units'].setValue(this.unitsArray);
  }

  saveData() {
    this.router.navigate(['/listing'],
      {
        queryParams: {
          form: 1,
          description: this.form.controls['description'].value,
          // government: this.form.controls['government'].value,
          locations: this.form.controls['locations'].value,
          compounds: this.form.controls['compounds'].value,
          propertytype: this.form.controls['propertyType'].value,
          bedrooms: this.form.controls['bedrooms'].value,
          units: this.form.controls['units'].value,
        }
      }).then(() => {
        // window.location.reload();
      });
  }

  isAuth() {
    if (this.auth.isAuthenticated()) {
      this.isAuthUser = true;
    } else {
      this.isAuthUser = false
    }
  }

  selectGovernerate(item) {
    // this.getLocation(item.id);
    this.form.controls['government'].setValue(item.id);
    console.log(item);
  }

  selectlocation(item) {
    this.form.controls['locations'].setValue(item.id);
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

}
