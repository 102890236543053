import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from '@realstate/services/user.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-change-password-request',
  templateUrl: './change-password-request.component.html',
  styleUrls: ['./change-password-request.component.scss']
})

export class ChangePasswordRequestComponent implements OnInit {
  resetForm: FormGroup;
  errors = null;
  successMsg = null;

  constructor(
    public fb: FormBuilder,
    public userService: UserService,
    private spinner: NgxSpinnerService, 
  ) {
    this.resetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit(): void { }

  onSubmit(){
    this.spinner.show(undefined,
      {   
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.userService.sendResetPasswordLink(this.resetForm.value).subscribe(
      (result) => {
        this.successMsg = result;
        this.errors = null;
        this.spinner.hide();
      },(error) => {
        this.errors = error.error.message;
        this.successMsg = null;
        this.spinner.hide();
      })
  }

}