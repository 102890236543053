import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {


  constructor(private http: HttpClient) { }

  getSetting(){
    return this.http.get<any>(environment.localUrl +'settings/view');
  }

}
