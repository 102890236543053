<app-navbar-style-one (currentUserEmitter)="fetchData($event)"></app-navbar-style-one>

<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'change password' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'change password' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="container">
    <div class="listing-widget listing-comment mt-3" id="reviews" dir="{{textDir}}"> 
        <h3 class="title">{{'change password' | translate}}</h3>
        <form class="pe-5 px-5" [formGroup]="form" (ngSubmit)=onSubmit()>
            <div class="row">
                <div class="form-group">
                    <label class="mb-1 mt-2">{{'current password' | translate}}</label>
                    <input class="form-control" type="password" formControlName="currentPassword" placeholder="{{'current password' | translate}}" required [ngClass]="{
                        'is-invalid': formSubmitted && f.currentPassword.invalid,
                        'is-valid': formSubmitted && !f.currentPassword.invalid
                        }">
                        <span class="help-block" *ngIf="
                        formSubmitted &&
                        (f.currentPassword.errors?.required)
                        ">
                            <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                        </span>
                </div>
                <div class="form-group">
                    <label class="mb-1 mt-2">{{'password' | translate}}</label>
                    <input class="form-control" type="password" formControlName="password" placeholder="{{'password' | translate}}" required [ngClass]="{
                        'is-invalid': formSubmitted && f.password.invalid,
                        'is-valid': formSubmitted && !f.password.invalid
                        }">
                        <span class="help-block" *ngIf="
                        formSubmitted &&
                        (f.password.errors?.required)
                        ">
                            <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                        </span>
                </div>
                <div class="form-group mt-3">
                    <label class="mb-1 mt-2">{{'confirm password' | translate}}</label>
                    <input class="form-control" type="password" formControlName="confirmPassword" placeholder="{{'confirm password' | translate}}" required [ngClass]="{
                        'is-invalid': formSubmitted && (f.confirmPassword.invalid || form.hasError('notSame')),
                        'is-valid': formSubmitted && !f.confirmPassword.invalid
                        }">
                        <span class="help-block" *ngIf="
                        formSubmitted &&
                        (f.confirmPassword.errors?.required)
                        ">
                            <small style="color: red;" class="help-block">{{'required' | translate}}</small>
                    </span>
                        <span class="help-block" *ngIf="
                        formSubmitted &&
                        (form.hasError('notSame'))
                        ">
                            <small style="color: red;" class="help-block">{{'not match' | translate}}</small>
                        </span>
                </div>
            </div>
            <div class="text-center mt-3">
                <ngb-alert *ngIf="error" type="danger" [dismissible]="false">          
                    {{error}}
                    <button type="button" class="close" aria-label="Close" (click)="closeAlert()"
                    >
                    <span aria-hidden="true">&times;</span> 
                    </button>
                </ngb-alert>
            </div>
            <div class="col-lg-12 col-md-12 text-center mt-5">
                <button type="submit" class="default-btn" style="padding: 12px 27px; border-radius: 0px; font-size: 15px;">{{'done' | translate}} </button>
            </div>
        </form>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
