import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { UserService } from '@realstate/services/user.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LanguageService } from '@realstate/services/lang.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  textDir: string;
  form: FormGroup;
  formSubmitted = false;
  record: any = null;
  displayWhatsapp = false;
  validContact = false;
  currentUser: any = null;
  imagePath: any;
  imageUrl: any;
  userImage: string = null;
  userCover: string = null;
  coverUrl: any;
  jobRoleUser
  environment = environment.imagesUrl;

  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private lang : LanguageService ,
    private spinner: NgxSpinnerService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Edit-Profile')
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });

    this.form = this.formBuilder.group({
      id: ["", Validators.compose([])],
      fullname: ["", Validators.compose([Validators.required])],
      address: ["", Validators.compose([])],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      phonenumber: ["", Validators.compose([Validators.minLength(11), Validators.maxLength(11)])],
      description: ["", Validators.compose([Validators.required])],
      website: ["", Validators.compose([])],
      thumbnail: '',
      cover: '',
      facebook: ["", Validators.compose([])],
      twitter: ["", Validators.compose([])],
      instagram: ["", Validators.compose([])],
      pinterest: ["", Validators.compose([])],
      youtube: ["", Validators.compose([])],
      email_contact: [0, Validators.compose([])],
      phone_contact: [0, Validators.compose([])],
      jobRole: ["", Validators.compose([])],
      subscribtion_id: [null, Validators.compose([])],
      user_type: '',
      whatsapp_no: '',
      whatsapp_contact: [0, Validators.compose([])],
    });
   }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.getCurrentUser();
  }

  populateForm() {
    this.form.patchValue({
      id:this.currentUser.id,
      fullname: this.currentUser.fullname,
      email: this.currentUser.email,
      description: this.currentUser.description,
      website: this.currentUser.website,
      address: this.currentUser.address,
      facebook: this.currentUser.facebook,
      twitter: this.currentUser.twitter,
      pinterest: this.currentUser.pinterest,
      youtube: this.currentUser.youtube,
      instagram: this.currentUser.instagram,
      phonenumber: this.currentUser.phonenumber,
      whatsapp_no: this.currentUser.whatsapp_no,
      email_contact: this.currentUser.email_contact,
      phone_contact: this.currentUser.phone_contact,
      whatsapp_contact: this.currentUser.whatsapp_contact,
      user_type: this.currentUser.usertype,
      subscribtion_id: this.currentUser.subscribtion_id
    });
  }

  getCurrentUser(){
    this.userService.getCurrentAuth().subscribe(
      (response) =>{
        this.currentUser = response.user;
        this.jobRoleUser = response.user.usertype
        console.log(this.jobRoleUser);
        console.log(this.currentUser);
        this.userImage = this.environment + '/users-images/' + this.currentUser?.image;
        this.userCover = this.environment + '/users-images/' + this.currentUser?.cover;
        this.populateForm();
      }
    )
  }

  onSubmit() {
    this.formSubmitted = true;
    console.log(this.form.valid);
    if (!this.form.valid) {
      return;
    }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      }); 
    var formData = this.userService.editObjectToForm(this.form.value);
    this.saveData(formData);
  }

  saveData(formData) {
      this.userService.updateUser(formData).subscribe(
      (response: any) => {
        this.record = response.record;
        console.log(response);
        this.spinner.hide()
        localStorage.setItem('user-data' ,JSON.stringify(response.record.original[0]))
        this.toastr.success('Profile Updated successfully', 'Success');
        this.router.navigate(['/']);
    },(error) => {
      this.toastr.error('Error Updating Profile', 'Error');
      this.spinner.hide()

    });
  }

  get f() {
    return this.form.controls;
  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  isDifferent(value){
    if(value == 1){
      this.displayWhatsapp = true;
      this.form.controls["whatsapp_no"].setValidators([Validators.required]);
    }else{
      this.displayWhatsapp = false;
      this.form.controls["whatsapp_no"].setValidators([]);
      this.form.controls["whatsapp_no"].setValue(null);
    }
  }

  onThumbnailChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      this.imagePath = file;
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
          this.imageUrl = reader.result;
      }
      this.form.get('thumbnail').setValue(file);
    }
  }

  onCoverChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      this.imagePath = file;
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
          this.coverUrl = reader.result;
      }
      this.form.get('cover').setValue(file);
    }
  }
}
