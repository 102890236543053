<footer class="footer-area footer-bg" dir={{textDir}}>
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <a routerLink="/" class="logo footerLogo"><img src="assets/img/logo/beitlist_Logo.png" alt="Logo"></a>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <!-- <h3>{{'contact info' | translate | uppercase}}</h3> -->
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                </div>
                <!-- <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <h3>{{'latest listings' | translate | uppercase}}</h3>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <p>{{textDir == 'ltr' ? setting['address'] : setting['address_ar']}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget pl-5">
                        <ul class="footer-contact-list">
                            <li><span>{{'Monday-Friday' | translate}} :</span> {{'9amTo6pm' | translate}}</li>
                            <li><span>{{'Saturday-Sunday' | translate}} :</span> {{'9amTo2pm' | translate}}</li>
                            <li><span>{{'phone' | translate}} :</span> <a href="tel:01201271000">{{setting['phone']}}</a>
                            </li>
                            <li><span>{{'email' | translate}} :</span> <a
                                    href="mailto:info@beitlist.com">{{setting['email']}}</a></li>
                        </ul>
                        <!-- <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul> -->
                    </div>
                </div>

                <!-- <div class="col-lg-4 col-md-6 offset-md-2 offset-lg-0">
                    <div class="footer-widget pl-5">
                        <ul class="footer-news">
                            <li *ngFor="let property of properties" style="width: 100%">
                                <div class="img">
                                    <a routerLink="/listing-details/{{property.id}}"><img style="width: 100px" src="{{environment}}/properties-thumbnails/{{property.thumbnail}}" alt="Images"></a>
                                </div>
                                <div class="content" style="margin-right: 3%">
                                    <h4>{{property.yearbuild}}</h4>
                                    <span><a routerLink="/listing-details/{{property.id}}">{{textDir == 'rtl' ? property.name_ar : property.name}}</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</footer>

<div class="copy-right-area" dir={{textDir}}>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-8">
                <div class="copy-right-text">
                    <p>{{'Copyright' | translate}}{{textDir == 'ltr' ? year : ''}}{{'All rights' | translate}} <a
                            href="https://graspstudios.com/" target="_blank">Graspstudios</a> </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition" target="_blank">{{'Terms Of Use' | translate}}</a></li>
                        <li><a routerLink="/privacy-policy" target="_blank">{{'Privacy Policy'| translate}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>