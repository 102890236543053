import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyTypesService {


  constructor(private http: HttpClient) { }



  getPropertyType(id){
    return this.http.get<any>(environment.localUrl + 'property-types/show?id=' + id, { responseType: "json" });
  }

  getPropertyTypes(){
    return this.http.get<any>(environment.localUrl +'property-types/list?offset=0&limit=30');
  }

}
