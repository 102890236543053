import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { ContactService } from '@realstate/services/contact.service';
import { LanguageService } from '@realstate/services/lang.service';
import { LookupService } from '@realstate/services/lookup.service';
import { SettingService } from '@realstate/services/setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form : FormGroup
  textDir: string;
  setting = {}

  constructor(
    public translate: TranslateService , private service : SettingService,
    private formBuilder: FormBuilder, private contactService : ContactService,
    private toastr: ToastrService, private lang : LanguageService,
    private lookupService : LookupService ,  private titleService: Title
 


  ) {
    this.titleService.setTitle("Contact");

    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0,0)
    this.getSetting();
    this.createform();
  }
  createform(){
    this.form = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required , Validators.maxLength(40)])],
      email: ["", Validators.compose([Validators.required, Validators.maxLength(40)])],
      phone: ["", Validators.compose([Validators.required])],
      message: ["", Validators.compose([Validators.required])],
    })
  }
  onSubmit(){
    if(!this.form.valid){
      this.toastr.error('Please fill in the mandatory fields ')
    }else{
      this.contactService.getSetting(this.form.value).subscribe(res => {
        console.log(res)
        this.toastr.success('Your request has been done successfully')
      } , err => {
        this.toastr.error(err.message)
      })
    }
  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }
  getSetting(){
    this.setting = this.lookupService.lookups?.settings
  }


}
