import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }

  changeObjectToForm(form, isSocial){    
    const formData = new FormData();
    formData.append('id', form.id);
    formData.append('fullname', form.fullname ? form.fullname : form.name);
    formData.append('email', form.email);
    formData.append('description', '');
    formData.append('website', '');
    formData.append('address', '');
    formData.append('facebook', '');
    formData.append('twitter', '');
    formData.append('pinterest', '');
    formData.append('youtube', '');
    formData.append('instagram', '');
    formData.append('image', '');
    formData.append('cover', '');
    formData.append('phonenumber', form?.phonenumber?.number ? form.phonenumber.number : '');
    formData.append('whatsapp_no', form.phonenumber?.number ? form.phonenumber.number : '');
    formData.append('usertype', form.jobRole ? form.jobRole : '');
    formData.append('password', form.password ? form.password : '');
    formData.append('whatsapp_contact', '1');
    formData.append('email_contact', '1');
    formData.append('phone_contact', '1');
    formData.append('subscribtion_id', '1');
    formData.append('social', isSocial);
    console.log(formData);
    return formData;
  }

  editObjectToForm(form){
    const formData = new FormData();
    formData.append('id', form.id);
    formData.append('fullname', form.fullname);
    formData.append('email', form.email);
    formData.append('phonenumber', form.phonenumber);
    formData.append('image', form.thumbnail);
    formData.append('cover', form.cover);
    formData.append('description', form.description);
    formData.append('website', form.website);
    formData.append('address', form.address);
    formData.append('facebook', form.facebook != null ? form.facebook : '');
    formData.append('twitter', form.twitter != null ? form.twitter : '');
    formData.append('instagram', form.instagram != null ? form.instagram : '');
    formData.append('pinterest', form.pinterest != null ? form.pinterest : '');
    formData.append('youtube', form.youtube != null ? form.youtube : '');
    formData.append('whatsapp_no', form.whatsapp_no);
    formData.append('usertype', form.user_type);
    formData.append('password', form.password);
    formData.append('whatsapp_contact', form.whatsapp_contact == true ? '1':'0');
    formData.append('email_contact', form.email_contact == true ? '1':'0');
    formData.append('phone_contact', form.phone_contact == true ? '1':'0');
    formData.append('subscribtion_id', form.subscribtion_id);
    formData.append('social', '0');
    return formData;
  }

  changeLoginObjectToForm(form){
    const formData = new FormData();
    formData.append('email', form.email);
    formData.append('password', form.password);
    return formData;
  }

  changePasswordObjectToForm(form){
    const formData = new FormData();
    formData.append('id', form.id);
    formData.append('currentPassword', form.currentPassword);
    formData.append('password', form.password);
    return formData;
  }

  changeSubObjectToForm(data){
    const formData = new FormData();
    formData.append('user_id', data.user_id);
    formData.append('subscribtion_id', data.subscribtion_id);
    return formData;
  }

  login(loginForm){
    return this.http.post(environment.localUrl + 'login', loginForm, {responseType:'json'});
  }

  addUser(formData){
    return this.http.post(environment.localUrl + 'register', formData);
  }
  checkOTP(formData){
    let params = new HttpParams()
    params.set('code' , formData.code)
    return this.http.post(environment.localUrl + 'users/check-otp', formData);
  }
  verify(){
    return this.http.get(environment.localUrl + 'users/verify');
  }
  getUsers(){
      return this.http.get<any>(environment.localUrl + 'users/list?offset=0&limit=10');
  }

  getUser(id){
    return this.http.get<any>(environment.localUrl + 'users/show/' + id + '/customer');
  }

  updateUser(formData){
    return this.http.post(environment.localUrl+ 'users/update-user', formData, {responseType:'json'})
  }

  updateUserType(data){
    return this.http.put(environment.localUrl+ 'users/change-type', data, {responseType:'json'})
  }


  changePassword(formData){
    return this.http.post(environment.localUrl+ 'users/change-password', formData, {responseType:'json'})
  }

  updateSubscribtion(formData){
    return this.http.post(environment.localUrl + 'users/update/subscribtion', formData);
  }

  delete(formData){
    return this.http.post(environment.localUrl + 'users/delete', formData);
  }

  getCurrent(){
    return this.http.get<any>(environment.localUrl + 'users/current');
  }

  getCurrentAuth(){
    return this.http.get<any>(environment.localUrl + 'users/current/auth');
  }

  getUserReview(id){
    return this.http.get<any>(environment.localUrl + 'users/reviews/' + id + '/customer');
  }

  getUserReviewAuth(id){
    return this.http.get<any>(environment.localUrl + 'users/reviews/' + id);
  }

  getUserProperties(id){
    return this.http.get<any>(environment.localUrl + 'users/properties/' + id + '/customer');
  }

  getUserPropertiesAuth(id){
    return this.http.get<any>(environment.localUrl + 'users/properties/' + id);
  }


  getNewest(){
    return this.http.get<any>(environment.localUrl +'users/newest');
  }

  sendResetPasswordLink(data) {
    return this.http.post(environment.localUrl + 'reset-password-request', data)
  }

  resetPassword(data) {
    return this.http.post(environment.localUrl + 'change-password', data)
  }

  getFavoriteProperties() {
    return this.http.get<any>(environment.localUrl + 'properties/favorites');
  }

}
