import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from '@realstate/services/lang.service';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(translate: TranslateService , private lang : LanguageService) {
    translate.addLangs(['en', 'ar']);
    // translate.setDefaultLang('en');
    if (localStorage.getItem('lang')){
      translate.setDefaultLang(localStorage.getItem('lang'))
    }else{
      translate.setDefaultLang('en')
    }
    // translate.setDefaultLang((localStorage.getItem('lang') == '') ?  'en' : localStorage.getItem('lang') );
    // this.lang.selectlang(localStorage.getItem('lang'))
    // translate.currentLang = localStorage.getItem('lang');
}
}


export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
