<app-navbar-style-one></app-navbar-style-one>
<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'login' | translate}}/{{'register' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'login' | translate}}-{{'register' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="user-area" dir="{{textDir}}">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6  p-0">
                <div class="user-img">
                    <img src="assets/img/ed-pylypenko-7zcbtbI4E2o-unsplash-min.jpg" alt="Images">
                </div>
            </div>
            <div class="col-lg-5 col-xl-6">
                <ngb-alert class="invalid" *ngIf="" type="danger" [dismissible]="false">
                    You have to set Mobile number to can verify your account to receiver your OTP code 
                    , you can set mobile number through <a routerLink="/edit-profile" class="fw-bold">here</a>
                    <button type="button" class="close" aria-label="Close" (click)="isLoginFailed = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ngb-alert>
                <div class="user-section text-center">
                    <div class="user-content">
                        <h2>{{"welcome" | translate }} <b>{{'to beitlist' | translate}}</b></h2>
                    </div>
                    <div class="tab user-tab">
                        <ul class="tabs">
                            <li (click)="loginbtn()" [ngClass]="!formsboolean? 'current' : ''" style="cursor: pointer;">
                                <a> <i class="flaticon-contact"></i> {{'login' | translate}}</a></li>
                            <li  (click)="registerbtn()" [ngClass]="formsboolean? 'current' : ''" style="cursor: pointer;">
                                <a> <i class="flaticon-verify"></i> {{'register' | translate}}</a></li>
                        </ul>
                        <ngb-alert class="invalid" *ngIf="isLoginFailed" type="danger" [dismissible]="false">
                            {{'invalid' | translate}}
                            <button type="button" class="close" aria-label="Close" (click)="isLoginFailed = false">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </ngb-alert>
                        <div class="tab_content current active">
                            <div class="tabs_item current"  [ngStyle]="{'display':  !formsboolean ? 'block' : 'none'}">
                                <div class="user-all-form">
                                    <div class="contact-form">

                                        <form [formGroup]="loginForm" (ngSubmit)=login()>
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="email" id="name"
                                                            class="form-control" required
                                                            data-error="Please enter your Email" required
                                                            placeholder='{{"mail" | translate}}'
                                                            [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.email.invalid, 'is-valid': loginFormSubmitted && !lf.email.invalid }">
                                                        <span class="help-block" *ngIf="
                                                        loginFormSubmitted &&
                                                        (lf.email.invalid|| lf.email.errors?.required)
                                                        ">
                                                            <small style="color: red;" class="help-block">{{'required' |
                                                                translate}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" type="password"
                                                            formControlName="password"
                                                            placeholder="{{'password' | translate}}"
                                                            [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }">
                                                        <span class="help-block" *ngIf="
                                                        loginFormSubmitted &&
                                                        (lf.password.invalid|| lf.password.errors?.required)
                                                        ">
                                                            <small style="color: red;" class="help-block">{{'required' |
                                                                translate}}</small>
                                                        </span>
                                                        <span class="help-block" *ngIf="
                                                        loginFormSubmitted && isLoginFailed
                                                        ">
                                                            <small style="color: red;" class="help-block">{{errormessage}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit" class="default-btn  user-all-btn">{{'login' |
                                                        translate}}</button>
                                                </div>
                                                <hr>
                                                <ngx-spinner></ngx-spinner>
                                                <div class="col-lg-6 col-sm-6 form-condition">
                                                    <div class="agree-label">
                                                        <input type="checkbox" id="chb1">
                                                        <label for="chb1">{{'remember me' | translate}}</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <a class="forget" routerLink="/reset-password">{{'forget' |
                                                        translate}}</a>
                                                </div>
                                            </div>
                                        </form>

                                        <div class="social-option">
                                            <h3>{{'login with' | translate}}</h3>
                                            <ul>
                                                <li><a style="cursor: pointer;" (click)="loginWithFacebook()">{{'facebook' | translate}}</a></li>
                                                <!-- <li><a (click)="loginWithGoogle()">{{'google' | translate}}</a></li> -->
                                            </ul>
                                        </div>

                                        <!-- Button trigger modal -->
                                        <button type="button" id="typeBtn" #typeBtn class="btn btn-primary"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Type Modal
                                        </button>

                                        <!-- Modal -->
                                        <div class="modal fade " data-bs-backdrop="static" data-bs-keyboard="false"  id="exampleModal" tabindex="-1"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered ">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">{{'Job Role'|translate}}</h5>
                                                        <button type="button" class="btn-close hideMe"  data-bs-dismiss="modal" #closeModal
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <select class="custom-select form-control" [(ngModel)]="socialType" id="typeSelect">
                                                            <!-- <option value='' selected disabled>{{'Job Role' |translate}}</option> -->
                                                            <option value="1" >{{'Owner' | translate}}</option>
                                                            <option value="2">{{'Freelance Broker' | translate}}</option>
                                                            <option value="4">{{'customer' | translate}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" (click)="submitType()" (click)="closeModal.click()" class="default-btn  user-all-btn">{{'register'|translate}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item"  [ngStyle]="{'display': formsboolean ? 'block' : 'none'}">
                                <div class="user-all-form">
                                    <div class="contact-form">
                                        <form [formGroup]="registerForm" (ngSubmit)="register()" id="contactForm">
                                            <div class="row">
                                                <div class="col-lg-12 ">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="fullname" id="fullname"
                                                            class="form-control" required
                                                            data-error="Please enter your Fullname"
                                                            placeholder="{{'fullname' | translate}}" [ngClass]="{
                                                            'is-invalid': registerFormSubmitted && f.fullname.invalid,
                                                            'is-valid': registerFormSubmitted && !f.fullname.invalid
                                                            }">
                                                        <span class="help-block" *ngIf="
                                                            registerFormSubmitted &&
                                                            (f.fullname.invalid|| f.fullname.errors?.required)
                                                            ">
                                                            <small style="color: red;" class="help-block">{{'required' |
                                                                translate}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='flaticon-email-2'></i>
                                                        <input type="email" formControlName="email" id="email"
                                                            class="form-control" required
                                                            data-error="Please enter email"
                                                            placeholder="{{'mail' | translate}}" [ngClass]="{
                                                            'is-invalid': registerFormSubmitted && f.email.invalid,
                                                            'is-valid': registerFormSubmitted && !f.email.invalid}">
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted && f.email.errors?.required">
                                                            <small style="color: red;" class="help-block">{{'required'
                                                                |translate}}</small>
                                                        </span>
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted && f.email.errors?.email">
                                                            <small style="color: red;" class="help-block">{{'mail
                                                                format' | translate}}</small>
                                                        </span>
                                                        <span class="help-block" *ngIf="!validateEmail()">
                                                            <small style="color: red;"
                                                                class="help-block">{{'companyEmailError'
                                                                |translate}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 ">
                                                        <div class="form-group d-flex align-items-center" >
                                                        <ngx-intl-tel-input style="width: 100%;"

                                                        [onlyCountries]="['eg']"
                                                        [maxLength]="15"
                                                        [phoneValidation]="true"
                                                        name="phone"
                                                        formControlName="phonenumber"
                                                        [ngClass]="{'is-invalid': registerFormSubmitted && f.phonenumber.invalid,'is-valid': registerFormSubmitted && !f.phonenumber.invalid}">
                                                        >
                                                    </ngx-intl-tel-input>
                                                </div>
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted &&(f.phonenumber.errors?.required || f.phonenumber.invalid || f.phonenumber.errors?.minlength || f.phonenumber.errors?.maxlength)">
                                                            <small style="color: red;"
                                                                class="help-block">{{'phoneformat' |translate}}.</small>
                                                        </span>
                                                </div>
                                                <div class="col-lg-12 ">
                                                    <div class="form-group"><i class='bx bx-briefcase-alt'></i>

                                                        <select class="custom-select form-control"
                                                            (change)="validateEmail()" id="inputGroupSelect01"
                                                            formControlName="jobRole" required
                                                            [ngClass]="{'is-invalid': registerFormSubmitted && f.jobRole.invalid,'is-valid': registerFormSubmitted && !f.jobRole.invalid}">
                                                            <option value='' selected disabled>{{'Job Role' |
                                                                translate}}</option>
                                                            <option value="6">{{'Owner' | translate}}</option>
                                                            <option value="4">{{'Freelance Broker' | translate}}
                                                            </option>
                                                            <option value="5">{{'Brokerage Company' | translate}}
                                                            </option>
                                                            <option value="1">{{'customer' | translate}}</option>
                                                        </select>
                                                        <!-- <input type="text" formControlName="jobRole" class="form-control" required
                                                            data-error="Please enter your jobRole"
                                                            placeholder="{{'Job Role' | translate}}" [ngClass]="{
                                                            'is-invalid': registerFormSubmitted && f.jobRole.invalid,
                                                            'is-valid': registerFormSubmitted && !f.jobRole.invalid}"> -->
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted &&
                                                            (f.jobRole.errors?.required)">
                                                            <small style="color: red;" class="help-block">{{'jobRole
                                                                format' | translate}}.</small>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-12" *ngIf="!socialbool">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" type="password"
                                                            formControlName="password"
                                                            placeholder="{{'password' | translate}}" required
                                                            [ngClass]="{'is-invalid': registerFormSubmitted && f.password.invalid,'is-valid': registerFormSubmitted && !f.password.invalid}">
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted && (f.password.errors?.required)">
                                                            <small style="color: red;" class="help-block">{{'required' |
                                                                translate}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="!socialbool">
                                                    <div class="form-group">
                                                        <i class='bx bx-lock-alt'></i>
                                                        <input class="form-control" type="password" 
                                                            formControlName="confirmPassword"
                                                            placeholder="{{'confirm password' | translate}}" required
                                                            [ngClass]="{'is-invalid': registerFormSubmitted && (f.confirmPassword.invalid || registerForm.hasError('notSame')),
                                                            'is-valid': registerFormSubmitted && !f.confirmPassword.invalid}">
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted && (f.confirmPassword.errors?.required)">
                                                            <small style="color: red;" class="help-block">{{'required' |
                                                                translate}}</small>
                                                        </span><br>
                                                        <span class="help-block"
                                                            *ngIf="registerFormSubmitted && (registerForm.hasError('notSame'))">
                                                            <small style="color: red;" class="help-block">{{'not match'|
                                                                translate}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex" style="flex-direction: column;">
                                                    <div class="d-flex" style="column-gap: 10px;">
                                                        <input style="margin-bottom: 3px;" id="terms" type="checkbox" formControlName="terms">
                                                        <label for="terms" class="fw-bold">
                                                        {{'agree_to' | translate}} <a target="_blank" routerLink="/terms-condition" style="color: #f19101;">{{'terms&condition' | translate}}</a> 
                                                        </label>
                                                    </div>
                                                    <span class="help-block" style="text-align: start;" *ngIf="
                                                    registerFormSubmitted &&
                                                    (f.terms.invalid|| f.terms.errors?.required)
                                                    ">
                                                    <small style="color: red;" class="help-block">{{'required' |
                                                        translate}}</small>
                                                </span>
                                                </div>
                                                <small style="color: red; " class="help-block text-left" *ngIf="error">{{error}}</small>
         
                                                <div class="col-lg-12 col-md-12 text-center mt-3">
                                                    <button type="submit" class="default-btn  user-all-btn">{{'register'
                                                        | translate}} </button>
                                                </div>

                                            </div>
                                            <ngx-spinner></ngx-spinner>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
