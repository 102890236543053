<app-navbar-style-one (currentUserEmitter)="fetchData($event)"></app-navbar-style-one>

<div class="inner-banner inner-bg6" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'my listings' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'my listings' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>
<div class="listing-widget-section pt-100 pb-70" dir="{{textDir}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="listing-widget-into">
                    <div class="row">
                        <p *ngIf="properties?.length == 0" class="text-center">{{'no properties' | translate}}</p>
                        <div *ngFor="let property of properties| paginate: { itemsPerPage: 9  , currentPage: page}" class="col-lg-4 col-md-4">
                            <app-carousel [property]="property" [isAuthUser]="isAuthUser"></app-carousel>
                        </div>
                        <pagination-controls class="text-center" (pageChange)="page = $event" *ngIf="properties?.length != 0"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
