<!-- <div class="col-4"> -->
<div class="place-list-item">
    <div class="images">
        <a class="images-list">
            <div class="col-2 d-flex justify-content-end" *ngIf="isAuthUser">
                <i class='bx bxs-heart  favIcon' style="z-index: 95;" *ngIf="property.isFav"
                    (click)="addToFav(property.id , property)"></i>
                <i class='bx bx-heart favIcon' style="z-index: 95;" *ngIf="!property.isFav"
                    (click)="addToFav(property.id , property)"></i>
            </div>

            <img routerLink="/listing-details/{{property.id}}/{{property.slug}}" class="listing-thumb pointer"
                src="{{environment}}/{{property.thumbnail}}" alt="{{property.name}}" (click)="sendPhoto(property.thumbnail)">
        </a>
        <div class="place-tag">
            <ul>
                <li>
                    <a routerLink="/listing-details/{{property.id}}/{{property.slug}}">
                        <fa-icon [icon]="faVectorSquare"></fa-icon>
                    </a>
                    <p class="text-color-white">{{property?.size}}<span>&#13217;</span></p>
                </li>
                <li>
                    <a routerLink="/listing-details/{{property.id}}/{{property.slug}}">
                        <fa-icon [icon]="faBed"></fa-icon>
                    </a>
                    <p class="text-color-white">{{property?.bedrooms}}</p>
                </li>
                <li>
                    <a routerLink="/listing-details/{{property.id}}/{{property.slug}}">
                        <fa-icon [icon]="faShower"></fa-icon>
                    </a>
                    <p class="text-color-white">{{property?.bathrooms}}</p>
                </li>
            </ul>
        </div>
    </div>

    <div class="content" dir="{{textDir}}">
        <a routerLink="/listing-details/{{property.id}}/{{property.slug}}">
            <h3 class="listing-title title">{{textDir == 'rtl' ? property.name_ar: property.name}}</h3>
        </a>
        <p>{{textDir == 'rtl' ? property?.property_type?.name_ar : property?.property_type?.name}}</p>
        <span><i class="main-color flaticon-cursor mx-2"></i>{{textDir == 'rtl' ? property?.government?.name_ar :
            property?.government?.name}} {{textDir == 'rtl' ? property?.location?.name_ar :
            property?.location?.name}}</span>
        <div class="row">
            <div class="col-md-12 price">
                <!--text-right-->
                <i class='bx bxs-wallet mx-2'></i>
                <span>{{property?.price | number}} {{'egp' | translate}}</span>
            </div>
        </div>
        <hr>
        <div class="row mt-2">
            <div class="" style="text-align: center;">
                <span>{{'by'| translate}} {{property.user?.fullname}} </span><br>
                <span [ngSwitch]="property.user?.usertype">
                    <span class="job" *ngSwitchCase="1">{{'Owner' | translate}}</span>
                    <span class="job" *ngSwitchCase="2">{{'Freelance Broker' | translate}}</span>
                    <span class="job" *ngSwitchCase="3">{{'Brokerage Company' | translate}}</span>
                    <!-- <span class="job" *ngSwitchCase="4">{{'broker' | translate}}</span>
                    <span class="job" *ngSwitchCase="5">{{'developer' | translate}}</span> -->
                </span><br>
                <div >
                    <i class='bx bxs-star me-1'></i>
                    <span>{{property.user?.review?.toFixed(1)}}</span>    
                </div>
            </div>

            <div *ngIf="property?.user?.phonenumber && property?.user?.email">
                <!-- <h6 class="title">{{'contact' | translate}}</h6> -->

                <app-contact-info [property]="property"></app-contact-info>
            </div>
        </div>
    </div>
</div>

<!-- </div> -->