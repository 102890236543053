import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { Subscribtionservice } from '@realstate/services/subscribtions.service';
import { UserService } from '@realstate/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LanguageService } from '@realstate/services/lang.service';

@Component({
  selector: 'app-subscribtions',
  templateUrl: './subscribtions.component.html',
  styleUrls: ['./subscribtions.component.scss']
})
export class SubscribtionsComponent implements OnInit {

  textDir: string;
  subscribtions: any = null;
  user: any = null;
  data: any = null;

  constructor(
    public translate: TranslateService,
    private subscribtionService: Subscribtionservice,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private lang : LanguageService
    ) {
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getSubscribtions();
  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

  getCurrentUser(){
    this.userService.getCurrentAuth().subscribe(
      response =>{
        this.user = response.user;
      }
    )
  }

  getSubscribtions(){
    this.subscribtionService.getSubscribtions().subscribe(
      response =>{
        this.subscribtions = response.list;
      });
  }

  onSelect(id){
    this.data = [
      {'user_id': this.user.id,
      'subscribtion_id': id}
    ];

    var formData = this.userService.changeSubObjectToForm(this.data[0]);
    this.updateSubscribtion(formData);
  }

  updateSubscribtion(formData){
    this.userService.updateSubscribtion(formData).subscribe(
      (response)=>{
        this.toastr.success('Subscribtion updated successfully', 'Success');
        window.location.reload();
      },error =>{
        this.toastr.error('Error updating subscribtion', 'Error');
      }
    );
  }
}
