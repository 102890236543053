import { Component, OnInit } from '@angular/core';
import { AuthService } from '@realstate/auth/auth.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: '../user-profile/user-profile.component.html',
  styleUrls: ['../user-profile/user-profile.component.scss']
})
export class MyProfileComponent extends UserProfileComponent implements OnInit {

  ngOnInit(){
    this.titleService.setTitle('User profile')

    window.scrollTo(0,0)
    this.getCurrentUser();
    this.profile = 2;
  }

  getCurrentUser(){
    this.userService.getCurrentAuth().subscribe(
      response =>{
        this.currentUser = response.user;
        console.log(this.currentUser);
        console.log(response);
        
        this.user = response.user;
        this.fetchUser(this.user);        
      }, error =>{
        console.log(error);
      }
    )
  }

}
