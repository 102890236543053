<div class="listing-section-right" [ngClass]="filterSection ? 'filterStyle' : ''">
    <h3 class="title"(click)="filterMode()"> <i class="flaticon-filter"></i> {{'filters' | translate}}</h3>
    <div *ngIf="!Mobview || filterSection" class="listing-right-form">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <!-- <div class="form-group">
                <div class="form-control px-2" style="border-right: none !important;">
                    <ng-select [multiple]="true" [maxSelectedItems]="3" [closeOnSelect]="false" [data]="locations" [(ngModel)]="tempLocations"
                        placeholder="Type Any Location" formControlName="locations"
                        (add)="addTagFn($event)" (search)="getSearchLocations($event)">

                        <ng-option [value]="item" *ngFor="let item of locations">
                            <div  class="">
                                    <h5 class="card-title">{{item.compounds?.name?item.compounds?.name:item.government?.name}}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted">
                                        <span *ngIf="item.government?.name">{{item.government?.name}}</span>
                                        <span *ngIf="item.district?.name">{{'/'+item.district?.name}}</span>
                                        <span *ngIf="item.compounds?.name">{{'/'+item.compounds?.name}}</span>
                                    </h6>
                            </div>
                        </ng-option>

                    </ng-select>
                </div>
            </div> -->


            <!-- <div class="form-group"> -->
                <!-- <select class="form-select" formControlName="government_id"
                    (change)="updateGovernment($event.target.value)">
                    <option [value]=null>{{'all governments' | translate}}</option>
                    <option *ngFor="let government of governments" [value]="government.id">{{translate.currentLang ==
                        'ar' ? government?.name_ar : government?.name}}</option>
                </select> -->
                <!-- <ng-select [items]="governments" bindLabel="name" bindValue="id" [multiple]="true"
                        [closeOnSelect]="false" placeholder="{{'all governments' | translate}}" formControlName="government_id">
                    </ng-select> -->
            <!-- </div> -->

            <div class="form-group">
                <i class='flaticon-loupe'></i>
                <input type="text" class="form-control" placeholder="{{'what are you searching for?' | translate}}"
                    formControlName="description">
            </div>

            <div class="form-group range-slider" >
                <ng5-slider *ngIf="minPrice != maxPrice" (valueChange)="valueChange($event)" [(value)]="minPrice" [(highValue)]="maxPrice"
                    [options]="options"></ng5-slider>
                <ng5-slider *ngIf="minPrice == maxPrice" [(value)]="minPrice" [options]="options"></ng5-slider>
            </div>
            <label>{{'area2' | translate}} <span>(&#13217;)</span> : </label>
            <ul>
                <li *ngFor="let area of areas">
                    <div class="agree-label">
                        <input #areasCheckboxes type="checkbox" id="{{area}}" name="area"
                            (change)="attachArea(area, $event.target.checked)">
                        <label for="{{area}}" class="mx-1">{{area}}</label>
                    </div>
                </li>
            </ul>
            <!-- buy or rent -->
            <label>{{'buyorrent' | translate}}: </label>
            <ul>
                <!-- <li *ngFor="let unit of units">
                        <div class="agree-label">
                            <input #unitsCheckboxes type="checkbox" id="{{unit}}" name="units" (change)="attachUnit(unit, $event.target.checked)">
                            <label for="{{unit}}">{{unit}}</label>
                        </div>
                     </li> -->
                <!-- <li *ngFor="let unit of units"> -->
                <li>
                    <div class="agree-label">
                        <input #unitsCheckboxes type="checkbox" id="buy" name="unit"
                            (change)="attachUnit(1, $event.target.checked)">
                        <label for="buy" class="mx-1">{{'buy'|translate}}</label>
                    </div>
                </li>
                <li>
                    <div class="agree-label">
                        <input #unitsCheckboxes type="checkbox" id="rent" name="unit"
                            (change)="attachUnit(0, $event.target.checked)">
                        <label for="rent" class="mx-1">{{'rent'|translate}}</label>
                    </div>
                </li>
            </ul>
            <!-- ************ -->
            <div class="form-group">
                <select class="form-select" formControlName="propertytype_id"
                    (change)="updatePropertyType($event.target.value)">
                    <option [value]=null>{{'all property types' | translate}}</option>
                    <option *ngFor="let property_type of property_types" [value]="property_type.id">
                        {{translate.currentLang == 'ar' ? property_type?.name_ar : property_type?.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label style="top: 0 ">{{'bedrooms' | translate}}</label>
                <select class="form-select" formControlName="bedrooms">
                    <option [value]=null>{{'any' | translate}}</option>
                    <option *ngFor="let e of [].constructor(maxBedrooms); let i = index" [value]=i+1>{{i+1}}</option>
                </select>
            </div>
            <div class="form-group">
                <label style="top: 0 ">{{'bathrooms' | translate}}</label>
                <select class="form-select" formControlName="bathrooms">
                    <option [value]=null>{{'any' | translate}}</option>
                    <option *ngFor="let e of [].constructor(maxBathrooms); let i = index" [value]=i+1>{{i+1}}</option>
                </select>
            </div>



            <div>
                <label>{{'Outdoor Area' | translate}}: </label>
                <ul>
                    <li>
                        <div class="agree-label">
                            <input #outdoorsCheckboxes type="checkbox" id="Roof" name="roof"
                                (change)="attachOutdoorarea('roof', $event.target.checked)">
                            <label for="Roof" class="mx-1">{{'Roof'|translate}}</label>
                        </div>
                    </li>
                    <li>
                        <div class="agree-label">
                            <input #outdoorsCheckboxes type="checkbox" id="Garden" name="garden"
                                (change)="attachOutdoorarea('garden', $event.target.checked)">
                            <label for="Garden" class="mx-1">{{'Garden'|translate}}</label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="facilities-list">
                <h3 class="facilities-title"> {{'facilities' | translate}}</h3>

                <ul>
                    <li *ngFor="let feature of features">
                        <div class="agree-label">
                            <input #featuresCheckboxes type="checkbox" (change)="attachFeatures(feature.id, $event.target.checked)"
                             id="{{feature.id}}" name="feature_id">
                            <label for="{{feature.id}}" class="mx-1">{{translate.currentLang == 'ar' ? feature.name_ar : feature.name}}</label><i class="{{feature.icon}}"></i>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 text-center">
                    <button type="submit" class="default-btn border-radius">{{'search' | translate}}</button>
                </div>
                <div class="col-lg-6 col-md-6 text-center">
                    <button type="button" (click)="clear()" class="clear-btn border-radius">{{'clear' |
                        translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
