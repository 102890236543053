import { Component, OnInit, Output, EventEmitter, Input, ViewChildren, QueryList, ElementRef, } from '@angular/core';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PropertiesService } from '@realstate/services/properties.service';
import { PropertyTypesService } from '@realstate/services/property_types.service';
import { FeaturesService } from '@realstate/services/features.service';
import { GovernmentsService } from '@realstate/services/governments.service';
import { Options } from 'ng5-slider';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { LocationsService } from '@realstate/services/locations.service';
import { LanguageService } from '@realstate/services/lang.service';
import { LookupService } from '@realstate/services/lookup.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() id: any = null;
  featuresArray = [];
  areaArray = [];
  unitArray = [];
  outdoorArray = [];
  form: FormGroup;
  minPrice: number = 100000;
  maxPrice: number = 10000000;
  total: any;
  noProperties: boolean;
  property_types: any = null;
  features:any = null;
  properties: any = null;
  governments: any = null;
  locations: any ;
  tempLocations = [] ;
  selectedLocations = [];
  selectedCompounds = [];
  filterSection : Boolean = false
  Mobview : boolean = false

  options: Options = {
    floor: 100000,
    ceil: 10000000,
    translate: (value: number): string => {
      return  value.toLocaleString('en') + 'EGP';
    }
  };

  submitType: number = null;
  areas = [' 50-100', ' 100-150', ' 150-200', ' 200-250', ' 250-300', ' 300-350'];
  units = ['buy' , 'rent']
  maxBedrooms: number;
  maxBathrooms: number;
  @Output() formEmitter = new EventEmitter<any>(); 
  @Output() clearEmitter = new EventEmitter<any>(); 
  @Input() searchValue: string;
  @ViewChildren("areasCheckboxes") areasCheckboxes: QueryList<ElementRef>;
  @ViewChildren("unitsCheckboxes") unitsCheckboxes: QueryList<ElementRef>;
  @ViewChildren("featuresCheckboxes") featuresCheckboxes: QueryList<ElementRef>;
  @ViewChildren("outdoorsCheckboxes") outdoorsCheckboxes: QueryList<ElementRef>;

  constructor(
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private propertyTypesService:PropertyTypesService,
    private featuresService:FeaturesService,
    private governmentsService: GovernmentsService,
    private propertiesService:PropertiesService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private LocationsService: LocationsService,
    private lang : LanguageService

    ) { }

  ngOnInit(): void {
    if(window.innerWidth < 768 ){
      this.Mobview = true
      console.log(this.Mobview);
    }
    this.createForm();
    //this.getGovernments();
    this.getFeatures();
    this.getPropertyTypes();
    this.headToSearch();
    this.checkSearchValue();
    this.updatePrice();
    this.max();

  }
  filterMode(){
    this.filterSection = !this.filterSection
  }

  createForm(){
    this.form = this.formBuilder.group({
      government_id: [null, Validators.compose([])],
      price: ['', Validators.compose([])],
      area: ["", Validators.compose([])],
      units: [[], Validators.compose([])],
      outdoors: [[], Validators.compose([])],
      propertytype_id: [null, Validators.compose([])],
      description: ["", Validators.compose([])],
      features: ["", Validators.compose([])],
      minPrice: ["", Validators.compose([])],
      maxPrice: ["", Validators.compose([])],
      bathrooms: [null, Validators.compose([])],
      bedrooms: [null, Validators.compose([])],
      locations: [null, Validators.compose([])],
      compounds: [null, Validators.compose([])],

    });
  }

  checkSearchValue(){
    if(this.searchValue != null)
      this.form.controls['description'].setValue(this.searchValue);
  }

  attachFeatures(id, event){
    if(event == true)
      this.featuresArray.push(id);
    else{
      this.featuresArray.forEach((element,index)=>{
        if(element==id) this.featuresArray.splice(index,1);
      });
    }
    this.form.controls['features'].setValue(this.featuresArray);
  }

  updateGovernment(government){
    this.form.controls['government_id'].setValue(government);
    this.updatePrice();
  }

  updatePropertyType(propertyType){
    console.log('123');
    this.form.controls['propertytype_id'].setValue(propertyType);
    this.updatePrice();
  }

  attachArea(id, event){
    if(event == true)
      this.areaArray.push(id);
    else{
      this.areaArray.forEach((element,index)=>{
        if(element==id) this.areaArray.splice(index,1);
      });
    }
    this.form.controls['area'].setValue(this.areaArray);
    this.updatePrice();
  }

  attachUnit(id, event){
    if(event == true)
      this.unitArray.push(id);
    else{
      this.unitArray.forEach((element,index)=>{
        if(element==id) this.unitArray.splice(index,1);
      });
    }
    this.form.controls['units'].setValue(this.unitArray);
    this.updatePrice();
  }

  attachOutdoorarea(id, event){
    if(event == true)
      this.outdoorArray.push(id);
    else{
      this.outdoorArray.forEach((element,index)=>{
        if(element==id) this.outdoorArray.splice(index,1);
      });
    }
    this.form.controls['outdoors'].setValue(this.outdoorArray);
    this.updatePrice();
  }


  getPropertyTypes(){
    this.property_types = this.lookupService.lookups.propertyTypes;
    // this.propertyTypesService.getPropertyTypes().subscribe(
    //   response => {
    //     this.property_types = response.list;
    //   }
    // )
  }

  getFeatures(){
    this.features = this.lookupService.lookups.features;
  }

  getGovernments(){
    this.governmentsService.getGovernments().subscribe(
      response =>{
        this.governments = response.list;
      }
    )
  }

  updatePrice(){
    this.propertiesService.updatePrice(this.form.value).subscribe(
      response => {
        if(response.minPrice != null && response.maxPrice != null){
          this.minPrice = response.minPrice;
          this.maxPrice = response.maxPrice;
          this.options = {
            floor: response.minPrice,
            ceil: response.maxPrice,
            translate: (value: number): string => {
              return  value.toLocaleString('en') + 'EGP';
            }
          };
        }
      }
    )

  }

  max(){
    this.maxBedrooms = this.lookupService.lookups.maxBedrooms;
    this.maxBathrooms = this.lookupService.lookups.maxBathrooms;
  }

  getSearchLocations(term) {
    if (term.term?.length < 2) return;
    console.log(term);
    let result = {};
    this.LocationsService.getSearchLocations(term.term).subscribe((res) => {
      result = res.results;
      this.locations = res.results;
    });

    // console.log(result);
    //  return result ;
  }

  addTagFn = (item) => {
    console.log(item);

    if (item.compounds) {
      this.selectedCompounds.push(item.compounds.id);
    } else {
      if (item.government) {
        this.selectedLocations.push(item.government.id);
      } if (item.district) {
        this.selectedLocations.push(item.district.id);
      }
    }

    console.log(this.selectedCompounds);
    console.log(this.selectedLocations);
  }


  headToSearch(){
    if(this.activatedRoute.snapshot.queryParams['minPrice']){
      this.form.controls['government_id'].setValue(this.activatedRoute.snapshot.queryParams['government'] ?
      this.activatedRoute.snapshot.queryParams['government'] : 'null');
      this.form.controls['minPrice'].setValue(this.activatedRoute.snapshot.queryParams['minPrice']);
      this.form.controls['maxPrice'].setValue(this.activatedRoute.snapshot.queryParams['maxPrice']);
      this.form.controls['area'].setValue(this.activatedRoute.snapshot.queryParams['area']);
      this.form.controls['units'].setValue(this.activatedRoute.snapshot.queryParams['units']);
      this.form.controls['outdoors'].setValue(this.activatedRoute.snapshot.queryParams['outdoors']);
      this.form.controls['propertytype_id'].setValue(this.activatedRoute.snapshot.queryParams['propertytype'] ?
      this.activatedRoute.snapshot.queryParams['propertytype'] : 'null');
      this.form.controls['bedrooms'].setValue(this.activatedRoute.snapshot.queryParams['bedrooms'] ?
      this.activatedRoute.snapshot.queryParams['bedrooms'] : 'null');
      this.form.controls['bathrooms'].setValue(this.activatedRoute.snapshot.queryParams['bathrooms'] ?
      this.activatedRoute.snapshot.queryParams['bathrooms'] : 'null');
      this.form.controls['description'].setValue(this.activatedRoute.snapshot.queryParams['description']);
      this.form.controls['features'].setValue(this.activatedRoute.snapshot.queryParams['features']);
      // this.form.controls['locations'].setValue(this.activatedRoute.snapshot.queryParams['locations']);
      // this.form.controls['compounds'].setValue(this.activatedRoute.snapshot.queryParams['compounds']);

      this.updatePrice();

      this.formEmitter.emit({form: this.form.value, num: 2});
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.form.controls['minPrice'].setValue(this.minPrice);
    this.form.controls['maxPrice'].setValue(this.maxPrice);
    console.log(this.form.controls['minPrice'].value);
    console.log(this.form.controls['maxPrice'].value);
    this.formEmitter.emit({form: this.form.value, num: 1, side:1});
    console.log(this.form.value);

  }
  valueChange(x){
    // console.log(x);
    // console.log(this.minPrice);
    
  }

  clear(){
    this.form.reset();
    this.form.controls['government_id'].setValue("null");
    this.form.controls['propertytype_id'].setValue("null");
    this.form.controls['bedrooms'].setValue("null");
    this.form.controls['bathrooms'].setValue("null");
    this.form.controls['area'].setValue("");
    this.form.controls['units'].setValue("");
    this.form.controls['outdoors'].setValue("");

    this.areasCheckboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.unitsCheckboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.featuresCheckboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.outdoorsCheckboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.updatePrice();
    this.clearEmitter.emit(1)
    // this.propertiesService.getProperties(1).subscribe(res => {
    //   console.log(res);
    // })
  }

}
