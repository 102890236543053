import { Component, OnInit } from '@angular/core';
import { PropertiesService } from '@realstate/services/properties.service';
import { environment } from 'environments/environment';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { SettingService } from '@realstate/services/setting.service';
import { LanguageService } from '@realstate/services/lang.service';
import { LookupService } from '@realstate/services/lookup.service';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

  properties: any = null;
  textDir: string;
  setting = {}
  environment = environment.imagesUrl;
  currentTime = new Date()
  year


  constructor(
    public translate: TranslateService,
    private propertiesService: PropertiesService,
    private service : SettingService,
    private lang : LanguageService,
    private lookupService : LookupService
  ) {
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {

      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
   }

  ngOnInit(): void {
    this.year = this.currentTime.getFullYear();


    //this.getLatest();
    this.getSetting();
  }

  getSetting(){
    this.setting = this.lookupService.lookups?.settings
  }

  currentLang(){
    this.lang.getlang().subscribe(res => {
      this.translate.currentLang = localStorage.getItem('lang')
  })
      if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }

  getLatest(){
    this.propertiesService.getNewest(3).subscribe(
      (response)=>{
        this.properties = response.record;
      }, (error)=>{
      }
    )
  }
}
