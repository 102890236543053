import { Component, OnInit } from '@angular/core';
import {NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import { environment } from 'environments/environment';
import { UserService } from '@realstate/services/user.service';
import { ReportsService } from '@realstate/services/reports.service.';
import { ReviewsService } from '@realstate/services/reviews.service';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '@realstate/auth/auth.service';
import { LanguageService } from '@realstate/services/lang.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  currentUser: any = null;
  user: any = null;
  properties: any[] = null;
  reviews: any = null;
  environment = environment.imagesUrl;
  userImage: string = null;
  userCover: string = null;
  averageFloor: number = null;
  averageFraction: number = null;
  closeResult = '';
  reviewForm: FormGroup;
  reportForm: FormGroup;
  formSubmitted = false;
  reports: any = null;
  selectedReport: number = null;
  textDir: string;
  profile: number = null;
  currentRate = 0;
  review: any = null;
  id: any;
  p: number = 1;
array = [] ;
  reporttypes = [
    'Pretending to be someone else', 'Fake Account', 'Fake Name','Posting Inappropriate Things', "I Can't Access My Account",
    'I Want ro Help','Something Else',
  ]

  reporttypes_ar = [
    'ادعاء شخصية أخري', 'حساب كاذب', 'أسم كاذب','نشر أشياء غير لائقة', "لا يمكنني فتح حسابي",
    'اريد المساعدة','شيء أخر',
  ]
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    margin: 10,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    autoHeight:true,
    // center:true,
    autoWidth:false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 3,
      }
    },
    nav: true,

  }
  isAuthUser: boolean;

  constructor(
    public userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private reportsService: ReportsService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private reviewService: ReviewsService,
    public auth:AuthService,
    private lang : LanguageService , 
    public titleService: Title
    ) {
      this.titleService.setTitle('User profile')
      this.currentLang();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
      {
        if(event.lang == 'ar')
        {
          this.textDir = 'rtl';
        }
        else
        {
          this.textDir = 'ltr';
        }
      });
    }

  ngOnInit(){
    this.getCurrentUser();
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if(this.id)
      this.getUser(this.id);
    this.createForm();
    this.createReviewForm();

    console.log(this.auth.isAuthenticated());
    

   }

   currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
    else
      this.textDir = 'ltr';
  }

   createForm(){
    this.reportForm = this.formBuilder.group({
      id: ["", Validators.compose([])],
      reporttype: ["", Validators.compose([Validators.required])],
      reporttext: ["", Validators.compose([])],
      from: ["", Validators.compose([])],
      to: ["", Validators.compose([])],
    });
  }

  createReviewForm(){
    this.reviewForm = this.formBuilder.group({
      id: ["", Validators.compose([])],
      comment: ["", Validators.compose([])],
      rating: ["", Validators.compose([Validators.required])],
      user_from_id: ["", Validators.compose([])],
      user_to_id: ["", Validators.compose([])],
    });
  }

  open(content) {
    this.modalService.open(content, {size: 'lg'},).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getCurrentUser(){
    this.userService.getCurrent().subscribe(
      response =>{
        this.currentUser = response?.user;
        console.log(this.currentUser);
      }, error =>{
        console.log(error);
      }
    )
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fetchUser(user){
    this.getUserProperties(user.id);
    this.getUserReviews(user.id);
    this.userImage = this.environment + '/users-images/' + user?.image;
    this.userCover = this.environment + '/users-images/' + user?.cover;
    this.averageFloor = user.review - user.review % 1;
    this.averageFraction = user.review % 1;
  }

  selected(num){
    this.selectedReport = num;
  }

  getUser(id){
    this.userService.getUser(id).subscribe(
      response =>{
        this.user = response.record;
        console.log(this.user);
        console.log(this.user.usertype);
        
        this.fetchUser(this.user);
      }, error =>{
        this.router.navigate(['error']);
      }
    )
  }

  getUserProperties(id){
    this.userService.getUserProperties(id).subscribe(
      response => {
        this.properties = response.record;
      });
  }

  getUserReviews(id){
    this.userService.getUserReview(id).subscribe(
      response => {
        this.reviews = response.record;
        console.log(this.reviews);
      }
    )
  }

  checkCurrentUser(){
    if(!this.currentUser){
      this.modalService.dismissAll();
    }else{
      this.reportForm.controls['from'].setValue(this.currentUser.id);
    }
  }

  onSubmit(){
    this.formSubmitted = true;
    this.reportForm.controls['reporttype'].setValue(this.selectedReport);
    this.reportForm.controls['to'].setValue(this.user.id);
    this.checkCurrentUser();
    if (!this.reportForm.valid) {
      return;
    }

    var formData = this.reportsService.changeObjectToForm(this.reportForm.value);

    this.saveData(formData);
  }
  change(x){
    this.reviewForm.get('rating').setValue(this.currentRate)
    console.log(this.reviewForm.value.rating);
  }

  onSubmitReview() {
    this.formSubmitted = true;
    if (!this.reviewForm.valid && this.currentRate == 0) {
      return;
    }

    if(this.currentUser == null){
      this.router.navigate(['/login-register']);
      return;
    }
    this.reviewForm.controls['rating'].setValue(this.currentRate);
    this.reviewForm.controls['user_from_id'].setValue(this.currentUser.id);
    this.reviewForm.controls['user_to_id'].setValue(this.user.id);
    var formData = this.reviewService.changeObjectToForm(this.reviewForm);

    this.saveReviewData(formData);
  }

  saveData(formData) {

    this.reportsService.addReport(formData).subscribe(
      (response: any) => {
        this.reports = response;
        this.modalService.dismissAll();
        this.toastr.success('Report Sent successfully', 'Success');
    },
    (error) => {
      console.log(error);
    });
  }

  saveReviewData(formData) {
    this.reviewService.addReview(formData).subscribe(
      (response: any) => {
        this.review = response;
        this.reviewForm.controls['comment'].reset()
        this.currentRate = 0;
    },
    (error) => {
      this.toastr.error('Error Submitting review', 'error');
    });
  }

  toAbout(){
    document.getElementById("about").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }
  toListings(){
    document.getElementById("listings").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }
  submitReviews(){
    document.getElementById("contactForm").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }
  toReviews(){
    document.getElementById("reviews").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest"
    });
  }

  isAuth(){
    if(this.auth.isAuthenticated()){
      this.isAuthUser = true ;
    }else{
      this.isAuthUser = false
    }
  }
}
