import { Component, Input, OnInit } from '@angular/core';
import { PropertiesService } from '@realstate/services/properties.service';

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})

export class ContactInfoComponent implements OnInit {
    @Input() property: any;

    constructor(private propertyService: PropertiesService) { }

    ngOnInit(): void {
    }

    clickOnProperty() {
        this.propertyService.clickOnProperty(this.property.id).subscribe(res => {
        });
    }

}
