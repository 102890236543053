import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class photoService {

    photoSubject =new BehaviorSubject<any>('');
    constructor(private http: HttpClient) { }
  
    getPhoto() : Observable <any> {
      return this.photoSubject.asObservable();     
    }
  
    selectPhoto(product:any){   
      this.photoSubject.next(product);
    }
}
