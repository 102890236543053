import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { timeInterval } from 'rxjs/operators';
import { NotificationService } from '@realstate/services/Notification.service';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(public _firebaseAuth: AngularFireAuth,
    private toastr: ToastrService, private notification : NotificationService,
    public router: Router,
     private cookieService: CookieService) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this.post.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });

  }

  logout() {
    // this.cookieService.delete('access_token');
    this.cookieService.deleteAll()
    this.router.navigate(['login-register']).then(() => {
      this.toastr.success('Logout successfully')
      this.notification.selectNotification('logout')
    });;
  }

  isAuthenticated() {        
    return this.cookieService.check('access_token');
  }
  isVerified(){
      let verified = localStorage.getItem('is_verified');
      if(verified === '1'){
       return true
      }else{
         return false
      } 
  

  }

  getToken(){
    return this.cookieService.get('access_token');
  }
  
}
