import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginservice {
  subject = new Subject
  constructor(private http:HttpClient) { }

  checkLogin(userdata){
      return this.http.post<any>(environment.localUrl + 'social/login' , userdata);
  }
  socialRegister(userdata){
      return this.http.post<any>(environment.localUrl + 'social/register' , userdata);
  }


  selectClient(clientdata:any){   
    this.subject.next(clientdata);
  }
  getuserdata() : Observable <any> {
    return this.subject.asObservable();     
  }



}
