import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { LanguageService } from '@realstate/services/lang.service';
import { UserService } from '@realstate/services/user.service';

@Component({
  selector: 'app-my-Reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.scss']
})
export class MyReviewsComponent implements OnInit {

  current: any = null;
  textDir: string;
  reviews: any = null;
  page: number = 1;
  noReviews: boolean;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private lang : LanguageService,
    private titleService: Title

  ) {
    this.titleService.setTitle('Reviews')
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0,0)

  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }

  fetchData(event){
    this.current = event.currentUser;
    if(this.current)
      this.userReviews(this.current.id)
    else
      this.userReviews(0);
  }

  userReviews(id){
    this.userService.getUserReviewAuth(id).subscribe(
      (response) =>{
        this.reviews = response.record;
      }
    )
  }

}
