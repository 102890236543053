<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg2" style="background-image: url('{{cover}}')" dir="{{textDir}}">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col" style="z-index: 1;">
                    <div class="inner-banner-text row">
                        <!-- <i *ngIf="property?.property?.thumbnail == null" class="fa fa-image"></i> -->
                        <!-- <img *ngIf="property?.property?.thumbnail != null" src="{{thumbnail}}" alt="Images" class="user-pic"> -->
                        <h3 class="listing-title">{{translate.currentLang == 'ar' ? property?.property?.name_ar :
                            property?.property?.name }}
                        </h3>
                        <div class="col-10">
                            <ul>
                                <li><i class="flaticon-cursor"></i> 
                                    {{translate.currentLang == 'ar' ?property?.property?.location?.name_ar : property?.property?.location?.name}}
                                    {{property?.property?.compound ? ',' : '' }}
                                    {{property?.property?.compound ? property?.property?.compound?.name : ''}} 
                                    </li>

                                <!-- <li *ngIf="property?.user?.phonenumber"><i class="flaticon-telephone"></i> <a
                                        href="tel:{{property?.user?.phonenumber}}">{{property?.user?.phonenumber}}</a></li>
                                <li *ngIf="property?.user?.email"><i class="flaticon-email-1"></i> <a
                                        href="mailto:{{property?.user?.email}}">{{property?.user?.email}}</a></li> -->
                                <li>
                                    <fa-icon  [icon]="faVectorSquare"></fa-icon>  {{property?.property?.size}}
                                    <span>&#13217;</span> 
                                </li>
                                <li>
                                    <fa-icon [icon]="faBed"></fa-icon>  {{property?.property?.bedrooms}}
                                </li>
                                <li>
                                    <fa-icon [icon]="faShower"></fa-icon>  {{property?.property?.bathrooms}}
                                </li>
                            </ul>
                        <h4 *ngIf="property?.property?.state == 'pending'" class="fw-bold mt-2" >Pending Approved</h4>

                        </div>
                        <div class="col-2 d-flex justify-content-end">
                            <i class='bx bxs-heart ms-2  favBtn' *ngIf="isFav && !isOwner()" (click)="addToFav()"></i>
                            <i class='bx bx-heart ms-2 favBtn' *ngIf="!isFav && !isOwner()" (click)="addToFav()"></i>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-4 text-left">
                    <div class="banner-rating" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <ul>
                            <li class="rating-star text-center">
                                <i class='bx bxs-star'></i>
                                <span> {{'rate' | translate}}</span>
                            </li>
                            <li><a routerLink="#">{{+property?.avgRating?.toFixed(1)}}</a></li>
                        </ul>
                    </div>
                </div> -->

            </div>
        </div>

        <ng-template #content let-modal>
            <div class="container" dir="{{textDir}}">
                <form [formGroup]="reportForm" (ngSubmit)="onSubmit()">
                    <div class="listing-category">
                        <div class="row">
                            <i style="color: #f19101 !important; margin-top: 1%;"
                                class="fa fa-exclamation-triangle col-md-1"></i>
                            <span class="col-md-10">{{'report listing' | translate}}</span>
                        </div>
                        <textarea class="form-control mt-3" rows="8" formControlName="body"></textarea>
                        <hr>
                        <p class="help-block" *ngIf="formSubmitted && f.body.errors?.required ">
                            <small style="color: red;" class="help-block">{{"report validation" | translate}}</small>
                        </p>
                        <div style="text-align: right;">
                            <button type="submit" class="default-btn" style="padding: 4px 20px !important">{{'send message' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-12 col-md-12" style="z-index: 1;">
                    <div class="banner-profile" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <img *ngIf="property?.user?.image" src="{{userImage}}" alt="Images"> &nbsp;
                        <h3><a class="anchor2" routerLink="/profile/{{property?.user?.id}}">By
                                {{property?.user?.fullname}} | </a></h3>
                        <div class="anchor2" style="display:inline-block">
                            <i class='bx bxs-star mx-2'></i>
                            <span>{{property?.user?.review?.toFixed(1)}}</span>
                        </div>
                    </div>
                    <div class="banner-tag">
                        <ul>
                            <li>
                                <a class="a-white" (click)="open(content)" *ngIf="!isOwner()"><i
                                        class="fa fa-ban mx-1"></i>{{'report list btn' | translate}}</a>
                                <a class="a-white" *ngIf="isOwner()"><i class="fa fa-ban mx-1"></i>{{'expiry data' |
                                    translate}}{{property?.property.expiry_date}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a routerLink="/">{{'home' | translate}}</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{ translate.currentLang == 'ar' ? property?.property?.name_ar :
                            property?.property?.name}}</li>
                    </ul>
                </div>
                <div class="col-lg-6 col-md-5">
                    <ul class="social-link" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <li>
                            {{'share listing profile' | translate}}
                        </li>
                        <li style="background-color:#f19101; border-radius: 10px; ">
                            <share-buttons [theme]="'default'" [include]="['whatsapp','linkedin' , 'facebook']" [show]="4" >
                            </share-buttons>
                            <!-- <svg (click)="shareFb()" style="width: 10px; height: 15px; margin: 0 10px; cursor:pointer;" xmlns="http://www.w3.org/2000/svg" width="1298" height="2500" viewBox="88.428 12.828 107.543 207.085"><path fill="white" d="M158.232 219.912v-94.461h31.707l4.747-36.813h-36.454V65.134c0-10.658 2.96-17.922 18.245-17.922l19.494-.009V14.278c-3.373-.447-14.944-1.449-28.406-1.449-28.106 0-47.348 17.155-47.348 48.661v27.149H88.428v36.813h31.788v94.461l38.016-.001z"/></svg> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-100 pb-70" dir="{{textDir}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="listing-details-wrap">
                    <div class="listing-category">
                        <ul>
                            <li><a (click)="toDetails()" class="anchor2"><i class="flaticon-touch"></i>{{'description' |
                                    translate}}</a></li>
                            <li *ngIf="property?.property?.images.length != 0"><a (click)="toGallery()"
                                    class="anchor2"><i class="flaticon-gallery"></i> {{'gallery' | translate}}</a></li>
                            <li *ngIf="property?.property?.map"><a (click)="toMap()" class="anchor2"><i
                                        class="flaticon-writing"></i> {{'map' | translate}}</a></li>
                            <!-- <li><a (click)="toReviews()" class="anchor2"><i class="flaticon-writing"></i> {{'reviews' |
                                    translate}}</a></li> -->
                        </ul>
                    </div>

                    <!-- test -->
                    <!-- gallery -->
                    <div *ngIf="property?.property?.images.length != 0" class="listing-widget gallery-slider"
                        id="gallery">
                        <h3 class="title">{{'gallery' | translate }}</h3>

                        <div class="gallery-wrapper">
                            <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery">
                            </ngx-gallery>
                        </div>
                    </div>

                    <!-- videos -->
                    <div *ngIf="property?.property?.videos.length != 0" class="listing-widget gallery-slider"
                        id="videoGallery">
                        <h3 class="title">{{'videos' | translate }}</h3>
                        <div class="videoWrap d-flex">
                            <div class="row m-1" *ngFor="let video of propertyVideos">
                                <div class="col-9 p-0 pe-1" *ngIf="video.video!='null'">
                                    <iframe class="embed-responsive-item" [src]="video.video | safe"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- test -->


                    <div class="listing-widget listing-widget-desc" id="details" style="min-height: 405px;">
                        <h3 class="title">{{'description' | translate}} </h3>
                        <p> {{translate.currentLang == 'ar' ? property?.property?.description_ar :
                            property?.property?.description}}</p>
                        <div *ngIf="property?.property?.features?.length != 0" class="listing-features">
                            <h3 class="features-title">{{"listing features" | translate}}</h3>
                            <div class="row">
                                <div *ngFor="let feature of property?.property?.features" class="col">
                                    <div class="features-card">
                                        <span
                                            style="border: 1px solid #f19101; border-radius: 50%; padding: 5px 6px 9px 7px;">
                                            <img style="width: 20px" src="{{icons}}{{feature?.icon}}" alt="feature">
                                        </span>
                                        <h3 class="mt-2">{{translate.currentLang == 'ar' ? feature?.name_ar :
                                            feature?.name}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div> <!-- end of col 8 -->

            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side details-side pb-4">
                        <h3 class="title text-color-white mb-0" style="background-color: var(--titleColor);">{{"details"
                            | translate}}</h3>
                        <div class="container-fluid pt-4 pb-4 details-info">
                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'property type' | translate}}</span>
                                <span class="col-4 text-center">{{textDir == 'rtl' ?
                                    property?.property?.property_type?.name_ar
                                    : property?.property?.property_type?.name }}</span>
                            </div>
                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'bedrooms' | translate}}</span>
                                <span class="col-4 text-center">{{property?.property?.bedrooms}}</span>
                            </div>
                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'bathrooms' | translate}}</span>
                                <span class="col-4 text-center">{{property?.property?.bathrooms}}</span>
                            </div>

                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'finish type' | translate}}</span>
                                <span class="col-4 text-center">
                                    {{
                                    property?.property?.finishtype == 1 ? ('core and shell' | translate) :
                                    property?.property?.finishtype == 2 ? ('semi finished' | translate) :
                                    property?.property?.finishtype == 3 ? ('fully finished' | translate) :
                                    ('fully finshed and furnished' | translate)
                                    }}</span>
                            </div>
                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'payment method' | translate}}</span>
                                <span class="col-4 text-center">
                                    {{property?.property?.paymentmethod == 1 ? ('cash' | translate) :
                                    property?.property?.paymentmethod == 2 ? ('installments' | translate) :
                                    ('cash or installments' | translate)}}</span>
                            </div>

                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'area2' | translate}}</span>
                                <span class="col-4 text-center">{{property?.property?.size}}
                                    <span>&#13217;</span></span>
                            </div>
                            <div class="row mb-3" *ngIf="property?.property?.garden">
                                <span class="col-8 detail-solo">{{'garden area' | translate}}</span>
                                <span class="col-4 text-center">{{property?.property?.garden}}
                                    <span>&#13217;</span></span>
                            </div>
                            <div class="row mb-3" *ngIf="property?.property?.roof">
                                <span class="col-8 detail-solo">{{'roof area' | translate}}</span>
                                <span class="col-4 text-center">{{property?.property?.roof}}
                                    <span>&#13217;</span></span>
                            </div>

                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'buyorrent' | translate}}</span>
                                <span class="col-4 text-center">
                                    {{property?.property?.units == 1 ? ( 'buy' | translate) : ('rent' |
                                    translate)}}</span>
                            </div>
                            <div class="row mb-3">
                                <span class="col-8 detail-solo">{{'status' | translate}}</span>
                                <span class="col-4 text-center">
                                    {{property?.property?.status == 1 ? ('delivered' | translate) : ('off plan' |
                                    translate)}}
                                </span>
                            </div>
                            <div class="row mb-3" *ngIf="property?.property?.status == '2'">
                                <span class="col-8 detail-solo">{{'delivery date' | translate}}</span>
                                <span class="col-4 text-center">{{property?.property?.deliverydate}}</span>
                            </div>
                        </div>
                        <div class="container-fluid pt-4">
                            <div class="row">
                                <span class="col-6">{{'price' | translate}}: </span>
                                <span class="col-6 detail-solo text-center"
                                    style="font-size: 20px;">{{property?.property?.price | number}} {{"egp" |
                                    translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of col-4 -->
        </div>
        <div class="row">
            <div class="col-lg-8" style="z-index: 1;">
                <!-- gallery -->

                <!-- videos -->

                <!-- map -->
                <div *ngIf="property?.property?.map && embedUrl" class="listing-widget" id="map">
                    <h3 class="title m-0">{{'map' | translate}}</h3>
                    <iframe *ngIf="embedUrl"
                    [src]="embedUrl" width="100%" height="450"></iframe>
                </div>

                <!-- reviews -->
                <!--
                    <div class="listing-widget listing-comment" id="reviews">
                    <h3 class="title">{{"reviews" | translate}}</h3>
                    <p *ngIf="property?.user?.reviews.length == 0" class="text-center">No Reviews Found</p>
                    <ul *ngIf="property?.user?.reviews.length > 0">
                        <li *ngFor="let review of property?.user?.reviews">
                            <div class="content">
                                <h3>{{review?.from_user?.fullname}}</h3>
                                <span>{{review?.created_at | date}}</span>
                                <p>{{review?.comment}}</p>
                                <div class="comment-rating">
                                    <i *ngFor="let i of [].constructor(review.rating)" class='bx bxs-star'></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div> -->

            </div>
            <div class="col-lg-4">
                <div class="listing-widget-side">
                    <h3 class="title">{{"seller profile" | translate}} :</h3>

                    <div class="listing-side-host">
                        <a routerLink="/profile/{{property?.user?.id}}">
                            <img *ngIf="property?.user?.image" src="{{userImage}}" alt="Images">
                            <h3>{{property?.user?.fullname}}</h3>

                            <span [ngSwitch]="property?.user?.usertype">
                                <span class="job" *ngSwitchCase="1">{{'Owner' | translate}}</span>
                                <span class="job" *ngSwitchCase="2">{{'Freelance Broker' | translate}}</span>
                                <span class="job" *ngSwitchCase="3">{{'Brokerage Company' | translate}}</span>
                                <!-- <span class="job" *ngSwitchCase="4">{{'broker' | translate}}</span>
                                <span class="job" *ngSwitchCase="5">{{'developer' | translate}}</span> -->
                            </span><br>

                            <span>{{property?.placeNumber}} {{"lists hosted" | translate}}</span>
                        </a>
                        <div>
                            <i class='bx bxs-star mx-2 mb-3'></i>
                            <span>{{property?.user?.review?.toFixed(1)}}</span><br>
                            <span class="userReviews"
                                routerLink="/profile/{{property?.user?.id}}">{{property?.user.reviews?.length}}
                                {{'reviews' | translate}} </span>
                        </div>

                        <app-contact-info class="contactInfo" [property]="property"></app-contact-info>

                    </div>


                    <!-- <div class="banner-rating" [ngStyle]="{'float': translate.currentLang == 'ar' ? 'left' : ''}">
                        <ul style="color: black;">
                            <li class="rating-star">
                            </li>
                        </ul>
                    </div> -->

                </div>

                <!-- <div class="listing-widget-side" *ngIf="property?.user?.phonenumber || property?.user?.email">
                    <h3 class="title">{{'contact' | translate}}</h3>

                    <app-contact-info [property]="property"></app-contact-info>
                </div> -->
            </div>

        </div>

    </div>
    <!-- end of row -->

</div>

<app-footer-style-one></app-footer-style-one>
