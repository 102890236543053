import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GovernmentsService {

  constructor(private http: HttpClient) { }

  getGovernment(id){
    return this.http.get<any>(environment.localUrl + 'locations/districts/' + id, { responseType: "json" });
    // return this.http.get<any>(environment.localUrl + 'governments/show/' + id, { responseType: "json" });

  }

  getGovernments(){
    // return this.http.get<any>(environment.localUrl +'governments/list?offset=0&limit=30');
    return this.http.get<any>(environment.localUrl +'locations/governments/list?offset=0&limit=30');

  }
}
