import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent  } from '@ngx-translate/core';
import { UserService } from '@realstate/services/user.service';
import { FormGroup, Validators, FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LanguageService } from '@realstate/services/lang.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-profile-password',
  templateUrl: './change-profile-password.component.html',
  styleUrls: ['./change-profile-password.component.scss']
})
export class ChangeProfilePasswordComponent implements OnInit {

  current: any = null;
  textDir: string;
  form: FormGroup;
  formSubmitted = false;
  record: any = null;
  error: string;

  constructor(
    public translate: TranslateService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private lang : LanguageService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Change-Password')
    this.currentLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) =>
    {
      if(event.lang == 'ar')
      {
        this.textDir = 'rtl';
      }
      else
      {
        this.textDir = 'ltr';
      }
    });

    this.form = this.formBuilder.group({
      id:"",
      currentPassword: ["", Validators.compose([Validators.required])],
      password: ["", Validators.compose([Validators.required])],
      confirmPassword: ["", Validators.compose([Validators.required])],
    }, { validator: this.checkPasswords });
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
  }

  currentLang(){
      this.translate.currentLang = localStorage.getItem('lang')
    if(this.translate.currentLang == 'ar')
        this.textDir = 'rtl';
      else
        this.textDir = 'ltr';
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  fetchData(event){
    this.current = event.currentUser;
  }

  onSubmit() {
    this.formSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    this.form.controls['id'].setValue(this.current.id);
    var formData = this.userService.changePasswordObjectToForm(this.form.value);
    this.saveData(formData);
  }

  saveData(formData) {
    this.userService.changePassword(formData).subscribe(
      (response: any) => {
        console.log(response);
        this.record = response.record;
        this.toastr.success('Password changed successfully', 'Success');
        this.router.navigate(['/']);
    },(error) => {
      this.error = error.error.message;
    });
  }

  closeAlert(){
    this.formSubmitted = false;
    this.error = null;
  }

}
