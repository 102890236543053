import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {


  constructor(private http: HttpClient) { }


  changeObjectToForm(form){
    const formData = new FormData();
    formData.append('id', form.get('id').value);
    formData.append('comment', form.get('comment').value);
    formData.append('rating', form.get('rating').value);
    formData.append('user_from_id', form.get('user_from_id').value);
    formData.append('user_to_id', form.get('user_to_id').value);
    return formData;
  }


  getReviews(page){
    return this.http.get<any>(environment.localUrl +'reviews/list');
  }

  addReview(formData){
    return this.http.post(environment.localUrl + 'reviews/create', formData);
  }

}
