import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { UserService } from '@realstate/services/user.service';
import { AuthService } from '@realstate/auth/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { shareReplay } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '@realstate/services/Notification.service';
import { LanguageService } from '@realstate/services/lang.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-navbar-style-one',
    templateUrl: './navbar-style-one.component.html',
    styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

    environment = environment.imagesUrl;
    currentUsr: any = null;
    properties: any = null;
    form: FormGroup;
    formSubmitted = false;
    textDir: string;
    userImage: any;
    subs: any;
    deviceInfo = null;
    userlogin : any
    

    @Output() currentUserEmitter = new EventEmitter<any>();

    constructor(
        private usersService: UserService,
        private formBuilder: FormBuilder,
        private router: Router,
        public authService: AuthService,
        public translate: TranslateService,
        private deviceService: DeviceDetectorService,
        private toastr: ToastrService,
        public notification : NotificationService,
        private lang : LanguageService
    ) {
        this.currentLang();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (event.lang == 'ar') {
                this.textDir = 'rtl';
            }
            else {
                this.textDir = 'ltr';
            }
        });
    }

    ngOnInit(): void {
     this.translate.currentLang = localStorage.getItem('lang')
        this.currentUser();
        this.createForm();
        this.detectDeveice();
    }

    currentLang() {
        if (this.translate.currentLang == 'ar')
            this.textDir = 'rtl';
        else
            this.textDir = 'ltr';
    }
    Translate(x){
        this.translate.use(x)
        localStorage.setItem('lang' , x)
    }
    RouterCondition(){
        if(this.authService.isAuthenticated){
            if(this.authService.isVerified()){
                this.router.navigate(['add-listing'])
            }else{
                this.router.navigate(['check-otp'])
                // this.usersService.verify().subscribe(res => {
                //     console.log(res);
                // })
            }
        }else{
            this.router.navigate(['login-register'])
        }
    }
    logout(){
        this.authService.logout()
    }

    currentUser() {
        if(this.authService.isAuthenticated()){
            this.notification.getClientname().subscribe(res => {
                this.currentUsr = res.user;
                this.userImage = this.environment + '/users-images/' + this.currentUsr?.image;
                this.currentUserEmitter.emit({ currentUser: this.currentUsr, currentUserImage: this.userImage });
            })
            if (!this.authService.isAuthenticated()) {
                this.currentUsr = ''
                return;
              }
            this.usersService.getCurrent().subscribe(
                response => {
                    this.notification.selectuser('yes')
                    this.currentUsr = response.user;
                    this.userImage = this.environment + '/users-images/' + this.currentUsr?.image;
                    this.currentUserEmitter.emit({ currentUser: this.currentUsr, currentUserImage: this.userImage });
                    console.log(response);
                    localStorage.setItem('user-data' ,JSON.stringify(response.user))
                    
                });
        }

    }

    createForm() {
        this.form = this.formBuilder.group({
            government_id: [null, Validators.compose([])],
            price: ['', Validators.compose([])],
            area: ["", Validators.compose([])],
            propertytype_id: [null, Validators.compose([])],
            description: ["", Validators.compose([])],
            features: ["", Validators.compose([])],
            minPrice: ["", Validators.compose([])],
            maxPrice: ["", Validators.compose([])]
        });
    }

    onSubmit() {
        this.formSubmitted = true;
        if (!this.form.valid) {
            return;
        }
        this.saveData();
    }

    saveData() {
        this.router.navigate(['/listing'],
            {
                queryParams: {
                    search: this.form.controls['description'].value
                }
            }).then(() => {
                window.location.reload();
            });
    }

    useLanguage(language) {
        if (language == 'ar')
            this.textDir = 'rtl';
        else
            this.textDir = 'ltr';
    }


    isMobile: boolean;
    isTablet: boolean;
    isDesktopDevice: boolean;

    detectDeveice() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

}
