<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg1" dir="{{textDir}}">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'contact' | translate}}</h3>
            <!-- <ul>
                <li><a routerLink="/">{{'home' | translate}}</a></li>
                <li>
                    <i *ngIf="translate.currentLang == 'en'" class='bx bx-chevron-right'></i>
                    <i *ngIf="translate.currentLang == 'ar'" class='bx bx-chevron-left'></i>
                </li>
                <li>{{'contact' | translate}}</li>
            </ul> -->
        </div>
    </div>
</div>

<div class="contact-area" dir="{{textDir}}">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>{{textDir == 'ltr' ? setting['address'] : setting['address_ar']}}</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3><a href="mailto:info@beitlist.com">{{setting['email']}}</a></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-to-do-list"></i>
                        <h3><a href="tel:+(06)–5432134567">{{setting['phone']}}</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div dir="{{textDir}}">
    <div class="contact-form">
        <!-- <span>{{'send message' | translate}}</span> -->
        <h2>{{'contact' | translate}}</h2>

        <form id="contactForm"[formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <i class='bx bx-user'></i>
                        <input type="text" name="name" id="name" class="form-control" formControlName="name" placeholder="{{'name' | translate}}*">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <i class='bx bx-user'></i>
                        <input type="email" name="email" id="email" class="form-control" formControlName="email" placeholder="{{'mail' | translate}}*">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <i class='bx bx-phone'></i>
                        <input type="text" name="phone_number" id="phone_number" formControlName="phone" class="form-control" placeholder="{{'phone' | translate}}">
                    </div>
                </div>

                <!-- <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <i class='bx bx-file'></i>
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="{{'subject' | translate}}">
                    </div>
                </div> -->

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <i class='bx bx-envelope'></i>
                        <textarea name="message" formControlName="message" class="form-control" id="message" cols="30" rows="8" required placeholder="{{'message' | translate}}"></textarea>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <button type="submit" class="default-btn border-radius">{{'send message' | translate}} <i class='bx bx-plus'></i></button>
                </div>
            </div>
        </form>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>